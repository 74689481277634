import React, { Component } from 'react';
import { Button, Header } from 'semantic-ui-react';

class AssessmentGrader extends Component {
    constructor(props) {
        super(props);
        this.state = { active: props.value };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({active: this.props.value});
        }
    }
    onGradeClick = (grade) => {
        const { onGradeChange } = this.props;

        onGradeChange(grade);
        this.setState({ active: grade });
    }
    render() {
        const { active } = this.state;
        const buttonStyle = { marginRight: 0 };
        const activeStyle = { ...buttonStyle, border: 'solid 4px #0784a7' };
        return (
            <span>
                <Header as="h5">Proficiency</Header>
                <Button style={active === 'green' ? activeStyle : buttonStyle} size="massive" icon circular color="green" data-testid="green" onClick={() => this.onGradeClick('green')} />
                <br />
                <br />
                <Button style={active === 'yellow' ? activeStyle : buttonStyle} size="massive" icon circular color="yellow" data-testid="yellow" onClick={() => this.onGradeClick('yellow')} />
                <br />
                <br />
                <Button style={active === 'red' ? activeStyle : buttonStyle} size="massive" icon circular color="red" data-testid="red" onClick={() => this.onGradeClick('red')} />
            </span>);
    }
}

export default AssessmentGrader;