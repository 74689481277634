import React, { Component } from 'react';
import { observer } from 'mobx-react';
import UserPerfomanceDisplay from '../components/PerformanceReviews/UserPerfomanceDisplay';
import { componentContainerStyle, componentContentStyle } from '../constants';
import  MultiplyHeader  from '../components/MultiplyHeader/MultiplyHeader';

class HomeLandingPage extends Component {

    renderUserPerformanceDisplay() {
        const { assessmentUsers, currentUser } = this.props.usersStore;
        if (currentUser.userType === 'Sales Member') {
            return <UserPerfomanceDisplay defaultUser={currentUser} {...this.props} />;
        }
        return assessmentUsers.map((user, index) => <UserPerfomanceDisplay key={`user-performance-${index}`} defaultUser={user} {...this.props} />);
    }
    render() {
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Multiply"/>
                <div style={componentContentStyle}>
                    {this.renderUserPerformanceDisplay()}
                </div>
            </div>);
    }
}

export default observer(HomeLandingPage);