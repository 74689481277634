import { types } from 'mobx-state-tree';
import { flow } from 'mobx';
import { AdminCompany } from './AdminCompany';
import { ApiStore } from './ApiStore';

const _adminStore = types.model({
    companies: types.array(AdminCompany)
});

export const AdminStore = types.compose('AdminStore', _adminStore, ApiStore)
    .actions(self => ({
        load: flow(function* load() {
            yield self.getApi('/admin', self.companies);
        }),
        loadEnterprise: flow(function* load(userId) {
            yield self.getApi(`/enterprises/${userId}/companies`, self.companies);
        })
    }));