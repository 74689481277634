import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import UserPerfomanceDisplay from '../components/PerformanceReviews/UserPerfomanceDisplay';
import SalesManagerAssessmentRollupDisplay from '../components/PerformanceReviews/SalesManagerAssessmentRollupDisplay';
import { componentContainerStyle, componentContentStyle } from '../constants';
import { Button } from 'semantic-ui-react';

class AssessmentsReportPage extends Component {

    constructor() {
        super();
        this.state = { displayRollup: true }
    }

    toggleRollup = () => {
        this.setState(prevState => ({ displayRollup: !prevState.displayRollup }))
    }

    renderUserPerformanceDisplay() {
        const { usersStore } = this.props;
        const { displayRollup } = this.state;

        if (usersStore.currentUser.userType === 'Sales Member') {
            return <UserPerfomanceDisplay hideSelector={true} defaultUser={usersStore.currentUser} {...this.props} />;
        }

        if (usersStore.currentUser.userType === 'Sales Manager') {
            return <><Button onClick={this.toggleRollup} primary>{displayRollup ? "Explode" : "Roll up"}</Button>
                {displayRollup ? <SalesManagerAssessmentRollupDisplay managerId={usersStore.currentUser.userId} {...this.props} /> :
                    usersStore.getDirectReports(usersStore.currentUser.userId).map(user => <UserPerfomanceDisplay hideSelector={false} defaultUser={user} {...this.props} />)
                }</>;
        }

        let managers = usersStore.salesManagers
            .filter(u => u.reportsToId === usersStore.currentUser.userId || usersStore.currentUser.userType === 'General Executive' || usersStore.currentUser.userType === 'admin')
            .sort((u1, u2) => `${u1.lastName}_${u1.firstName}` > `${u2.lastName}_${u2.firstName}`);

        if (usersStore.currentUser.isExecutive) {
            managers = managers.concat(usersStore.companyExecutivesWithSalesMemberReports);
        }
        return (<><Button onClick={this.toggleRollup} primary>{displayRollup ? "Explode" : "Roll up"}</Button>
            {managers.map(manager => displayRollup ? <SalesManagerAssessmentRollupDisplay key={manager.userId} managerId={manager.userId} {...this.props} /> :
                <>
                    <UserPerfomanceDisplay hideSelector={false} defaultUser={manager} {...this.props} />
                    {usersStore.getDirectReports(manager.userId).map(user => <UserPerfomanceDisplay key={`user-performance-${user.userId}`} hideSelector={false} defaultUser={user} {...this.props} />)}
                </>)
            }
        </>);
    }
    render() {
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Feedback Report" />
                <div style={componentContentStyle}>
                    {this.renderUserPerformanceDisplay()}
                </div>
            </div>);
    }
}

export default observer(AssessmentsReportPage);