import React from 'react';
import logo from '../../logo-tagline.png';
import { Loader } from 'semantic-ui-react';

const Splash = (props) => {
    return (
        <div>
            <div style={{ textAlign: 'center', marginTop: 130, marginBottom: 10 }}>
                <img src={logo} style={{ width: 300 }} alt="logo" />
            </div>
            {props.showLoader && <Loader active inline="centered" size="massive"></Loader>}
        </div>
    );
};

export default Splash;
