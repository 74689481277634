import React, { useState, useEffect } from 'react';
import './App.css';
import { CreateRootStore } from './models/RootStore';
import { CurrentUserInitializer } from './CurrentUserInitializer';
import Splash from './components/Splash/Splash';
import Home from './pages/Home';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import ErrorDisplay from './components/ErrorDisplay/ErrorDisplay';
import SignupWizard from './pages/SignupWizard';
import { CompanyInitializer } from './CompanyInitializer';
import { useUserContext } from './UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import LoginView from './Login';

const App = observer(() => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <LoginView />;
  }

  return <AppInternal />;
});

const AppInternal = (props) => {
  const [rootStore] = useState(CreateRootStore());
  const { token, userId } = useUserContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const initialize = async () => {
      try {
        const {
          usersStore,
          companyStore,
          assessmentsStore,
          adminStore,
          modulesStore,
        } = rootStore;

        await CurrentUserInitializer(
          usersStore,
          adminStore,
          userId
        );

        let companyId = usersStore.currentUser.companyId;
        if ((usersStore.currentUser.isAdmin || usersStore.currentUser.isEnterpriseAdmin) && adminStore.companies.length > 0) {
          companyId = adminStore.companies[0].company?.id;
        }

        await CompanyInitializer(
          companyStore,
          assessmentsStore,
          usersStore,
          modulesStore,
          companyId
        );

        setIsLoaded(true);
      } catch (error) {
        console.error(error);
        setError(error.message);
      }
    };

    initialize();
  }, [rootStore, setError, setIsLoaded, token, userId]);

  const showIfCompanyExec = (component) => {
    const { usersStore } = rootStore;
    if (usersStore.currentUser?.userType === 'Company Executive') {
      return component;
    } else {
      return null;
    }
  };

  if (!isLoaded) {
    return <div data-testid='splash'>
      <ErrorDisplay message={error} />
      <Splash showLoader={true} />
    </div>;
  }

  return (
    <div style={{ height: '100%' }} data-testid='app'>
      <Switch>
        {showIfCompanyExec(
          <Route
            path='/signup'
            render={(props) => (
              <SignupWizard
                {...props}
                companyStore={rootStore.companyStore}
                usersStore={rootStore.usersStore}
              />
            )}
          />
        )}
        <Route
          path='/'
          render={(props) => <Home {...props} rootStore={rootStore} />}
        />
      </Switch>
    </div>
  );
};

export default App;
