import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CompanyOverview from '../components/CompanyOverview/CompanyOverview';
import CompanyBackground from '../components/CompanyOverview/CompanyBackground';

class CompanyBackgroundPage extends Component {
  render() {
    const { companyBackground, step, mode, saveCallback } = this.props;

    return (
      <CompanyOverview step={step}>
        <CompanyBackground
          companyBackground={companyBackground}
          mode={mode}
          saveCallback={saveCallback}
        />
      </CompanyOverview>
    );
  }
}

export default observer(CompanyBackgroundPage);
