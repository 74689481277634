import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import EditAssessment from '../components/ScheduleAssessments/EditAssessment';
import { componentContainerStyle, componentContentStyle } from '../constants';
import OpenAssessments from '../components/ScheduleAssessments/OpenAssessments';

class ScheduleAssessmentsPage extends Component {
    constructor(props) {
        super(props);
        this.state = { editIndex: -1 };
    }

    onEditIndexChange = index => {
        this.setState({ editIndex: index });
    }

    render() {
        const { editIndex } = this.state;

        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Schedule Review" />
                <div style={componentContentStyle}>
                    <EditAssessment {...this.props} editIndex={editIndex} onEditIndexChange={this.onEditIndexChange} />
                    <div style={{ marginTop: 15 }}></div>
                    <OpenAssessments {...this.props} onEditIndexChange={this.onEditIndexChange} editIndex={editIndex} />
                </div>
            </div>);
    }
}

export default observer(ScheduleAssessmentsPage);