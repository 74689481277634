import { types, flow, getSnapshot } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';
import { OpportunityData, NullOpportunityData } from './OpportunityData';
import { SalespersonExpense, NullSalespersonExpense } from './SalespersonExpense';
import formatter from './CurrencyFormatter';

const _salesPerformance = types.model({
    totalOpportunities: types.maybe(OpportunityData, getSnapshot(NullOpportunityData)),
    wonOpportunities: types.maybe(OpportunityData, getSnapshot(NullOpportunityData)),
    expense: types.maybe(SalespersonExpense, getSnapshot(NullSalespersonExpense))
});

export const SalesPerformance = types.compose('SalesPerformance', _salesPerformance, ApiStore)
    .actions(self => ({
        updateFromEvent(event) {
            const ids = event.target.id.split('.');
            self[ids[0]][ids[1]] = +event.target.value;
        },
        save: flow(function* save(user) {
            const patch = {
                salesPerformance: getSnapshot(self)
            };
            yield user.patchApi(`/users/${user.userId}`, patch, user);
        }),
    }))
    .views(self => ({
        get salesRoiNumber() {
            const fraction = 1 - (self.expense.totalEarningsNumber + self.expense.expensesPaidNumber) / (self.wonOpportunities.value);

            return (fraction * 100).toFixed();
        },
        get salesRoi() {
            return !Number.isFinite(+self.salesRoiNumber) ? '' : `${self.salesRoiNumber} %`;
        },
        get multiplyMetricNumber() {
            const metric = self.wonOpportunities.value - (self.expense.totalEarningsNumber + self.expense.expensesPaidNumber);

            return metric;
        },
        get multiplyMetric() {

            return isNaN(self.multiplyMetricNumber) ? '' : formatter.format(self.multiplyMetricNumber);
        },
    }));

export const NullSalesPerformance = SalesPerformance.create({
    totalOpportunities: getSnapshot(NullOpportunityData),
    wonOpportunities: getSnapshot(NullOpportunityData),
    expense: getSnapshot(NullSalespersonExpense)
});