import React from "react";
import { Modal, Button } from "semantic-ui-react";

const DeleteModal = (props) => {
  const { onConfirm, onCancel } = props;
  return (
    <Modal open={true}>
      <Modal.Content>Are you sure you want to delete?</Modal.Content>
      <Modal.Actions>
        <Button
          content="Yes"
          labelPosition="right"
          icon="checkmark"
          onClick={onConfirm}
          primary
        />
        <Button negative onClick={onCancel}>
          No
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;
