import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { componentContentStyle } from '../constants';
import RecordPresentation from '../components/RecordPresentation/RecordPresentation';

export class RecordPresentationPage extends Component {
  render() {
    const { usersStore, mode, videoPresentationStore } = this.props;
        return (
            <div className='record-presentation-page'>
                <MultiplyHeader fontSize='25px' text="Record Presentation" />
                <div className='flex-grow record-presentation-parent' style={componentContentStyle}>
                    <RecordPresentation videoPresentationStore={videoPresentationStore} usersStore={usersStore} mode={mode} />
                </div>
            </div>
        );
  }
}

export default observer(RecordPresentationPage);
