import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Grid, Button } from 'semantic-ui-react';

class MultipleAssessmentDisplay extends Component {

    assessmentPlaceholder = {
        grade: 'grey',
        subject: 'Not Completed'
    }

    fullBorderStyle = { border: '1px solid #0784a7' }
    assessmentTextStyle = { marginTop: 10, display: 'inline-block' }
    buttonStyle = { marginTop: -20, display: 'inline-block' }
    gridColumnStyle = { borderLeft: '1px solid #0784a7' }
    gridStyle = { margin: 0 }

    renderLastThreeAssessments(assessmentHistory) {
        const { assessments } = assessmentHistory;

        const assessment1 = assessments[0] ? assessments[0] : this.assessmentPlaceholder;
        const assessment2 = assessments[1] ? assessments[1] : this.assessmentPlaceholder;
        const assessment3 = assessments[2] ? assessments[2] : this.assessmentPlaceholder;

        const assessment1Text = assessment1.secondarySubject ? `${assessment1.subject} - ${assessment1.secondarySubject}` : assessment1.subject;
        const assessment1Date = new Date(assessment1.completedDateTime);
        const assessment1DateText = !isNaN(assessment1Date) ? ` - ${assessment1Date.toLocaleDateString()}` : '';

        const assessment2Text = assessment2.secondarySubject ? `${assessment2.subject} - ${assessment2.secondarySubject}` : assessment2.subject;
        const assessment2Date = new Date(assessment2.completedDateTime);
        const assessment2DateText = !isNaN(assessment2Date) ? ` - ${assessment2Date.toLocaleDateString()}` : '';

        const assessment3Text = assessment3.secondarySubject ? `${assessment3.subject} - ${assessment3.secondarySubject}` : assessment3.subject;
        const assessment3Date = new Date(assessment3.completedDateTime);
        const assessment3DateText = !isNaN(assessment3Date) ? ` - ${assessment3Date.toLocaleDateString()}` : '';

        return (
            <div>
                <Grid columns={3} style={this.gridStyle} celled>
                    <Grid.Row style={this.fullBorderStyle}>
                        <Grid.Column>
                            <Button
                                data-testid="assessment-grade-1"
                                color={assessment1.grade}
                                icon
                                size="massive"
                                circular
                                floated="left" />
                            <div style={this.assessmentTextStyle}>
                                {`${assessment1Text}${assessment1DateText}`}
                            </div>
                        </Grid.Column>
                        <Grid.Column style={this.gridColumnStyle}>
                            <Button
                                data-testid="assessment-grade-2"
                                color={assessment2.grade}
                                icon
                                size="massive"
                                circular
                                floated="left" />
                            <div style={this.assessmentTextStyle}>
                                {`${assessment2Text}${assessment2DateText}`}
                            </div>
                        </Grid.Column>
                        <Grid.Column style={this.gridColumnStyle}>
                            <Button
                                data-testid="assessment-grade-3"
                                color={assessment3.grade}
                                icon
                                size="massive"
                                circular
                                floated="left" />
                            <div style={this.assessmentTextStyle}>
                                {`${assessment3Text}${assessment3DateText}`}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

    render() {
        const { assessmentHistory } = this.props;
        return (
            <div>
                <MultiplySubheader text="Last 3 Performance Reports" />
                {this.renderLastThreeAssessments(assessmentHistory)}
            </div>);
    }
}

export default observer(MultipleAssessmentDisplay);