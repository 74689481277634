import { types } from 'mobx-state-tree';
import { UsersStore } from './UsersStore';
import { CompanyStore } from './CompanyStore';
import { AssessmentsStore } from './AssessmentsStore';
import { AdminStore } from './AdminStore';
import { ModulesStore } from './Modules';
import { VideoPresentationStore } from './VideoPresentationStore';

export const RootStore = types.model({
    usersStore: types.optional(UsersStore, {}),
    companyStore: types.optional(CompanyStore, {}),
    assessmentsStore: types.optional(AssessmentsStore, {}),
    adminStore: types.optional(AdminStore, {}),
    modulesStore: types.optional(ModulesStore, {}),
    videoPresentationStore: types.optional(VideoPresentationStore, {}),
});

export const CreateRootStore = () => {
    return RootStore.create(
        {
            usersStore: UsersStore.create(),
            companyStore: CompanyStore.create(),
            assessmentsStore: AssessmentsStore.create(),
            adminStore: AdminStore.create(),
            modulesStore: ModulesStore.create(),
            videoPresentationStore: VideoPresentationStore.create(),
        }
    );
};