import { types } from "mobx-state-tree";

export const CompanyOffering = types.model("CompanyOffering", {
  offering: types.string,
  differentiator: types.string,
  asks: types.string,
  qualifyingQuestions: types.string,
  typicalObjections: types.string,
  uploadKey: types.maybe(types.string),
  uploadLink: types.maybe(types.string),
});
