import { types, flow, getSnapshot } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';
import { SalesPerformance, NullSalesPerformance } from './SalesPerformance';

const _user = types.model({
    userId: types.string,
    firstName: types.string,
    lastName: types.string,
    companyId: types.optional(types.string, ''),
    email: types.string,
    active: types.optional(types.boolean, true),
    userType: types.enumeration(['Company Executive', 'General Executive', 'Sales Manager', 'Sales Member', 'admin', 'Enterprise Admin']),
    salesPerformance: types.optional(SalesPerformance, getSnapshot(NullSalesPerformance)),
    stripeCustomerId: types.maybe(types.string),
    subscriptionId: types.maybe(types.string),
    reportsToId: types.maybe(types.string),
    disabled: types.maybe(types.boolean),
    mfaEnabled: types.maybe(types.boolean),
    enterpriseCompanyIds: types.optional(types.array(types.string), [])
}).views(self => ({
    get isExecutive() {
        return self.userType === 'Company Executive' || self.userType === 'General Executive' || self.userType === 'admin';
    },
    get isCompanyExecutive() {
        return self.userType === 'Company Executive';
    },
    get isGeneralExecutive() {
        return self.userType === 'General Executive';
    },
    get isAdmin() {
        return self.userType === 'admin';
    },
    get isEnterpriseAdmin() {
        return self.userType === 'Enterprise Admin';
    },
    get canChangeCreditCard() {
        return !!self.stripeCustomerId;
    },
    get isSalesMember() {
        return self.userType === 'Sales Member';
    }
}));

export const User = types.compose('User', _user, ApiStore).actions(self => ({
    updateFromSnapshot: flow(function* updateFromSnapshot(snapshot) {
        const patch = {
            firstName: snapshot.firstName,
            lastName: snapshot.lastName,
            userType: snapshot.userType,
            reportsToId: snapshot.reportsToId,
            active: snapshot.active,
            mfaEnabled: snapshot.mfaEnabled,
            enterpriseCompanyIds: snapshot.enterpriseCompanyIds
        };

        yield self.patchApi(`/users/${self.userId ?? snapshot.userId}`, patch, self);
    }),
    createFromSnapshot: flow(function* createFromSnapshot(snapshot) {
        snapshot.active = true;
        yield self.postApi('/users', snapshot, self);
    })
}));

export const NullUser = User.create(
    {
        userId: '',
        firstName: '',
        lastName: '',
        companyId: '',
        email: '',
        active: true,
        userType: 'Company Executive'
    }
);
