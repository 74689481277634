export const CompanyInitializer = async (companyStore, assessmentsStore, usersStore, modulesStore, companyId = undefined) => {
    const calls = [];
    if (!companyId) {
        return;
    }
    calls.push(companyStore.loadCompany(companyId));
    calls.push(assessmentsStore.loadCompanyAssessments(companyId));
    if (usersStore.currentUser.userType !== 'Sales Member') {
        calls.push(usersStore.loadCompanyUsers(companyId));
    }
    calls.push(await modulesStore.load(companyId));

    await Promise.all(calls);
};