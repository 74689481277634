import React, { Component } from "react";
import { Form, Button, Icon } from "semantic-ui-react";
import { observer } from "mobx-react";
import { FileDownloader } from "../../FileDownloader";

class MultiplyUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  fileInputRef = React.createRef();
  fileChange = async (e) => {
    this.setState({ file: e.target.files[0] });
    const { onChange } = this.props;

    onChange(e.target.files[0]);
  };

  downloadFile = () => {
    const { uploadKey } = this.props;

    FileDownloader(uploadKey);
  };

  removeFile = () => {
    const { onChange } = this.props;

    onChange(null);

    this.setState({ file: "" });
  };

  renderUploadButton() {
    const { file } = this.state;

    return (
      <div data-testid="upload-button">
        <Form.Button
          icon="paperclip"
          circular
          primary
          basic={!file}
          size="mini"
          compact
          onClick={() => this.fileInputRef.current.click()}
        />
        <input
          data-testid="upload-input"
          ref={this.fileInputRef}
          type="file"
          hidden
          onChange={this.fileChange}
        />
      </div>
    );
  }

  renderDownloadLink() {
    const { mode } = this.props;
    const isReadonly = mode === "readonly";
    return (
      <Button.Group>
        <Button
          primary
          size="mini"
          compact
          onClick={this.downloadFile}
          icon
          labelPosition="left"
        >
          Collateral
          <Icon name="download" />
        </Button>
        {!isReadonly ? (
          <Button
            data-testid="remove"
            negative
            size="mini"
            compact
            onClick={this.removeFile}
            icon="remove"
            style={{ borderLeft: "none" }}
          ></Button>
        ) : null}
      </Button.Group>
    );
  }
  render() {
    const { uploadKey, uploadLink, mode } = this.props;
    const isReadOnly = mode === "readonly";
    if (uploadKey) {
      return this.renderDownloadLink(uploadLink);
    } else if (!isReadOnly) {
      return this.renderUploadButton();
    } else {
      return null;
    }
  }
}

export default observer(MultiplyUpload);
