import { types, getSnapshot, flow } from 'mobx-state-tree';
import { Company, NullCompany } from './Company';
import { ApiStore } from './ApiStore';

const _companyStore = types.model('CompanyStore', {
    company: types.optional(Company, getSnapshot(NullCompany)),
    isLoading: types.optional(types.boolean, false)
});

export const CompanyStore = types.compose(_companyStore, ApiStore)
    .actions(self => ({
        loadCompany: flow(function* loadCompany(companyId) {
            yield self.getApi(`/companies/${companyId}`, self.company);
        }),
        updatePaymentMethod: flow(function* (
            paymentMethodId,
            userId
        ) {
            const response = yield self.putApi('/payment-methods', {
                userId: userId,
                paymentMethodId: paymentMethodId
            });

            return response;
        }),
    }));
