import React, { Component } from 'react';
import MultiplyHeader from '../MultiplyHeader/MultiplyHeader';
import { observer } from 'mobx-react';
import {
  componentContentStyle,
  componentContainerStyle,
} from '../../constants';

class CompanyOverview extends Component {
  render() {
    return (
      <div style={componentContainerStyle}>
        <MultiplyHeader text='Company Overview'></MultiplyHeader>
        <div style={componentContentStyle}>{this.props.children}</div>
      </div>
    );
  }
}

export default observer(CompanyOverview);
