import { types } from 'mobx-state-tree';
import formatter from './CurrencyFormatter';

export const SalespersonExpense = types.model('SalespersonExpense', {
    salary: types.maybe(types.number),
    commissions: types.maybe(types.number),
    expenses: types.maybe(types.number),
    attributedExpenses: types.maybe(types.number)
})
    .views(self => ({
        get totalEarningsNumber() {
            return self.salary + self.commissions;
        },
        get totalEarnings() {
            return isNaN(self.totalEarningsNumber) ? '' : formatter.format(self.totalEarningsNumber);
        },
        get expensesPaidNumber() {
            return self.expenses - self.attributedExpenses;
        },
        get expensesPaid() {
            return isNaN(self.expensesPaidNumber) ? '' : formatter.format(self.expensesPaidNumber);
        }
    }));

export const NullSalespersonExpense = SalespersonExpense.create(
    {
        salary: 0,
        commissions: 0,
        expenses: 0,
        attributedExpenses: 0
    }
);