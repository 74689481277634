import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import { Form, Grid, Icon } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import MultiplyTimeInput from '../MultiplyTimeInput/MultiplyTimeInput';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';

class EditAssessment extends Component {
    constructor(props) {
        super(props);

        let assessment = {};
        const { editIndex, assessmentsStore } = props;
        if (editIndex >= 0) {
            assessment = { ...getSnapshot(assessmentsStore.openAssessments[editIndex]) };
            const date = new Date(assessment.reviewDateTime);
            assessment.reviewDate = date.toLocaleDateString();
            assessment.reviewTime = date.toLocaleTimeString();
        }

        this.state = {
            assessment,
            error: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editIndex !== this.props.editIndex) {
            const { editIndex, assessmentsStore } = this.props;
            if (editIndex >= 0) {
                const assessment = { ...getSnapshot(assessmentsStore.openAssessments[editIndex]) };
                const date = new Date(assessment.reviewDateTime);
                assessment.reviewDate = date.toLocaleDateString();
                assessment.reviewTime = date.toLocaleTimeString();

                this.setState({ assessment });
            } else {
                this.setState({ assessment: {} });
            }
        }
    }

    onSubmitClick = async () => {
        const { editIndex } = this.props;
        if (editIndex >= 0) {
            await this.saveAssessment(editIndex);
        } else {
            await this.scheduleAssessment();
        }
    };

    saveAssessment = async (editIndex) => {
        const { assessmentsStore, onEditIndexChange } = this.props;
        const { assessment } = this.state;

        const editAssessment = assessmentsStore.openAssessments[editIndex];

        const date = new Date(`${assessment.reviewDate} ${assessment.reviewTime}`);

        const assessmentSnapshot = { ...assessment, id: editAssessment.id, companyId: editAssessment.companyId, reviewDateTime: date.toISOString() };

        delete assessmentSnapshot.reviewTime;
        delete assessmentSnapshot.reviewDate;

        await assessmentsStore.updateAssessmentFromSnapshot(assessmentSnapshot);
        this.setState({ assessment: {} });

        if (onEditIndexChange) {
            onEditIndexChange(-1);
        }
    };

    scheduleAssessment = async () => {
        this.setState({ error: '' });
        const { assessmentsStore, company } = this.props;
        const { assessment } = this.state;

        const date = new Date(`${assessment.reviewDate} ${assessment.reviewTime}`);
        const assessmentSnapshot = { ...assessment, reviewDateTime: date.toISOString(), companyId: company.id };

        delete assessmentSnapshot.reviewTime;
        delete assessmentSnapshot.reviewDate;
        try {
            await assessmentsStore.scheduleAssessment(assessmentSnapshot);
            this.setState({ assessment: {} });
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    onTextChange = event => {
        const { assessment } = this.state;
        assessment[event.target.id] = event.target.value;
        this.setState({ assessment });
    };

    onSubjectChange = data => {
        const { assessment } = this.state;
        assessment[data.id] = data.value;
        delete assessment.secondarySubject;
        this.setState({ assessment });
    };

    onUserChange = data => {
        const { usersStore } = this.props;
        const { assessment } = this.state;

        const user = usersStore.assessmentUsers.find(u => u.userId === data.value);
        assessment[data.id] = user;
        this.setState({ assessment });
    };

    onOfferingChange = data => {
        const { assessment } = this.state;

        assessment.secondarySubject = data.value;

        this.setState({ assessment });
    };

    onSessionTypeChange = data => {
        const { assessment } = this.state;

        assessment.sessionType = data.value;

        this.setState({ assessment });
    };

    dateTimeChange = (event, { name, value }) => {
        const { assessment } = this.state;
        assessment[name] = value;
        this.setState({ assessment });
    };

    enableSchedule = () => {
        const { assessment } = this.state;

        return assessment.salesperson &&
            assessment.subject &&
            (assessment.subject !== 'Offerings' || assessment.secondarySubject) &&
            assessment.reviewDate &&
            assessment.reviewTime &&
            assessment.sessionType;
    };

    formIsDirty = () => {
        const { assessment } = this.state;

        const keys = Object.keys(assessment);

        for (let k of keys) {
            if (!!assessment[k]) {
                return true;
            }
        }

        return false;
    };

    renderSubmitButton() {
        const { editIndex } = this.props;

        const isEditing = editIndex >= 0;
        const icon = isEditing ? 'save' : 'calendar alternate';
        const text = isEditing ? 'Save' : 'Schedule';

        return <Form.Button onClick={this.onSubmitClick} primary icon labelPosition="right" disabled={!this.enableSchedule()}>{text}<Icon name={icon} /></Form.Button>;
    }

    render() {
        const { usersStore, assessmentsStore, company } = this.props;
        const salesPersonOptions = usersStore.assessmentUsers
            .map((user, i) => ({ key: i, value: user.userId, text: `${user.firstName} ${user.lastName}` }));

        const subjectOptions = [{
            key: 'subject-1',
            value: 'Company Overview',
            text: 'Company Overview'
        },
        {
            key: 'subject-5',
            value: 'Offerings',
            text: 'Offerings'
        }];

        const { assessment, error } = this.state;
        const offeringOptions = assessment.subject === 'Offerings' ? company.offerings.map(offering => ({ value: offering.offering, text: offering.offering })) : undefined;
        const sessionTypeOptions = [{
            key: 'session-type-1',
            value: 'Phone Call',
            text: 'Phone Call'
        },
        {
            key: 'session-type-2',
            value: 'Casual Encounter',
            text: 'Casual Encounter'
        },
        {
            key: 'session-type-3',
            value: 'Presentation',
            text: 'Presentation'
        },
        {
            key: 'session-type-4',
            value: 'Online Video Conference',
            text: 'Online Video Conference'
        }];

        const subjectColumnCount = offeringOptions ? 3 : 2;

        return (<div>
            <NavigationBlocker when={this.formIsDirty()} />
            <Form loading={assessmentsStore.isLoading}>
                <Grid stackable columns={2}>
                    <Grid.Row columns={subjectColumnCount}>
                        <Grid.Column>
                            <Form.Dropdown
                                id="salesperson"
                                label="Salesperson"
                                placeholder="Salesperson"
                                selection
                                options={salesPersonOptions}
                                onChange={(e, d) => this.onUserChange(d)}
                                required
                                value={assessment.salesperson?.userId || ''}
                                search
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Dropdown
                                id="subject"
                                label="Subject"
                                placeholder="Subject"
                                selection
                                options={subjectOptions}
                                onChange={(e, d) => this.onSubjectChange(d)}
                                required
                                value={assessment.subject || ''}
                                search
                            />
                        </Grid.Column>
                        {offeringOptions ? <Grid.Column>
                            <Form.Dropdown
                                id="secondarySubject"
                                label="Offering"
                                placeholder="Offering"
                                selection
                                options={offeringOptions}
                                onChange={(e, d) => this.onOfferingChange(d)}
                                required
                                value={assessment.secondarySubject || ''}
                                search
                            />
                        </Grid.Column> : null}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Input
                                name="reviewDate"
                                as={DateInput}
                                label="Review Date"
                                placeholder="Review Date"
                                onChange={this.dateTimeChange}
                                animation='' // Empty string is needed.  Do not delete.
                                popupPosition="bottom center"
                                dateFormat="MM/DD/YYYY"
                                minDate={new Date().toLocaleDateString()}
                                required
                                value={assessment.reviewDate || ''} />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Review Time</label>
                                <MultiplyTimeInput name="reviewTime"
                                    placeholder="Time"
                                    onChange={this.dateTimeChange}
                                    popupPosition="bottom center"
                                    animation='' // Empty string is needed.  Do not delete.
                                    timeFormat="AMPM"
                                    required
                                    clearable={true}
                                    value={assessment.reviewTime || ''}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Dropdown
                                id="sessionType"
                                label="Session Type"
                                placeholder="Session Type"
                                selection
                                options={sessionTypeOptions}
                                onChange={(e, d) => this.onSessionTypeChange(d)}
                                required
                                value={assessment.sessionType || ''}
                                search
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                id="additionalAttendees"
                                label="Additional Attendees"
                                placeholder="jane@yourcompany.com;joe@yourcompany.com"
                                onChange={this.onTextChange}
                                value={assessment.additionalAttendees || ''} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            {this.renderSubmitButton()}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
            <ErrorDisplay message={error} />
        </div>);
    }
}

export default observer(EditAssessment);