import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Table, Button, Modal, Message } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';

class OpenAssessments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editIndex: -1,
            error: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editIndex !== this.props.editIndex) {
            this.setState({ editIndex: this.props.editIndex });
        }
    }

    onEditClick = (editIndex) => {
        const { onEditIndexChange } = this.props;

        this.setState({ editIndex });

        onEditIndexChange(editIndex);
    }

    onCancelClick = () => {
        const { onEditIndexChange } = this.props;

        this.setState({ editIndex: -1 });
        onEditIndexChange(-1);
    }

    onDeleteClick = (assessmentId) => {
        this.setState({ deleteId: assessmentId });
    }

    onDeleteConfirmation = async (assessmentId) => {
        const { assessmentsStore } = this.props;
        this.setState({ deleteId: undefined });

        try {
            this.setState({ error: '' });
            await assessmentsStore.deleteAssessment(assessmentId);
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    onDeleteCancel = () => {
        this.setState({ deleteId: undefined });
    }

    renderDeleteModal() {
        const { deleteId } = this.state;
        const modalIsOpen = !!deleteId;

        if (!modalIsOpen) {
            return;
        }

        const assessment = this.props.assessmentsStore.openAssessments.find(assessment => assessment.id === deleteId);

        if (!assessment) {
            return;
        }

        const reviewDate = new Date(assessment.reviewDateTime);

        return (
            <Modal size='tiny' open={true} onClose={this.onDeleteModalClose}>
                <Modal.Header>Are you sure you want to delete your assessment with {assessment.salesperson.firstName} {assessment.salesperson.lastName} on {reviewDate.toLocaleDateString()}?</Modal.Header>
                <Modal.Actions>
                    <Button negative onClick={this.onDeleteCancel}>No</Button>
                    <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Yes'
                        onClick={() => this.onDeleteConfirmation(assessment.id)}
                    />
                </Modal.Actions>
            </Modal>
        );
    }

    render() {
        const { assessmentsStore, usersStore } = this.props;
        const { error, editIndex } = this.state;
        const assessmentUsers = new Set(usersStore.assessmentUsers.map(u => u.userId));
        const openAssessments = assessmentsStore.openAssessments.filter((assessment) => assessmentUsers.has(assessment.salesperson.userId));

        return (
            <div>
                <ErrorDisplay message={error} />
                {this.renderDeleteModal()}
                {(openAssessments.length === 0 &&
                    <Message color="blue">No Feedback Scheduled</Message>) ||
                    <Table fixed celled singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}>

                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Salesperson
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Subject
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Session Type
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Review Date
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Review Time
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Additional Attendees
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {openAssessments.map((assessment, i) => {
                                const reviewDate = new Date(assessment.reviewDateTime);
                                return (
                                    <Table.Row active={i === editIndex} key={i}>
                                        <Table.Cell textAlign='center'>
                                            {editIndex !== i ?
                                                <div>
                                                    <Button primary compact data-testid={`edit-${i}`} basic size="small" icon="edit" onClick={() => this.onEditClick(i)}></Button>
                                                    <Button negative compact data-testid={`delete-${i}`} basic size="small" icon="trash alternate outline" onClick={() => this.onDeleteClick(assessment.id)}></Button>
                                                </div> :
                                                <div>
                                                    <Button negative compact data-testid={`cancel-${i}`} basic size="small" icon="cancel" onClick={() => this.onCancelClick(i)}></Button>
                                                </div>}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {`${assessment.salesperson.firstName} ${assessment.salesperson.lastName}`}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {!assessment.secondarySubject ? assessment.subject : `${assessment.subject} - ${assessment.secondarySubject}`}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {assessment.sessionType}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {reviewDate.toLocaleDateString()}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {reviewDate.toLocaleTimeString()}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {assessment.additionalAttendees}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                }
            </div>
        );
    }
}

export default observer(OpenAssessments);