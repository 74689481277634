import React from 'react';
import { marked } from 'marked';

const ModuleArticle = (props) => {
    const { content } = props;

    if (!content) {
        return null;
    }

    const getMarkdownText = () => {
        var rawMarkup = marked(content, { sanitize: true });
        return { __html: rawMarkup };
    };

    return (
        <div id="module-article" dangerouslySetInnerHTML={getMarkdownText()} />
    );
};

export default ModuleArticle;
