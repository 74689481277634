import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Splash from './components/Splash/Splash';
import LoginButton from './LoginButton';
import { useLocation } from 'react-router-dom';

const LoginView = () => {
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = !!queryParams.get('code');
    const message = queryParams.get('message');

    React.useEffect(() => {
        if (isAuthenticated) {
            history.push('/explore');
        } else {
            // loginWithRedirect();
        }
    }, [history, isAuthenticated, loginWithRedirect]);

    return <>
        <Splash />
        {code || <LoginButton />}
        <div style={{ textAlign: 'center', marginTop: 10 }}>
            {message}
        </div>
    </>;
};

export default LoginView;
