import { types } from 'mobx-state-tree';
import { Article } from './Article';


export const Module = types.model('Module', {
    id: types.number,
    name: types.string,
    articles: types.array(Article),
    icon: types.maybeNull(types.string)
}).views(self => ({
    get path() {
        return self.name.toLocaleLowerCase().replaceAll(" ", "-")
    }
}));
