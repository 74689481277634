import React, { Component } from 'react';
import { Icon, Grid } from "semantic-ui-react";
import posterImg from '../../video-camera.svg';
import casualEncounterIcon from '../../casual-encounter-icon.svg';
import salesPresentationIcon from '../../sales-presentation-icon.svg';
import phoneCallIcon from '../../phone-call-icon.svg';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import ScreenRecorder from './ScreenRecorder';
import phoneImg from '../../PhoneCall.jpg'

class RecordPresentation extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
			permission: false,
      stream: null,
      mediaRecorder: null,
      chunks: [],
      recordedChunks: [],
      videoUrl: '',
      timer: 0,
      recording: false,
      blobCount: 0,
      showVideoModal: false,
      recordingType: '',
      error: '',
			timestamp: '',
      sreenRecordPage: '',
      numberOfVideoDevices: 0,
      screenRecording: true,
    }; 
  }

  componentDidMount(){
    navigator.permissions.query({ name: "camera" }).then((cameraResult) => {
      navigator.permissions
        .query({ name: "microphone" })
        .then((microphoneResult) => {
          if (
            cameraResult.state !== "granted" ||
            microphoneResult.state !== "granted"
          ) {
            this.getCameraAndMicrophonePermission();
          } else {
            this.setState({ permission: true });
            this.checkInputDevices();
          }
        });
    });
  }
	
  componentWillUnmount() {
    clearInterval(this.timerInterval);
		this.setState({ timestamp: '' });
    this.stopMediaDevices();
  }

  async getCameraAndMicrophonePermission() {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        this.setState({ permission: true });
        this.stopMediaDevices(streamData);
      } catch (err) {
        let errorMessage;

        switch (err.message) {
          case 'Permission denied':
            errorMessage = 'To record, please grant permission to access your camera and microphone.';
            break;

					case 'Requested device not found':
						errorMessage = 'Both video and audio inputs are required to proceed with recording.';
						break;

					default:
						errorMessage = err.message;
						break;
        }

        this.setState({error: errorMessage});
      }
    } else {
      this.setState({error: 'MediaRecoder API is not supported in this browser.'});
    }
  };

  checkInputDevices = async ()=> {
    const audioDevices = [], videoDevices = [];
    await navigator.mediaDevices.enumerateDevices().then((devices) => {
      devices.forEach((device) => {
        if (device.kind === 'videoinput') {
          videoDevices.push(device);
        } 
        else if (device.kind === 'audioinput') {
          audioDevices.push(device);
        }
      });
      this.setState({numberOfVideoDevices: videoDevices.length});
      if(audioDevices.length < 1) {
				this.setState({ error: 'Audio device is required to proceed with recording.', permission: false });
      }
    });
  };

  getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let hour = date.getHours().toString();
    let min = date.getMinutes().toString();
    let sec = date.getSeconds().toString();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    this.setState({ timestamp: `${year}${month}${day}${hour}${min}${sec}`});
  }

  startTimer() {
    this.timerInterval = setInterval(() => {
      this.setState(prevState => ({ timer: prevState.timer + 1 }));
    }, 1000);
  };

  formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  startRecording = async () => {
    const { videoPresentationStore, usersStore } = this.props;
    const streamData = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: this.state.numberOfVideoDevices > 0 ? true : false,
    });
    this.videoRef.current.srcObject = streamData;
    this.videoRef.current.play();
    const mediaRecorder = new MediaRecorder(streamData);
    this.setState({ recording: true, chunks: [], stream: streamData, mediaRecorder }, () => {
				mediaRecorder.ondataavailable = (event)  => this.handleDataAvailable(event);
				mediaRecorder.start(1000); 
			});
    this.startTimer();
    this.getFormattedDate();

		const { recordingType, timestamp } = this.state;
    const data = {
      id: usersStore.currentUser.companyId,
      userId: usersStore.currentUser.userId,
      ContentType: "video/mp4",
      key: `recording_${timestamp}_${recordingType}`,
    };
    await videoPresentationStore.uploadInitialChunk(data);
  };

  stopRecording = async () => {
    const { mediaRecorder, chunks } = this.state;
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        this.playRecordedVideo();
        this.stopMediaDevices();
        this.onStopTimer();
      };
      const blobChunks = new Blob(chunks, { type: 'video/mp4' });
      if (blobChunks.size) { 
        this.createBlob(blobChunks, () => {
          this.setState({ chunks: [] });
        });
      }
    }
  };

  onStopTimer () {
    this.setState({ screenRecording: false })
    clearInterval(this.timerInterval); 
  }

	playRecordedVideo() {
    const { recordedChunks } = this.state;
    const blob = new Blob(recordedChunks, { type: 'video/mp4' });
    const url = URL.createObjectURL(blob);
    this.setState({videoUrl: url, start: false})
  };

  async stopMediaDevices(streamData) {
    const stream = streamData || this.state.stream;
    try {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (err) {
			this.setState({error: 'Error stopping media devices: ' + err.message});
    }
  }

  handleDataAvailable(event) {
    const { recordedChunks, chunks } = this.state;
    recordedChunks?.push(event.data);
    chunks?.push(event.data);
    const blobChunks = new Blob(chunks, { type: 'video/mp4' });
    if (blobChunks.size >= 5 * 1024 * 1024) {
      this.createBlob(blobChunks, () => {
        this.setState({ chunks: [] });
      });
    }
  }

  createBlob(blob, callback) {
    const { usersStore } = this.props;
    const { blobCount, timestamp, recordingType } = this.state;
    this.setState({ blobCount: blobCount + 1 }, () => {
      const data = {
        id: usersStore.currentUser.companyId,
        userId: usersStore.currentUser.userId,
        ContentType: "video/mp4",
        key: `recording_${timestamp}_${recordingType}`,
        partNumber: blobCount + 1,
      };
      this.callUploadChunck(data,blob);
      if (callback) callback();
    });
  }

  async callUploadChunck(data, blob) {
    const { videoPresentationStore } = this.props;
    await videoPresentationStore.uploadChunk(data,blob);
  }

  executeStartRecording(recordingType) {
    this.setState({showVideoModal: true, recordingType})
  }

  renderInstruction() {
    return (
			<div className='instruction'>
      <h5>Instruction how to start Video presentation.</h5>
      <p>You may only record your presentation video once, so it is important to be adequately prepared and poised in front of the camera.<br/> Ensure you are ready to face the camera confidently and deliver your presentation effectively.</p>
      <ul>
        <li>To commence the video presentation, simply click the "Start Video" button.</li>
        <li>Upon clicking, the video recording will commence, as evidenced by the timer displayed at the bottom of the interface.</li>
        <li>To conclude the video recording, click the "Stop Video" button.</li>
        <li>Subsequently, the recording will cease, and a preview of the recorded video will initiate.</li>
      </ul>
     </div>
    )
  }

  renderCards() {
    const {permission} = this.state;

		const recordingTypes = [
			{
				type: 'Casual Encounter',
				icon: casualEncounterIcon,
				recordingKey: 'casual_encounter'
			},
			{
				type: 'Sales Presentation',
				icon: salesPresentationIcon,
				recordingKey: 'sales_presentation'
			},
			{
				type: 'Phone Call',
				icon: phoneCallIcon,
				recordingKey: 'phone_call'
			}
		];

    return (
      <div className='record-card'>
        <Grid stackable Row={1}>
					{
						recordingTypes.map(recordingType => (
							<Grid.Column key={recordingType.recordingKey} className={`card ${recordingType.recordingKey.replace('_','-')}`}>
							<div><img src={recordingType.icon} className='recording-card-icon' alt={recordingType.type} /></div>
							<h2>{recordingType.type}</h2>
							<div className='recording-card-subheading'>Suggested length and other instructions here.</div>
							<button	disabled={!permission || recordingType.recordingKey === 'sales_presentation' && this.state.numberOfVideoDevices < 1} className={`start-recording-button btn-${recordingType.recordingKey.split('_')[0]}`}onClick={() => this.executeStartRecording(recordingType.recordingKey)}><Icon name='video' />Start Recording</button>
						</Grid.Column>
						))}
        </Grid>
      </div>
    )
  }

  renderButtons() {
    const { permission, recording, sreenRecordPage } = this.state;
    return (
      <div className='button-card'>
        <div className='video-cta'>
          {this.state.recordingType !== 'sales_presentation' ? <button
            className='start-stop-button'
            onClick={() => recording ? this.stopRecording() : this.startRecording()}
            disabled={!permission}
          >
            <Icon name={`${recording ? 'stop' : 'play'} circle outline`} />
            {recording ? 'Stop Recording' : 'Start Recording'}
          </button> : sreenRecordPage === 'Stop Recording' ? '' : <button
          className='start-stop-button'
          onClick={(e) => this.setState({sreenRecordPage: e?.target?.textContent, recording: !recording})}
          disabled={!permission}
        >
          <Icon name={`${recording ? 'stop' : 'play'} circle outline`} />
          {recording ? 'Stop Recording' : 'Start Recording'}
        </button>}
        </div>
      </div>
    );
  }

  render() {
    const { videoUrl, recording, showVideoModal, timer,error, recordingType, screenRecording } = this.state;
    return (
      <div className='record-presentation'>
        <Grid stackable columns={1} style={{height:"100%"}}>
          {!showVideoModal ? (
            <>
              <div style={{textAlign: 'center', width: '100%', fontWeight: 600, color: '#4c4d50'}}>Choose the type of presentation to get started. <b>You can only record your presentation <br/> once.</b> Make sure your're prepared and facing the camera.
                { error && <ErrorDisplay message={error} /> }
              </div>
              {this.renderCards()}
            </>
          ) : (
            <Grid.Row id="videoTag">
              <Grid.Column style={{textAlign: '-webkit-center', height: '100%'}}>
                <div className='live-video-card'>
                  <div className='timmer component' style={{padding: videoUrl ? '15px': '7px'}}>
                    {recordingType === 'sales_presentation' ? <p>
                      {screenRecording && this.formatTime(timer)} 
                      {!screenRecording && <h5>Preview</h5>}
                    </p> : <p>
                      {!videoUrl && this.formatTime(timer)} 
                      {videoUrl && <h5>Preview</h5>}
                      </p>}
									</div>
                  {!videoUrl && recordingType !== 'sales_presentation' ? <div className='video-component'>
                    <div className='common-box left-comp' style={{display : `${recordingType !== 'casual_encounter' && 'none'}`}}>Michelle</div>
                    <div className='common-box right-comp' style={{display : `${recordingType !== 'casual_encounter' && 'none'}`}}>David</div>
                    <div className='common-box left-comp' style={{display : `${recordingType !== 'casual_encounter' && 'none'}`}}>Rebecca</div>
                    <div className='box left-comp' style={{display : `${recordingType !== 'phone_call' && 'none'}`}}><img src={phoneImg} /></div>
                    <video className={`${recording ? 'video' : ''} ${recordingType === 'casual_encounter' ? 'casual-encounterVideo' : ''} right-comp before-recording`} style={{height : `${recordingType === 'phone_call' ? '350px': ''}`}} ref={this.videoRef} poster={posterImg} muted/>
                  </div> : <div className='screen-recorder-component' style={{position: 'relative'}}>
                    <ScreenRecorder buttonClick={this.state.sreenRecordPage} videoPresentationStore={this.props.videoPresentationStore} usersStore={this.props.usersStore} startTimer={() => this.startTimer()} stoptimer={()=>this.onStopTimer()}/>
                  </div>
                  }
                  {videoUrl && <video className={`video after-recording ${recordingType === 'casual_encounter' ? 'casual-after-encounterVideo' : ''}`} controls autoPlay ><source src={videoUrl} type="video/mp4" /></video>}
                  <div className='button component' style={{padding: videoUrl || !screenRecording ? '15px': '7px'}}>
                    {!videoUrl && this.renderButtons()} 
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    )
  }
}

export default RecordPresentation;
