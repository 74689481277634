import React, { useState } from 'react';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import { Form, Message } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import Loader from '../../components/Loading/Loading';

const UpdateCreditCard = (props) => {
    const { companyStore } = props;
    const [zipCode, setZipCode] = useState('');
    const [error, setError] = useState();
    const [creditCardSaved, setCreditCardSaved] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const onZipCodeChange = (e) => {
        setZipCode(e.target.value);
    };

    const onPaymentMethodChange = async (paymentMethod) => {
        setIsLoading(true);
        try {
            await companyStore.updatePaymentMethod(paymentMethod.id);
            setCreditCardSaved(true);
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    };

    return <div>
        <Loader active={isLoading}>
            <Form>
                <Form.Input
                    name='zipCode'
                    label='Billing Zip Code'
                    placeholder='Billing Zip Code'
                    onChange={onZipCodeChange}
                    value={zipCode}
                    type='number'
                />
                <CreditCardDetails
                    onPaymentMethodChange={onPaymentMethodChange}
                    company={{ zipCode }}
                    buttonText="Save"
                />
                <ErrorDisplay message={error} />
                {creditCardSaved && <Message color="blue">New credit card has been saved</Message>}
            </Form>
        </Loader>
    </div>;
};

export default UpdateCreditCard;