const red = 'red';
const yellow = 'yellow';
const green = 'green';
const grey = 'grey';

export const AverageGradeCalculator = (grades) => {
    const scoreForGrades = GetTotalScoreForGrades(grades);
    return GetAverageColorForScore(grades, scoreForGrades);
};

const GetTotalScoreForGrades = (grades) => {
    let scoreForGrades = 0;
    grades.forEach(grade => {
        switch (grade) {
            case red:
                scoreForGrades += 3;
                break;
            case yellow:
                scoreForGrades += 2;
                break;
            case green:
                scoreForGrades += 1;
                break;
            default:
                break;
        }
    });
    return scoreForGrades;
}

const GetAverageColorForScore = (grades, scoreForGrades) => {
    const numberOfGrades = grades.filter(g => g !== 'notGraded').length;
    let color = grey;
    switch (Math.round(scoreForGrades / numberOfGrades)) {
        case 1:
            color = green;
            break;
        case 2:
            color = yellow;
            break;
        case 3:
            color = red;
            break;
        default:
            break;
    }
    return color;
}
