import { TimeInput } from 'semantic-ui-calendar-react';

export default class MultiplyTimeInput extends TimeInput {
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value && !this.props.value) {
            this.setState({ mode: 'hour' });
        }
    }

    render() {
        return super.render();
    }
}