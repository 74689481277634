import React, { Component } from "react";
import { observer } from "mobx-react";
import { getSnapshot } from 'mobx-state-tree';
import MultiplySubheader from "../MultiplySubheader/MultiplySubheader";
import {
  Form,
  Icon,
  Grid,
  Button,
  Header,
  AccordionTitle, 
  AccordionContent, 
  Accordion,
} from "semantic-ui-react";
import NavigationBlocker from "../NavigationBlocker/NavigationBlocker";
import RichTextEditor from "react-rte";
import { toolbarConfig } from "../RichTextEditorConfig";

export class QuestionAnswer extends Component {
  constructor(props) {
    super(props);
    const questionAnswerSnapshot = [...getSnapshot(props.company.questionsAndAnswers).map(question => ({
      ...question,
      answer: RichTextEditor.createValueFromString(question.answer || '', 'markdown'),
      question: RichTextEditor.createValueFromString(question.question || '', 'markdown'),
      related_to: question.related_to ?? ''
    }))];

    if (questionAnswerSnapshot.length === 0) {
      questionAnswerSnapshot.push({
        answer: RichTextEditor.createEmptyValue(),
        question: RichTextEditor.createEmptyValue(),
      });
    }

    this.state = {
      questionAnswerSnapshot,
      error: "",
      activeTab: [],
    };
  }

  companyTypeOptions = [
    {
      key: 'companyOverview',
      text: 'Company Overview',
      value: "Company Overview",
    },
    {
      key: 'offerings',
      text: 'Offerings',
      value: "Offerings",
    },
  ];

  onRelatedSubjectChanged = (index,data) => { 
    const { questionAnswerSnapshot } = this.state;

    questionAnswerSnapshot[index] = {
      ...questionAnswerSnapshot[index],
      ['related_to']:  data.value,
    };

    this.setState({ questionAnswerSnapshot });
  };

  onTextChange = (event, index, name) => {
    const { questionAnswerSnapshot } = this.state;

    questionAnswerSnapshot[index] = {
      ...questionAnswerSnapshot[index],
      [name]: event,
    };

    this.setState({ questionAnswerSnapshot });
  };


  onSaveClick = async () => {
    const { company } = this.props;
    const { questionAnswerSnapshot } = this.state;

    this.setState({ error: '' });

    try {
      const questionAnswerUpdate = questionAnswerSnapshot.map(question => ({
        ...question,
        answer: question.answer.toString('markdown'),
        question: question.question.toString('markdown'),
        related_to: question.related_to || ''
      }));

      await company.updateQuestionsAndAnswers(questionAnswerUpdate);
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onAddClick = () => {
    const { questionAnswerSnapshot } = this.state;
    const newState = [...questionAnswerSnapshot];
    newState.push({answer: RichTextEditor.createEmptyValue(), question: RichTextEditor.createEmptyValue()});
    this.setState({ questionAnswerSnapshot: newState });
  };

  onRemoveClick = (index) => {
    const { questionAnswerSnapshot } = this.state;
    let newSnapshot = [...questionAnswerSnapshot];
    newSnapshot.splice(index, 1);
    this.setState({ questionAnswerSnapshot: newSnapshot });
  };


  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTab } = this.state;
    const newIndex = activeTab;
    const currentIndexPosition = activeTab.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }
    this.setState({ activeTab: newIndex });
  };

  renderQACard(d, i, moreThanOne, isReadonly) {
    if (!d) {
      return null;
    }

    return (
      <div style={{ marginBottom: "1rem" }} key={i}>
        <Accordion fluid styled exclusive={false}>
      <AccordionTitle
            active={this.state.activeTab.includes(i)}
            index={i}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            <Header as="h4" floated="left">
              Question # {i + 1}
            </Header>
        </AccordionTitle>
        <AccordionContent active={this.state.activeTab.includes(i)}>
        {!isReadonly && moreThanOne && (
          <Button
            style={{ marginTop: '-35px' }}
            data-testid={`remove-${i}`}
            size="mini"
            compact
            onClick={() => this.onRemoveClick(i)}
            icon
            basic
            negative
            floated="right"
          >
            <Icon name="remove" />
          </Button>
        )}
        <Grid.Row>
        <Grid.Column>
                  {!isReadonly ? (
                    <Form.Dropdown
                      label="Related To"
                      placeholder="Related To"
                      options={this.companyTypeOptions}
                      selection
                      onChange={(e, data) => this.onRelatedSubjectChanged(i,data)}
                      fluid
                      value={d.related_to}
                      readOnly={isReadonly}
                      search
                    />
                  ) : (
                    <Form.Input
                      label="Related To"
                      placeholder="Related To"
                      value={d.related_to}
                      readOnly
                    />
                  )}
                </Grid.Column>
                </Grid.Row>
        <h4>Question</h4>
        <Grid.Row>
          <Grid.Column>
            <Form.Field className="freeTextField">
              <RichTextEditor
                onChange={(e) => this.onTextChange(e, i, 'question')}
                readOnly={isReadonly}
                toolbarConfig={toolbarConfig}
                data-testid="diff-entry"
                placeholder="Enter a question that your team may encounter when in a sales meeting."
                name={i}
                value={d.question}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <h4>Answer</h4>
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <RichTextEditor
                onChange={(e) => this.onTextChange(e, i, 'answer')}
                readOnly={isReadonly}
                toolbarConfig={toolbarConfig}
                data-testid="diff-entry"
                placeholder="How should your team respond to the question above?"
                name={i}
                value={d.answer}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </AccordionContent>
      </Accordion>
      </div>
    )
  }

  renderButtons() {
    const { mode } = this.props;

    if (mode === "readonly") {
      return null;
    }

    const { questionAnswerSnapshot } = this.state;
    const saveEnabled =
      questionAnswerSnapshot.length > 1 || questionAnswerSnapshot[0];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.Button
              primary
              onClick={this.onSaveClick}
              icon
              labelPosition="right"
                disabled={!saveEnabled}
            >
              Save
              <Icon name="save" />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const { mode } = this.props;
    const isReadonly = mode === "readonly";
    const { questionAnswerSnapshot } = this.state;
    const moreThanOne = questionAnswerSnapshot.length > 1;
    return (
      <div>
        <MultiplySubheader text="Questions & answers regarding your company" />
        <NavigationBlocker />
        <Form loading={this.props.company.isLoading}>
          <Grid stackable columns={1}>
            <Grid.Row>
              <Grid.Column>
                {questionAnswerSnapshot.map((d, i) =>
                  this.renderQACard(d, i, moreThanOne, isReadonly)
                )}
            </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="right">
                {!isReadonly ? (
                  <Form.Button
                    primary
                    onClick={this.onAddClick}
                    icon
                    circular
                    basic
                    data-testid="add"
                  >
                    <Icon name="plus" />
                  </Form.Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>{this.renderButtons()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default observer(QuestionAnswer);
