import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Button, Icon, Accordion, Divider, Header } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

class OfferingAssessmentDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accordianContentVisible: false,
    };
  }

  onAccordianClick = () => {
    const { accordianContentVisible } = this.state;

    this.setState({ accordianContentVisible: !accordianContentVisible });
  };

  render() {
    const { assessment, nextReview } = this.props;
    const { accordianContentVisible } = this.state;
    const nextReviewText = nextReview
      ? new Date(nextReview).toLocaleDateString()
      : 'Not Scheduled';

    return (
      <Accordion as={Grid} padded>
        <Accordion.Title
          as={Grid.Row}
          columns={3}
          onClick={this.onAccordianClick}
          verticalAlign='middle'
        >
          <Grid.Column>{assessment.secondarySubject}</Grid.Column>
          <Grid.Column>
            <Button
              data-testid='rating'
              color={assessment.grade ? assessment.grade : 'grey'}
              icon
              size='massive'
              circular
              style={{ marginLeft: '19px' }}
            />
          </Grid.Column>
          <Grid.Column>
            {nextReviewText}
            <Icon
              name={accordianContentVisible ? 'caret down' : 'dropdown'}
              style={{ float: 'right' }}
            />
          </Grid.Column>
        </Accordion.Title>
        <Accordion.Content
          as={Grid.Row}
          columns={2}
          active={accordianContentVisible}
        >
          <Divider />
          <Grid.Column>
            <Header size='small'>Positives</Header>
            <div style={{ marginTop: '5px' }}>
              <span style={{ whiteSpace: 'pre-line' }}>
                <ReactMarkdown remarkPlugins={[remarkGfm]} children={assessment.offeringPositives} />
              </span>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Header size='small'>Areas for Improvement </Header>
            <div style={{ marginTop: '5px' }}>
              <span style={{ whiteSpace: 'pre-line' }}>
                <ReactMarkdown remarkPlugins={[remarkGfm]} children={assessment.offeringAreasForImprovement} />
              </span>
            </div>
          </Grid.Column>
          <Divider />
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default observer(OfferingAssessmentDisplay);
