import { clone, flow, getSnapshot, types } from "mobx-state-tree";
import { ApiStore } from "./ApiStore";
import { NullVideo, NullVideoChunk, Video, VideoChunck } from "./VideoPresentation";

const videoPresentation = types.model("VideoPresentationStore", {
    videoData: types.optional(Video, getSnapshot(NullVideo)),
    videoEtagData: types.optional(types.array(types.frozen()), []),
    videoChunck: types.optional(VideoChunck, getSnapshot(NullVideoChunk)),
    loading:  types.optional(types.boolean,  false),
    error: types.optional(types.string, ''),
});

export const VideoPresentationStore = types.compose(videoPresentation , ApiStore)
    .views(self => ({
            get getAppEtags() {
                return self.videoEtagData;
            }
        }
    ))
    .actions((self) => ({
    uploadInitialChunk: flow(function* uploadInitialChunk(data) {
        self.videoEtagData = [];
        const body = {userId: data.userId, ContentType: data.ContentType}
        self.loading = true;
        self.error = '';
        try {
            const res = clone(NullVideo);
            yield self.postApi(`/companies/${data.id}/initialize-upload/${data.key}`,body, res);
            self.videoData = res
        } catch (error) {
            console.error('Error fetching data:', error);
            self.error = error.message;
        } finally {
            self.loading = false;
        }
    }),
    uploadChunk: flow(function* uploadChunk(data,blob) {
        const body = {userId: data.userId, ContentType: data.ContentType, PartNumber: data.partNumber, uploadId: self.videoData.uploadId}
        self.loading = true;
        self.error = '';
        try {
            const res = clone(NullVideoChunk);
            yield self.postApi(`/companies/${data.id}/initialize-upload/${data.key}`,body, res);
            self.videoChunck = res
            if (res.uploadUrl) {  	
                const xhr = new XMLHttpRequest()
                xhr.open("PUT", self.videoChunck.uploadUrl)
                xhr.onerror = (error) => {
                reject(error)
            }
            xhr.onabort = () => {
                reject(new Error("Upload canceled by user"))
            }
            xhr.onreadystatechange = async function() {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        const ETag = xhr.getResponseHeader("ETag")
                        self.addVideoEtagData({ PartNumber: data.partNumber, ETag: ETag.replace(/"/g, "") });
                        if (blob?.size < 5 * 1024 * 1024 && ETag) {
                            await self.finalizeUpload(data);
                        }
                    } else {
                        console.error('API request failed with status:', xhr.status, xhr.statusText);
                    }
                }
            };
            xhr.send(blob);
        }          
        } catch (error) {
            console.error('Error fetching data:', error);
            self.error = error.message;
        } finally {
            self.loading = false;
        }
    }),
    addVideoEtagData: flow(function* addVideoEtagData(item) {
        self.videoEtagData.push(item);
    }),
    finalizeUpload: flow(function* finalizeUpload(data) {
        const body = {uploadId: self.videoData.uploadId, key: self.videoChunck.key, parts: self.videoEtagData}
        self.loading = true;
        self.error = '';
        if (Array.isArray(self.videoEtagData) && self.videoEtagData.length > 0) {
            try {
                yield self.postApi(`/companies/${data.id}/finalize-upload`,body);
            } catch (error) {
                console.error('Error fetching data:', error);
                self.error = error.message;
            } finally {
                self.loading = false;
            }
        }
    }),
}));

