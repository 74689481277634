import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Form } from 'semantic-ui-react';

export default class CurrencyInput extends Component {
    defaultMaskOptions = {
        prefix: '$',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2,
        allowNegative: false,
        allowLeadingZeroes: false,
    };
    onChange = (e) => {
        let value = e.target.value;

        value = +value.replace('$', '').replace(/,/g, '');

        if (!isNaN(value)) {
            this.props.onChange({ target: { id: e.target.id, name: e.target.name, value, type: 'number' } });
        }
    };
    render() {
        const currencyMask = createNumberMask(this.defaultMaskOptions);
        const inputProps = { ...this.props };
        delete inputProps.onChange;
        delete inputProps.label;
        delete inputProps.fluid;

        if (inputProps.value === undefined || inputProps.value === null) {
            inputProps.value = '';
        }

        return <Form.Input
            id={this.props.id}
            label={this.props.label}
            required={this.props.required}
            fluid={this.props.fluid}
            children={<MaskedInput mask={currencyMask} {...inputProps} onChange={this.onChange} inputMode="numeric" />} />;
    }
}