import React, { Component } from 'react';
import { Segment, Header } from 'semantic-ui-react';
import InfoPopup from '../InfoPopup/InfoPopup';

export default class MultiplySubheader extends Component {
    render() {
        const { text, popupContent } = this.props;
        return (
            <Segment basic style={{ padding: 0 }}>
                <Header textAlign="left" icon as="h3">
                    {text}
                    <InfoPopup popupContent={popupContent} />
                </Header>
            </Segment>
        );
    }
}

