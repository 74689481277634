import React, { Component } from 'react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Button } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import { observer } from 'mobx-react';
import OfferingAssessmentCompleter from './OfferingAssessmentCompleter';
import CompanyOverviewAssessmentCompleter from './CompanyOverviewAssessmentCompleter';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';

class AssessmentCompleter extends Component {
    constructor(props) {
        super(props);
        this.state = { error: '' };
    }

    onSaveClick = async () => {
        const { assessment } = this.props;

        try {
            await assessment.save();
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    onSubmitClick = async () => {
        this.setState({ error: '' });
        const { assessment, onAssessmentComplete, currentUser } = this.props;
        const completedDate = new Date(Date.now());
        assessment.updateFromEvent({ target: { id: 'completedDateTime', value: completedDate.toISOString() } });
        assessment.updateFromEvent({ target: { id: 'reviewer', value: currentUser } });

        try {
            await assessment.save();
            if (onAssessmentComplete) {
                onAssessmentComplete(assessment);
            }
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    completeIsEnabled() {
        const { assessment } = this.props;
        return assessment.assessmentCanBeSaved;
    }

    renderSpecificAssessmentCompleter() {
        const { assessment } = this.props;

        if (assessment.subject === 'Offerings') {
            return <OfferingAssessmentCompleter assessment={assessment} />;
        } else {
            return <CompanyOverviewAssessmentCompleter assessment={assessment} />;
        }
    }

    render() {
        const { assessment } = this.props;
        const { error } = this.state;
        return (
            <div>
                <MultiplySubheader text={`${assessment.subject} Feedback`} />
                <ErrorDisplay message={error} />
                <NavigationBlocker when={assessment.isDirty} />
                {this.renderSpecificAssessmentCompleter()}
                <br />
                <Button primary onClick={this.onSaveClick} >Save</Button>
                <Button primary onClick={this.onSubmitClick} disabled={!this.completeIsEnabled()}>Save & Complete</Button>
            </div>);
    }
}

export default observer(AssessmentCompleter);