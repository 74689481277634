import React, { useState } from 'react';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from '@stripe/react-stripe-js';
import { Form } from 'semantic-ui-react';
import Loader from '../../components/Loading/Loading';

const useOptions = () => {
    const options = {
        style: {
            base: {
                fontSize: '14px',
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#9e2146'
            }
        }
    };

    return options;
};

const CreditCardDetails = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    const initialState = {
        isLoading: false,
        paymentDetails: undefined
    };
    const [state, setState] = useState(initialState);
    const { buttonText } = props;

    const onVerifyClick = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        const { onPaymentMethodChange } = props;
        setState({ isLoading: true });
        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                address: {
                    postal_code: props.company.zipCode
                }
            }
        });
        setState({ isLoading: false });

        setState({ paymentDetails: payload.paymentMethod });
        onPaymentMethodChange(payload.paymentMethod);
    };

    const getColor = () => {
        return state.paymentDetails ? 'green' : undefined;
    };

    return (
        <div>
            <Loader active={state.isLoading}>
                <Form.Field>
                    <label>Card Number</label>
                    <CardNumberElement options={options} />
                </Form.Field>
                <Form.Field>
                    <label>Expiration date</label>
                    <CardExpiryElement options={options} />
                </Form.Field>
                <Form.Field>
                    <label>CVC</label>
                    <CardCvcElement options={options} />
                </Form.Field>
                <Form.Button onClick={onVerifyClick} color={getColor()}>{!!buttonText ? buttonText : "Verify"}</Form.Button>
            </Loader>
        </div>
    );
};

export default CreditCardDetails;
