import axios from 'axios';

export function createSubscription({ customerId, paymentMethodId }) {
  
  return axios.post('/stripe-subscriptions', {
    customerId: customerId,
    paymentMethodId: paymentMethodId
  })
    // If the card is declined, display an error to the user.
    .then((result) => {
      if (result.error) {
        // The card had an error when trying to attach it to a customer.
        throw new Error(result.error);
      }
      return result.data;
    })
    // Normalize the result to contain the object returned by Stripe.
    // Add the addional details we need.
    .then((result) => {
      return {
        paymentMethodId: paymentMethodId,
        subscription: result,
      };
    })
    .then(handlePaymentMethodRequired);
}

function handlePaymentMethodRequired({
  subscription,
  paymentMethodId,
  priceId,
}) {
  if (subscription.status === 'active') {
    // subscription is active, no customer actions required.
    return { subscription, priceId, paymentMethodId };
  } else if (
    subscription.latest_invoice.payment_intent.status ===
    'requires_payment_method'
  ) {
    localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
    localStorage.setItem(
      'latestInvoicePaymentIntentStatus',
      subscription.latest_invoice.payment_intent.status
    );
    throw new Error('Your card was declined.');
  } else {
    return { subscription, priceId, paymentMethodId };
  }
}
