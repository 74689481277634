import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

export default class ErrorDisplay extends Component {
    render() {
        const { message } = this.props;

        if (message) {
            return (<Message negative>{message}</Message>);
        }

        return null;
    }
}