import { types } from 'mobx-state-tree';
import { Company } from './Company';
import { User } from './User';

export const AdminCompany = types.model('AdminData', {
   company: Company,
   users: types.array(User)
})
   .views(self => ({
      get totalUsersCount() {
         return self.users.length;
      },
      get activeUsersCount() {
         return self.users.filter(user => user.active).length;
      },
      get companyExecutive() {
         return self.users.find(user => user.userType === 'Company Executive')
      }
   }));
