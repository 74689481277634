import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Form, Grid, Icon, Segment, Button, Header, AccordionTitle, AccordionContent, Accordion, } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import { shallowEqual } from '../../ShallowEquality';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';
import DeleteModal from '../DeleteModal/DeleteModal';

class TopCompetitors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topCompetitorsSnapshot: [...getSnapshot(props.company.topCompetitors)
        .map(tc => ({
          ...tc,
          strengths: RichTextEditor.createValueFromString(tc.strengths || '', 'markdown'),
          weaknesses: RichTextEditor.createValueFromString(tc.weaknesses || '', 'markdown')
        }))],
      error: '',
      activeTab: [],
      openDeleteModal: false,
      deleteIndex: null
    };
  }

  componentDidMount() {
    if (this.state.topCompetitorsSnapshot.length === 0) {
      this.setState({ topCompetitorsSnapshot: [{}] });
    }
  }

  onChange = (e) => {
    const { topCompetitorsSnapshot } = this.state;
    const newTopCompetitorsSnapshot = [...topCompetitorsSnapshot];

    const nameAndIndex = e.target.id.split('-');
    const name = nameAndIndex[0];
    const index = +nameAndIndex[1];
    const competitor = newTopCompetitorsSnapshot[index];
    const value = e.target.type === 'number' ? +e.target.value : e.target.value;

    newTopCompetitorsSnapshot[index] = { ...competitor, [name]: value };

    this.setState({ topCompetitorsSnapshot: newTopCompetitorsSnapshot });
  };

  onStrengthsChange = (e, index) => {
    const { topCompetitorsSnapshot } = this.state;
    const newTopCompetitorsSnapshot = [...topCompetitorsSnapshot];

    newTopCompetitorsSnapshot[index].strengths = e;
    this.setState({ topCompetitorsSnapshot: newTopCompetitorsSnapshot });
  };

  onWeaknessesChange = (e, index) => {
    const { topCompetitorsSnapshot } = this.state;
    const newTopCompetitorsSnapshot = [...topCompetitorsSnapshot];

    newTopCompetitorsSnapshot[index].weaknesses = e;
    this.setState({ topCompetitorsSnapshot: newTopCompetitorsSnapshot });
  };

  onAddClick = () => {
    const { topCompetitorsSnapshot } = this.state;
    topCompetitorsSnapshot.push({ strengths: RichTextEditor.createEmptyValue(), weaknesses: RichTextEditor.createEmptyValue() });
    this.setState({ topCompetitorsSnapshot });
  };

  onRemoveClick = (index) => {
    this.setState({ openDeleteModal: true, deleteIndex: index });
  };

  onDeleteConfirm = () => {
    const { topCompetitorsSnapshot, deleteIndex } = this.state;

    let newSnapshot = topCompetitorsSnapshot.map((c) => ({ ...c }));

    newSnapshot.splice(deleteIndex, 1);

    this.setState({ topCompetitorsSnapshot: newSnapshot, openDeleteModal: false });
  };

  closeModal = () =>{
    this.setState({ openDeleteModal: false });
  }

  saveButtonEnabled = () => {
    const { topCompetitorsSnapshot } = this.state;
    return topCompetitorsSnapshot.every(
      (c) =>
        c.companyName &&
        c.revenue !== undefined &&
        c.location &&
        c.website &&
        c.strengths &&
        c.weaknesses
    );
  };

  onSaveClick = async () => {
    const { topCompetitorsSnapshot } = this.state;
    const { company, saveCallback } = this.props;
    this.setState({ error: '' });

    const topCompetitorsUpdate = topCompetitorsSnapshot.map(tc => ({ ...tc, strengths: tc.strengths?.toString('markdown'), weaknesses: tc.weaknesses?.toString('markdown') }));

    try {
      await company.updateTopCompetitorsFromSnapshot(topCompetitorsUpdate);

      if (saveCallback) {
        saveCallback();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  formIsDirty = () => {
    const { topCompetitorsSnapshot } = this.state;
    const topCompetitors = getSnapshot(this.props.company.topCompetitors);
    const filteredTopCompetitorsSnapshot = topCompetitorsSnapshot.filter(d => Object.keys(d).length > 0);

    if (filteredTopCompetitorsSnapshot.length !== topCompetitors.length) {
      return true;
    }

    for (var i = 0; i < filteredTopCompetitorsSnapshot.length; i++) {
      const snapshot = filteredTopCompetitorsSnapshot[i];
      const model = topCompetitors[i];

      if (!shallowEqual({
        ...snapshot, strengths: snapshot.strengths.toString('markdown'), weaknesses: snapshot.weaknesses.toString('markdown')
      }, model)) {
        return true;
      }
    }

    return false;
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTab } = this.state;
    const newIndex = activeTab;
    const currentIndexPosition = activeTab.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }
    this.setState({ activeTab: newIndex });
  };

  renderTopCompetitors = () => {
    const { topCompetitorsSnapshot, activeTab } = this.state;
    const readonly = this.props.mode === 'readonly';
    const moreThanOne = topCompetitorsSnapshot.length > 1;

    return topCompetitorsSnapshot.map((competitor, index) => (
      <div style={{marginBottom: '1rem'}} key={index}>
      <Accordion fluid styled exclusive={false} >
       <AccordionTitle
         active={activeTab?.includes(index)}
         index={index}
         onClick={this.handleClick}
       >
         <Icon name="dropdown" />
        <Header as='h4' floated='left'>
          Competitor #{index + 1}
        </Header>
        </AccordionTitle>
        <AccordionContent active={activeTab?.includes(index)}>
        {readonly || !moreThanOne ? null : (
          <Button
            style={{ marginTop: '-35px' }}
            data-testid={`remove-${index}`}
            size='mini'
            compact
            onClick={() => this.onRemoveClick(index)}
            icon
            basic
            negative
            floated='right'
          >
            <Icon name='remove' />
          </Button>
        )}
        <Grid columns={2} stackable style={{ display: 'block' }}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                id={`companyName-${index}`}
                label='Company Name'
                value={competitor.companyName || ''}
                onChange={this.onChange}
                placeholder='Company Name'
                readOnly={readonly}
              />
            </Grid.Column>
            <Grid.Column>
              <CurrencyInput
                id={`revenue-${index}`}
                label='Revenue'
                value={competitor.revenue}
                onChange={this.onChange}
                placeholder='Annual Revenue'
                readOnly={readonly}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                id={`location-${index}`}
                label='Region/Location of Headquarters'
                value={competitor.location || ''}
                onChange={this.onChange}
                placeholder='Region/Location of Headquarters'
                readOnly={readonly}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                id={`website-${index}`}
                label='Website'
                value={competitor.website || ''}
                onChange={this.onChange}
                placeholder='Website'
                readOnly={readonly}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Strengths</label>
                <RichTextEditor
                  readOnly={readonly}
                  toolbarConfig={toolbarConfig}
                  placeholder="Competitor Strengths"
                  value={competitor.strengths || RichTextEditor.createEmptyValue()}
                  id={`strengths-${index}`}
                  onChange={(e) => this.onStrengthsChange(e, index)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Weaknesses</label>
                <RichTextEditor
                  readOnly={readonly}
                  toolbarConfig={toolbarConfig}
                  placeholder="Competitor Weaknesses"
                  value={competitor.weaknesses || RichTextEditor.createEmptyValue()}
                  id={`weaknesses-${index}`}
                  onChange={(e) => this.onWeaknessesChange(e, index)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </AccordionContent>
        </Accordion>
        </div>
    ));
  };

  renderButtons() {
    const { mode } = this.props;

    if (mode === 'readonly') {
      return null;
    }

    return (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Form.Button
              primary
              onClick={this.onSaveClick}
              icon
              labelPosition='right'
            >
              Save <Icon name='save' />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  popupContent = <>
    <p>This is where you document the competition your company will face.  This could be direct competitors, groups of companies that represent a market segment, or even inertia that prevent prospects from moving.  The key is to know their strengths and weaknesses.</p>
  </>;

  render() {
    const { company } = this.props;
    const { error } = this.state;
    const readonly = this.props.mode === 'readonly';

    return (
      <div>
        <MultiplySubheader text='Top Competitors' popupContent={this.popupContent} />
        <ErrorDisplay message={error} />
        <NavigationBlocker when={this.formIsDirty()} />
        {this.state.openDeleteModal && <DeleteModal onConfirm={this.onDeleteConfirm} onCancel={this.closeModal}/>}
        <Form loading={company.isLoading}>
          <Grid columns={2} stackable>
            <Grid.Row columns={1}>
              <Grid.Column>{this.renderTopCompetitors()}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column textAlign='right'>
                {readonly ? null : (
                  <Form.Button
                    primary
                    circular
                    icon
                    data-testid='add'
                    onClick={this.onAddClick}
                    basic
                  >
                    <Icon name='plus' />
                  </Form.Button>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>{this.renderButtons()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default observer(TopCompetitors);
