import React, { Component } from 'react';
import logo from '../../logo.png';
import { Menu, Icon, Accordion, Divider, Image } from 'semantic-ui-react';
import { Link, withRouter, } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Button, Select, Modal, Input } from 'semantic-ui-react';
import { CompanyInitializer } from '../../CompanyInitializer';
import { Company } from '../../models/Company';
import { observer } from 'mobx-react';
import { withAuth0 } from '@auth0/auth0-react';
import { getSnapshot } from 'mobx-state-tree';

class NavigationMenu extends Component {
    constructor() {
        super();
        this.state = { playbookActive: false };
    }
    businessLineChange(e) {
        this.setState({ newCompanyName: e.target.value });
    }

    async onAddBusinessLineClick(data) {
        try {
            this.setState({ isLoading: true });

            const { newCompanyName } = this.state;
            const { companyStore, usersStore, assessmentsStore, adminStore } = this.props;

            const company = Company.create({
                name: newCompanyName,
                phone: '',
                streetAddress: '',
                zipCode: '',
                city: '',
                state: '',
            });

            await company.createFromSnapshot({
                name: newCompanyName,
                phone: '',
                streetAddress: '',
                zipCode: '',
                city: '',
                state: '',
            });

            await usersStore.currentUser
                .updateFromSnapshot({ ...getSnapshot(usersStore.currentUser), enterpriseCompanyIds: [...usersStore.currentUser.enterpriseCompanyIds, company.id] });

            await adminStore.loadEnterprise(usersStore.currentUser.userId);
            await CompanyInitializer(
                companyStore,
                assessmentsStore,
                usersStore,
                company.id
            );

            this.setState({ selectedCompanyId: company.id });
        } catch (e) {
            throw e;
        } finally {
            this.setState({ isLoading: false });
        }
    }

    logout = () => {
        const { history } = this.props;

        history.push('/');
        this.props.auth0.logout({
            returnTo: window.location.origin,
        });
    };

    onHeaderClick(header) {
        const { history } = this.props;
        if (header === 'Company Overview') {
            history.push('/background');
        } else if (header === 'Feedback') {
            history.push('/schedule-assessments');
        } else if (header === 'Performance Reports') {
            history.push('/assessments-report');
        } else if (header === 'Offerings') {
            history.push('/offerings');
        } else if (header === 'Question And Answer') {
            history.push('/questions-answers');
        } else if (header === 'Recording') {
            history.push('/record-presentation');
        }
    }

    onModuleHeaderClick(module) {
        const { history } = this.props;

        history.push(module.articles[0].path);
    }

    companyOverviewActive() {
        const { history } = this.props;

        const path = history.location.pathname;

        return path === '/background'
            || path === '/differentiators'
            || path === '/prospect-profile'
            || path === '/top-competitors'
            || path === '/addressable-problems'
            || path === '/general-asks';
    }

    offeringsActive() {
        const { history } = this.props;

        const path = history.location.pathname;

        return path.indexOf('offerings') > -1;
    }

    moduleActive(module) {
        const { history } = this.props;

        const path = history.location.pathname;

        const paths = module.articles.map(article => article.path);

        for (let i = 0; i < paths.length; i++) {
            if (paths[i] === path) {
                return true;
            }
        }
        return false;
    }

    assessmentsActive() {
        const { history } = this.props;
        const path = history.location.pathname;

        return path === '/company-assessments'
            || path === '/offering-assessments'
            || path === '/schedule-assessments';
    }

    performanceReviewsActive() {
        const { history } = this.props;
        const path = history.location.pathname;

        return path === '/assessments-report'
            || path === '/value-report';
    }

    onCompanyChange(data) {
        this.props.onCompanyChange(data);
    }

    renderAdminSelector(selectedCompanyId, adminStore, isEnterpriseAdmin) {
        const { isLoading } = this.state;
        return <div>
            <Select
                loading={isLoading}
                placeholder='Companies'
                onChange={(_, d) => this.onCompanyChange(d)}
                value={this.props.selectedCompanyId || adminStore.companies[0]?.company.id}
                options={adminStore.companies.map((c) => ({
                    text: c.company.name,
                    key: c.company.id,
                    value: c.company.id,
                }))} />
            {isEnterpriseAdmin &&
                <Modal
                    trigger={<Button icon='plus' title='Add Company' onClick={this.handleAddCompany} circular style={{ marginLeft: 5 }} primary />}
                    header='Add Business Line'
                    content={<div style={{ margin: 15 }}>What is the name of your new business line? <br /><br /><Input fluid onChange={(e) => this.businessLineChange(e)}></Input></div>}
                    actions={['Cancel', { key: 'save', content: 'Save', positive: true, onClick: async () => await this.onAddBusinessLineClick() }]}
                    size="small"
                />}
        </div>;
    }

    render() {
        const { selectedCompanyId } = this.state;

        const { location, currentUser, companyStore, adminStore, modulesStore, path } = this.props;
        const { isAdmin, isSalesMember, isEnterpriseAdmin } = currentUser;
        const linkStyle = {
            marginLeft: '30px',
            textAlign: 'left'
        };
        const iconStyle = { float: 'left', marginRight: 10 };
        const dividerStyle = { borderTop: '1px solid rgba(7,132,167,.4)', width: 35, marginLeft: 10 };
        const menuItemStyle = { zIndex: 0 };

        const showAssessments = !isSalesMember;
        const showUserManagement = !isSalesMember;
        const showModules = modulesStore.modules.length > 0;

        return (
            <Accordion as={Menu}
                style={{ borderTop: 'none', borderBottom: 'none', boxShadow: 'none', borderRightWidth: path === '/record-presentation' ? '0' : '2px' }}
                vertical
                fluid
                secondary
                pointing>
                <Menu.Header style={{ textAlign: 'center' }}
                >
                    <Image src={logo} style={{ width: 100 }} alt="logo" as={Link}
                        to='/' />
                    {isAdmin && this.renderAdminSelector(selectedCompanyId, adminStore)}
                    {isEnterpriseAdmin && this.renderAdminSelector(selectedCompanyId, adminStore, true)}
                </Menu.Header>
                <Divider style={dividerStyle} />
                <Menu.Item style={menuItemStyle}>
                    <Menu.Item as={Accordion.Title} onClick={() => this.onHeaderClick('Company Overview')}>
                        <Icon name="building" style={iconStyle} />
                        Company Overview
                    </Menu.Item>
                    <Accordion.Content active={this.companyOverviewActive()} >
                        <Menu.Menu>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/background'}
                                to='/background'>
                                Background
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/differentiators'}
                                to='/differentiators'>
                                Differentiators
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/addressable-problems'}
                                to='/addressable-problems'>
                                Addressable Problems
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/top-competitors'}
                                to='/top-competitors'>
                                Top Competitors
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/general-asks'}
                                to='/general-asks'>
                                General Asks
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/prospect-profile'}
                                to='/prospect-profile'>
                                Prospect Profile
                            </Menu.Item>
                        </Menu.Menu>
                    </Accordion.Content>
                </Menu.Item>
                <Menu.Item style={menuItemStyle} as={Accordion.Title} onClick={() => this.onHeaderClick('Offerings')}>
                    <Icon name="newspaper" style={iconStyle} />
                    Offerings
                </Menu.Item>
                <Accordion.Content style={menuItemStyle} active={this.offeringsActive()} >
                    <Menu.Menu>
                        {companyStore.company.offerings.map((offering) => <Menu.Item style={linkStyle}
                            key={offering.offering}
                            as={HashLink}
                            active={location.pathname === `/offerings#${offering.offering}`}
                            to={`/offerings#${offering.offering}`}>
                            {offering.offering}
                        </Menu.Item>)}
                    </Menu.Menu>
                </Accordion.Content>
                <Menu.Item style={menuItemStyle} as={Accordion.Title} onClick={() => this.onHeaderClick('Question And Answer')}>
                    <Icon name="question" style={iconStyle} />
                    Question & Answer
                </Menu.Item>
                <Divider style={dividerStyle} />

                {/* <Menu.Item as={Accordion.Title} onClick={() => this.onHeaderClick('Recording')}>
                    <Icon name="video" style={iconStyle} />
                    Record Sales Message
                </Menu.Item> */}
                {showAssessments && (<Menu.Item>
                    <Menu.Item style={menuItemStyle} as={Accordion.Title} onClick={() => this.onHeaderClick('Feedback')}>
                        <Icon name="life ring outline" style={iconStyle} />
                        Feedback
                    </Menu.Item>
                    <Accordion.Content style={menuItemStyle} active={this.assessmentsActive()} >
                        <Menu.Menu>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/schedule-assessments'}
                                to='/schedule-assessments'>
                                Schedule Review
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/company-assessments'}
                                to='/company-assessments'>
                                Company Feedback
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/offering-assessments'}
                                to='/offering-assessments'>
                                Offering Feedback
                            </Menu.Item>
                        </Menu.Menu>
                    </Accordion.Content>
                </Menu.Item>)}
                <Menu.Item style={menuItemStyle}>
                    <Menu.Item as={Accordion.Title} onClick={() => this.onHeaderClick('Performance Reports')}>
                        <Icon name="chart bar" style={iconStyle} />
                        Performance Reports
                    </Menu.Item>
                    <Accordion.Content active={this.performanceReviewsActive()} >
                        <Menu.Menu>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/assessments-report'}
                                to='/assessments-report'>
                                Feedback Report
                            </Menu.Item>
                            <Menu.Item
                                style={linkStyle}
                                as={Link}
                                active={location.pathname === '/value-report'}
                                to='/value-report'>
                                Value Report
                            </Menu.Item>
                        </Menu.Menu>
                    </Accordion.Content>
                </Menu.Item>
                <Divider style={dividerStyle} />

                {showModules &&
                    <>
                        <Menu.Item style={menuItemStyle}>
                            <Menu.Item as={Accordion.Title} onClick={() => this.setState({ playbookActive: !this.state.playbookActive })}>
                                <Icon name="book" style={iconStyle} />
                                Playbook
                            </Menu.Item>
                            <Accordion.Content active={this.state.playbookActive}>
                                {
                                    modulesStore.modules.map(module =>
                                        <Menu.Item key={`module_${module.path}`}>
                                            <Menu.Item as={Accordion.Title} onClick={() => this.onModuleHeaderClick(module)}>
                                                <Icon name={module.icon} style={iconStyle} />
                                                {module.name}
                                            </Menu.Item>
                                            <Accordion.Content active={this.moduleActive(module)} >
                                                {module.articles.map((article, i) => <Menu.Item
                                                    key={`article_${article.key}`}
                                                    style={linkStyle}
                                                    as={Link}
                                                    active={location.pathname === article.path || (i === 0 && location.pathname === `/${module.path}`)}
                                                    to={article.path}>
                                                    {article.title}
                                                </Menu.Item>)}
                                            </Accordion.Content>
                                        </Menu.Item>
                                    )
                                }
                            </Accordion.Content>
                        </Menu.Item>
                        <Divider style={dividerStyle} />
                    </>
                }
                {showUserManagement && (<Menu.Item
                    as={Link}
                    to="users"
                    active={location.pathname === '/users'}>
                    <Icon name="users" style={iconStyle} />
                    Users
                </Menu.Item>)
                }
                <Menu.Item
                    as={Link}
                    to="profile"
                    active={location.pathname === '/profile'}>
                    <Icon name="user" style={iconStyle} />
                    Profile
                </Menu.Item>
                {isAdmin && (<Menu.Item
                    as={Link}
                    to="admin"
                    active={location.pathname === '/admin'}>
                    <Icon name="cogs" style={iconStyle} />
                    Admin
                </Menu.Item>)}
                <Menu.Item
                    as="a"
                    onClick={this.logout}>
                    <Icon name="sign-out" style={iconStyle} />
                    Sign Out
                </Menu.Item>
                <Divider style={dividerStyle} />
            </Accordion >

        );
    }
}

export default withAuth0(withRouter(observer(NavigationMenu)));