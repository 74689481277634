import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import CompaniesGrid from '../components/Admin/CompaniesGrid';
import { componentContainerStyle, componentContentStyle } from '../constants';

class AdminPage extends Component {
    render() {
        const { adminStore } = this.props;

        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Multiply Administrator" />
                <div style={componentContentStyle}>
                    <CompaniesGrid adminCompanies={adminStore.companies} />
                </div>
            </div>);
    }
}

export default observer(AdminPage);