import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { Grid, Button } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import NavigationMenu from '../components/NavigationMenu/NavigationMenu';
import UserManagement from '../components/UserManagement/UserManagement';
import UserProfile from '../pages/UserProfile';
import CompanyBackgroundPage from './CompanyBackgroundPage';
import DifferentiatorsPage from './DifferentiatorsPage';
import TargetProfilePage from './TargetProfilePage';
import TopCompetitorsPage from './TopCompetitorsPage';
import CompanyOfferingsPage from './CompanyOfferingsPage';
import ScheduleAssessmentsPage from './ScheduleAssessmentsPage';
import CompanyAssessmentsPage from './CompanyAssessmentsPage';
import OfferingAssessmentsPage from './OfferingAssessmentsPage';
import AssessmentsReportPage from './AssessmentsReportPage';
import ValueReportPage from './ValueReportPage';
import AdminPage from './AdminPage';
import HomeLandingPage from './HomeLandingPage';
import GeneralAsksPage from './GeneralAsksPage';
import ModuleArticlePage from './ModuleArticlePage';
import AddressableProblemsPage from './AddressableProblemsPage';
import { CompanyInitializer } from '../CompanyInitializer';
import { withAuth0 } from '@auth0/auth0-react';
import QuestionAnswerPage from './QuestionAnswerPage';
import RecordPresentationPage from './RecordPresentationPage';

class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }

  logout = () => {
    const { history } = this.props;

    history.push('/');

    this.props.auth0.logout({
      returnTo: window.location.origin,
    });
  };

  onCompanyChange = async (data) => {
    const { usersStore, companyStore, assessmentsStore, modulesStore } = this.props.rootStore;

    await CompanyInitializer(
      companyStore,
      assessmentsStore,
      usersStore,
      modulesStore,
      data.value
    );
    this.setState({ selectedCompanyId: data.value });
  };

  renderComponent(path) {
    const {
      usersStore,
      companyStore,
      assessmentsStore,
      adminStore,
      modulesStore,
      videoPresentationStore
    } = this.props.rootStore;

    const currentUser = usersStore.currentUser;
    const company = companyStore.company;

    if (!currentUser.userId || (!company.id && !currentUser.isEnterpriseAdmin)) {
      return <Redirect to='signup/onboarding' />;
    }

    const { isAdmin, isSalesMember } = usersStore.currentUser;

    const showAssessments = !isSalesMember;
    const showUserManagement = !isSalesMember;
    const mode = isSalesMember ? 'readonly' : undefined;
    const { selectedCompanyId } = this.state;

    return (
      <Grid
        stretched
        style={{ height: '100%'}}
        key={selectedCompanyId}
      >
        <Grid.Column width={3} style={{marginTop: 40}}>
          <NavigationMenu path={path} assessmentsStore={assessmentsStore} currentUser={usersStore.currentUser} companyStore={companyStore} adminStore={adminStore} usersStore={usersStore} modulesStore={modulesStore} onCompanyChange={this.onCompanyChange} selectedCompanyId={selectedCompanyId} />
        </Grid.Column>
        <Grid.Column width={13} style={{margin: 0, paddingTop: 40, paddingBottom: 40, background: path === '/record-presentation' && '#152445', height: path === '/record-presentation' && '100%'}}>
          <Route path='/' exact>
            <HomeLandingPage
              usersStore={usersStore}
              assessmentsStore={assessmentsStore}
              companyStore={companyStore}
            />
          </Route>
          <Route path='/background'>
            <CompanyBackgroundPage
              companyBackground={companyStore.company.background}
              mode={mode}
            />
          </Route>
          <Route path='/differentiators'>
            <DifferentiatorsPage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/general-asks'>
            <GeneralAsksPage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/addressable-problems'>
            <AddressableProblemsPage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/prospect-profile'>
            <TargetProfilePage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/top-competitors'>
            <TopCompetitorsPage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/offerings'>
            <CompanyOfferingsPage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/questions-answers'>
            <QuestionAnswerPage company={companyStore.company} mode={mode} />
          </Route>
          <Route path='/record-presentation'>
            <RecordPresentationPage videoPresentationStore={videoPresentationStore} usersStore={usersStore} mode={mode} />
          </Route>
          {showAssessments && (
            <Route path='/schedule-assessments'>
              <ScheduleAssessmentsPage
                usersStore={usersStore}
                assessmentsStore={assessmentsStore}
                company={company}
              />
            </Route>
          )}
          {showAssessments && (
            <Route path='/company-assessments'>
              <CompanyAssessmentsPage
                usersStore={usersStore}
                assessmentsStore={assessmentsStore}
              />
            </Route>
          )}
          {showAssessments && (
            <Route path='/offering-assessments'>
              <OfferingAssessmentsPage
                usersStore={usersStore}
                assessmentsStore={assessmentsStore}
              />
            </Route>
          )}
          <Route path='/assessments-report'>
            <AssessmentsReportPage
              usersStore={usersStore}
              assessmentsStore={assessmentsStore}
              companyStore={companyStore}
            />
          </Route>
          <Route
            path='/value-report/:userId?'
            children={
              <ValueReportPage
                usersStore={usersStore}
                assessmentsStore={assessmentsStore}
              />
            }
          />
          {showUserManagement && (
            <Route path='/users'>
              <UserManagement
                usersStore={usersStore}
                company={companyStore.company}
              />
            </Route>
          )}
          <Route path='/profile'>
            <UserProfile
              currentUser={usersStore.currentUser}
              editUser={usersStore.currentUser}
              companyStore={companyStore}
            />
          </Route>
          {isAdmin && (
            <Route path='/admin'>
              <AdminPage adminStore={adminStore} />
            </Route>
          )}
          {
            modulesStore.allArticles.map((article, i) =>
              <Route path={article.path} key={`article_${i}`}>
                <ModuleArticlePage article={article} />
              </Route>
            )
          }
        </Grid.Column>
      </Grid>
    );
  }

  renderInactive() {
    const textStyle = {
      padding: 10,
      color: '#0784a7',
    };

    return (
      <div style={textStyle}>
        Your account is inactive. Please contact your administrator.
        <br />
        <br />
        <Button onClick={this.logout}>Return to Login</Button>
      </div>
    );
  }

  renderDisabled() {
    const textStyle = {
      padding: 10,
      color: '#0784a7',
    };

    return (
      <div style={textStyle}>
        Your account is disabled due to an inactive subscription. Please contact
        your administrator.
        <br />
        <br />
        <Button href='#' onClick={this.logout}>
          Return to Login
        </Button>
      </div>
    );
  }

  render() {
    const { history } = this.props;
    const { usersStore } = this.props.rootStore;
    if (usersStore.currentUser.disabled) {
      return this.renderDisabled();
    } else if (usersStore.currentUser.active) {
      return this.renderComponent(history.location.pathname);
    } else {
      return this.renderInactive();
    }
  }
}

export default withAuth0(observer(Home));
