import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Grid, Header, Segment } from 'semantic-ui-react';
import UserPerfomanceDisplay from './UserPerfomanceDisplay';
import CompanyOverviewRollupAssessmentDisplay from './CompanyOverviewRollupAssessmentDisplay';
import OfferingRollupAssessmentDisplay from './OfferingRollupAssessmentDisplay';

class SalesManagerAssessmentRollupDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: '',
            isExecutive: props.usersStore.currentUser.isExecutive
        };
    }

    componentDidMount() {
        const { managerId } = this.props;

        this.getAssessmentsForManager(managerId);
    }

    managerOptions = () => {
        const { salesManagers, currentUser, companyExecutives } = this.props.usersStore;

        if (currentUser.userType === `Sales Manager`) {
            return [{
                text: `${currentUser.firstName} ${currentUser.lastName}`,
                value: currentUser.userId
            }];
        }

        let salesManagersOptions = salesManagers.map(user => ({
            text: `${user.firstName} ${user.lastName}`,
            value: user.userId
        }));

        const companyExecutivesOptions = companyExecutives.map(user => ({
            text: `${user.firstName} ${user.lastName}`,
            value: user.userId
        }));

        salesManagersOptions = salesManagersOptions.concat(companyExecutivesOptions)

        return salesManagersOptions
    }

    memberOptions = () => {
        const { managerId } = this.state;
        const { salesMembers } = this.props.usersStore;

        const members = salesMembers
            .filter(u => u.reportsToId === managerId)
            .map(user => ({
                text: `${user.firstName} ${user.lastName}`,
                value: user.userId
            }));
        members.unshift({ text: 'All', value: 0 });
        return members;
    }

    onSalesMemberChange = (data) => {
        this.getAssessmentsForUser(data.value);
    }

    getAssessmentsForUser(memberId) {
        const { salesMembers } = this.props.usersStore;
        const selectedMember = salesMembers.find(m => m.userId === memberId);
        this.setState({ selectedMember });
    }

    onSalesManagerChange = (data) => {
        this.getAssessmentsForManager(data.value);
    }

    getAssessmentsForManager(managerId) {
        const { usersStore, assessmentsStore, companyStore } = this.props;
        const salesMembers = usersStore.salesMembers
            .filter(u => u.reportsToId === managerId);

        const companyAssessments = [];
        const offeringAssessments = [];
        salesMembers.forEach(member => {
            const lastCompanyAssessment = assessmentsStore.assessmentHistoryForUser(member.userId, 'Company Overview').lastAssessment;
            if (!!lastCompanyAssessment) {
                companyAssessments.push(lastCompanyAssessment);
            }
            companyStore.company.offerings.forEach(offering => {
                const lastOfferingAssessment = assessmentsStore.assessmentHistoryForUser(member.userId, 'Offerings', offering.offering).lastAssessment;
                if (!!lastOfferingAssessment) {
                    offeringAssessments.push(lastOfferingAssessment);
                }
            })
        });
        this.setState({ managerId, selectedMember: undefined, companyAssessments, offeringAssessments });
    }

    render() {
        const { managerId, companyAssessments, offeringAssessments, selectedMember, isExecutive } = this.state;
        const salesMemberId = selectedMember?.userId ?? 0;

        return (
            <Segment padded>
                <div style={{ display: 'flex' }}>
                    {isExecutive && <Dropdown
                        button
                        className='icon'
                        labeled
                        search
                        icon="user"
                        onChange={((e, d) => this.onSalesManagerChange(d))}
                        options={this.managerOptions()}
                        value={managerId} />}
                    <Dropdown
                        style={{ marginLeft: 'auto' }}
                        button
                        className='icon'
                        labeled
                        search
                        icon="user"
                        onChange={((e, d) => this.onSalesMemberChange(d))}
                        options={this.memberOptions()}
                        value={salesMemberId} />
                </div>
                {
                    salesMemberId === 0 && <Grid padded>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Header as="h4">Feedback</Header>
                            </Grid.Column>
                            <Grid.Column>
                                <Header as="h4">Proficiency</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <CompanyOverviewRollupAssessmentDisplay assessments={companyAssessments} />
                        <OfferingRollupAssessmentDisplay assessments={offeringAssessments} offerings={this.props.companyStore.company.offerings} />
                    </Grid>
                }
                {salesMemberId !== 0 && <UserPerfomanceDisplay hideSelector={true} defaultUser={selectedMember} {...this.props} />}
            </Segment >);
    }
}

export default observer(SalesManagerAssessmentRollupDisplay);