import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Dropdown, Grid, Header, Segment } from 'semantic-ui-react';
import OfferingAssessmentDisplay from './OfferingAssessmentDisplay';
import CompanyOverviewAssessmentDisplay from './CompanyOverviewAssessmentDisplay';
import PreviousAssessmentDisplay from '../Assessments/PreviousAssessmentDisplay';

class UserPerformanceDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offeringAssessmentsForSelectedUser: [],
            selectedUserId: '',
            nextReviewDatesForSelectedUser: []
        };
    }

    componentDidMount() {
        const { defaultUser } = this.props;

        if (defaultUser) {
            this.getAssessmentsForUser(defaultUser.userId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultUser !== this.props.defaultUser && this.props.defaultUser) {
            this.getAssessmentsForUser(this.props.defaultUser.userId);
        }
    }

    companyUserOptions = () => {
        const { assessmentUsers } = this.props.usersStore;

        return assessmentUsers.map(user => ({
            text: `${user.firstName} ${user.lastName}`,
            value: user.userId
        }));
    }

    onUserChange = (data) => {
        this.getAssessmentsForUser(data.value);
    }

    getAssessmentsForUser(userId) {
        const { assessmentsStore, companyStore } = this.props;

        const company = companyStore.company;

        const offeringsAssessmentHistoryForSelectedUser = {};
        const lastOfferingAssessmentsForSelectedUser = [];
        const nextReviewDatesForSelectedUser = [];

        company.offerings.forEach(offering => {
            const offeringHistory = assessmentsStore.assessmentHistoryForUser(userId, 'Offerings', offering.offering);
            offeringsAssessmentHistoryForSelectedUser[offering.offering] = offeringHistory;
            let { lastAssessment } = offeringHistory;

            if (!lastAssessment) {
                lastAssessment = {
                    secondarySubject: offering.offering,
                };
            }

            lastOfferingAssessmentsForSelectedUser.push(lastAssessment);
            const nextReview = assessmentsStore.nextAssessmentForUser(userId, 'Offerings', offering.offering);

            if (nextReview) {
                nextReviewDatesForSelectedUser.push(nextReview.reviewDateTime);
            }
        });

        const companyOverviewAssessmentHistory = assessmentsStore.assessmentHistoryForUser(userId, 'Company Overview');
        let companyOverviewAssessmentForUser = companyOverviewAssessmentHistory.lastAssessment;
        const nextCompanyOverviewReview = assessmentsStore.nextAssessmentForUser(userId, 'Company Overview');

        if (!companyOverviewAssessmentForUser) {
            companyOverviewAssessmentForUser = { subject: 'Company Overview' };
        }
        this.setState({ offeringsAssessmentHistoryForSelectedUser, selectedUserId: userId, offeringAssessmentsForSelectedUser: lastOfferingAssessmentsForSelectedUser, nextReviewDatesForSelectedUser, companyOverviewAssessmentForUser, nextCompanyOverviewReviewDate: nextCompanyOverviewReview?.reviewDateTime, companyOverviewAssessmentHistory });
    }

    render() {
        const { hideSelector } = this.props;
        const { offeringsAssessmentHistoryForSelectedUser, offeringAssessmentsForSelectedUser, selectedUserId, nextReviewDatesForSelectedUser, companyOverviewAssessmentForUser, nextCompanyOverviewReviewDate, companyOverviewAssessmentHistory } = this.state;

        return (
            <Segment padded>
                {!hideSelector ? <Dropdown
                    button
                    className='icon'
                    labeled
                    search
                    icon="user"
                    onChange={((e, d) => this.onUserChange(d))}
                    options={this.companyUserOptions()}
                    value={selectedUserId} /> : null}
                <Grid padded>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as="h4">Previous Assessments</Header>
                            <div style={{ marginTop: -20 }}>
                                <PreviousAssessmentDisplay assessmentHistory={companyOverviewAssessmentHistory} label="Company Overview" />
                                {offeringsAssessmentHistoryForSelectedUser && Object.keys(offeringsAssessmentHistoryForSelectedUser)?.map((key, i) => <PreviousAssessmentDisplay key={i} assessmentHistory={offeringsAssessmentHistoryForSelectedUser[key]} label={key} />)}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Header as="h4">Feedback</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h4">Proficiency</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as="h4">Next Review</Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {companyOverviewAssessmentForUser && <CompanyOverviewAssessmentDisplay assessment={companyOverviewAssessmentForUser} nextReview={nextCompanyOverviewReviewDate} />}
                {offeringAssessmentsForSelectedUser.map((assessment, index) => <OfferingAssessmentDisplay key={`assessment-display-${index}`} assessment={assessment} nextReview={nextReviewDatesForSelectedUser[index]} />)}
            </Segment>);
    }
}

export default observer(UserPerformanceDisplay);