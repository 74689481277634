import React, { Component } from 'react';
import { Segment, Header, Divider, Icon } from 'semantic-ui-react';
import logo from '../../logo-icon.png';
export default class MultiplyHeader extends Component {
    render() {
        const { text, fontSize } = this.props;
        return (
            <Segment basic style={{paddingTop:0, marginTop: fontSize ? 0 : -51}}>
                <Header textAlign="center" icon as="h1" style={{fontFamily: 'Montserrat', textTransform: 'uppercase', color:"#23253A", fontWeight: 600, fontSize: fontSize? fontSize: 38, marginBottom: -9}}>
                    <Icon><img src={logo} style={{height: 68, marginBottom: -15}} alt="logo-icon"></img></Icon>
                    {text}
                </Header>
                {this.props.children}
                <Divider />
            </Segment>
        );
    }
}
 
