import React, { Component } from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

export default class Loading extends Component {
    render() {
        const { active } = this.props;
        return (
            <Dimmer.Dimmable>
                <Dimmer active={active} inverted>
                    <Loader />
                </Dimmer>
                {this.props.children}
            </Dimmer.Dimmable>
        );
    }
}