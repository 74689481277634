import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import CompanyOfferings from '../components/CompanyOfferings/CompanyOfferings';
import { componentContentStyle, componentContainerStyle } from '../constants';

class CompanyOfferingsPage extends Component {
    render() {
        const { company, mode } = this.props;
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Company Offerings" />
                <div sytle={componentContentStyle}>
                    <CompanyOfferings company={company} mode={mode} />
                </div>
            </div>);
    }
}

export default observer(CompanyOfferingsPage);