import { types, flow } from 'mobx-state-tree';
import { ApiStore } from './ApiStore';
import { Module } from './Module';

const _modulesStore = types.model('ModulesStore', {
    modules: types.array(Module)
}).actions((self) => ({
    load: flow(function* (companyId) {
        yield self.getApi(`/modules/${companyId}?populate=*`, self.modules);
    }),
})).views(self => ({
    get allArticles() {
        return self.modules.reduce((prev, current) => {
            prev.push(...current.articles);
            return prev;
        }, [])
    }
}));

export const ModulesStore = types.compose(_modulesStore, ApiStore);