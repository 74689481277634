import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-less/semantic.less';
import AppWithAuth from './AppWithAuth';

ReactDOM.render(
  <AppWithAuth />,
  document.getElementById('root')
);

