import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { componentContainerStyle, componentContentStyle } from '../constants';
import ModuleArticle from '../components/ModuleArticle/ModuleArticle';

class ModuleArticlePage extends Component {
    render() {
        const { article } = this.props;

        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text={article.moduleName} />
                <div style={componentContentStyle}>
                    <ModuleArticle content={article?.content} />
                </div>
            </div>);
    }
}

export default observer(ModuleArticlePage);