import { types, flow, getParent } from "mobx-state-tree";

export const CompanyBackground = types
  .model("CompanyBackground", {
    ceo: types.string,
    totalRevenue: types.maybe(types.number),
    yearFounded: types.maybe(types.number),
    numberOfEmployees: types.maybe(types.number),
    isPublic: types.boolean,
    numberOfOffices: types.maybe(types.number),
    history: types.string,
    mission: types.string,
    uploadKey: types.maybe(types.string),
    uploadLink: types.maybe(types.string),
  })
  .views((self) => ({
    get isLoading() {
      return getParent(self).isLoading;
    },
  }))
  .actions((self) => ({
    updateFromSnapshot: flow(function* updateFromSnapshot(snapshot) {
      yield getParent(self).updateCompanyBackgroundFromSnapshot(snapshot);
    }),
  }));

export const NullCompanyBackground = CompanyBackground.create({
  ceo: "",
  totalRevenue: undefined,
  yearFounded: undefined,
  numberOfEmployees: undefined,
  isPublic: false,
  numberOfOffices: undefined,
  history: "",
  mission: "",
  uploadKey: "",
  uploadLink: "",
});
