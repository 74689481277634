import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MulitplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import AssessmentPicker from '../components/Assessments/AssessmentPicker';
import AssessmentCompleter from '../components/Assessments/AssessmentCompleter';
import { Form } from 'semantic-ui-react';
import { componentContainerStyle, componentContentStyle } from '../constants';
import PreviousAssessmentDisplay from '../components/Assessments/PreviousAssessmentDisplay';
import { clone } from 'mobx-state-tree';

class OfferingAssessmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedAssessment: {} };
    }

    onAssessmentChange = (assessment) => {
        const { assessmentsStore } = this.props;
        const assessmentHistory = assessmentsStore.assessmentHistoryForUser(assessment.salesperson.userId, assessment.subject, assessment.secondarySubject);
        this.setState({ selectedAssessment: assessment, assessmentHistory: assessmentHistory });
    }

    onAssessmentComplete = () => {
        this.setState({ selectedAssessment: {}, salespersonAssessments: [] });
    }

    onAssessmentSalespersonChange = (salespersonId) => {
        const { assessmentsStore } = this.props;
        const salespersonAssessments = assessmentsStore
            .openAssessmentsForUser(salespersonId)
            .filter(assessment => assessment.subject === 'Offerings');

        this.setState({ salespersonAssessments, selectedAssessment: {} });
    }

    render() {
        const { usersStore, assessmentsStore } = this.props;
        const { selectedAssessment, salespersonAssessments, assessmentHistory } = this.state;

        return (
            <div style={componentContainerStyle}>
                <MulitplyHeader text="Offering Feedback" />
                <div style={componentContentStyle}>
                    <Form loading={assessmentsStore.isLoading}>
                        <AssessmentPicker {...this.props} onAssessmentChange={this.onAssessmentChange} onAssessmentSalespersonChange={this.onAssessmentSalespersonChange} salespersonAssessments={salespersonAssessments} />
                        <PreviousAssessmentDisplay assessmentHistory={assessmentHistory} />
                        <div style={{ marginTop: 20 }} />
                        {selectedAssessment.id ? <AssessmentCompleter assessment={selectedAssessment} assessmentHistory={assessmentHistory} onAssessmentComplete={this.onAssessmentComplete} currentUser={clone(usersStore.currentUser)} /> : null}
                    </Form>
                </div>
            </div>
        );
    }
}

export default observer(OfferingAssessmentPage);