import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Button } from 'semantic-ui-react';
import { AverageGradeCalculator } from '../../AverageGradeCalculator';

class OfferingRollupAssessmentDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordianContentVisible: false
        };
    }

    componentDidMount() {
        this.createRollups();
    }

    componentDidUpdate(previousProps) {
        if (previousProps === this.props) {
            return
        }
        this.createRollups();
    }

    createRollups() {
        const { assessments, offerings } = this.props;
        if (!assessments) {
            return;
        }
        const offeringAssessmentRollups = [];

        offerings.forEach(offering => {
            const assessmentsByName = assessments.filter(assessment => assessment.secondarySubject === offering.offering);
            const average = !assessmentsByName ? 'grey' : AverageGradeCalculator(assessmentsByName.map(assessment => assessment.grade));
            offeringAssessmentRollups.push({ name: offering.offering, grade: average });
        })

        this.setState({ offeringAssessmentRollups });
    }

    render() {
        const { offeringAssessmentRollups } = this.state;
        return (<>
            {offeringAssessmentRollups?.map((rollup, index) => (
                <Grid.Row columns={2} key={index}>
                    <Grid.Column>
                        {rollup.name}
                    </Grid.Column>
                    <Grid.Column>
                        <Button
                            data-testid="rating"
                            color={rollup.grade}
                            icon
                            size="massive"
                            circular
                            style={{ marginLeft: '19px' }} />
                    </Grid.Column>
                </Grid.Row>
            ))}
        </>);
    }
}

export default observer(OfferingRollupAssessmentDisplay);