import { types, getSnapshot, flow, clone } from 'mobx-state-tree';
import { User, NullUser } from './User';
import { ApiStore } from './ApiStore';
import { NullSalesPerformance, SalesPerformance } from './SalesPerformance';

const _usersStore = types.model('UsersStore', {
    currentUser: types.optional(User, getSnapshot(NullUser)),
    companyUsers: types.optional(types.array(User), []),
})
    .views(self => ({
        get isCurrentUserExecutive() {
            const { userType } = self.currentUser;
            return userType === 'Company Executive' ||
                userType === 'General Executive' ||
                userType === 'admin';
        },
        get isCurrentAdmin() {
            const { userType } = self.currentUser;
            return userType === 'admin';
        },
        get assessmentUsers() {
            const currentUser = self.currentUser;
            return self.companyUsers.filter(u =>
                (self.isCurrentAdmin || u.userType !== 'Company Executive') &&
                u.userType !== 'General Executive' &&
                (self.isCurrentUserExecutive || u.reportsToId === currentUser.userId) &&
                u.userId !== currentUser.userId);
        },
        get companyExecutives() {
            return self.companyUsers
                .filter(u => u.userType === 'Company Executive')
                .sort((u1, u2) => `${u1.lastName}_${u1.firstName}` > `${u2.lastName}_${u2.firstName}`);
        },
        get companyExecutivesWithSalesMemberReports() {
            return self.companyUsers
                .filter(u => u.userType === 'Company Executive' && self.salesMembers.some(member => member.reportsToId === u.userId))
                .sort((u1, u2) => `${u1.lastName}_${u1.firstName}` > `${u2.lastName}_${u2.firstName}`);
        },
        get salesMembers() {
            return self.companyUsers
                .filter(u => u.userType === 'Sales Member')
                .sort((u1, u2) => `${u1.lastName}_${u1.firstName}` > `${u2.lastName}_${u2.firstName}`);
        },
        get salesManagers() {
            return self.companyUsers
                .filter(u => u.userType === 'Sales Manager')
                .sort((u1, u2) => `${u1.lastName}_${u1.firstName}` > `${u2.lastName}_${u2.firstName}`);
        },
        getAggregateSalesPerformance(usersToAggregate) {
            const users = usersToAggregate ?? self.assessmentUsers;
            const salesPerformance = users
                .map(user => user.salesPerformance)
                .reduce((r1, r2) => {
                    return {
                        totalOpportunities: {
                            count: r1.totalOpportunities.count + (r2.totalOpportunities?.count || 0),
                            value: r1.totalOpportunities.value + (r2.totalOpportunities?.value || 0)
                        },
                        wonOpportunities: {
                            count: r1.wonOpportunities.count + (r2.wonOpportunities?.count || 0),
                            value: r1.wonOpportunities.value + (r2.wonOpportunities?.value || 0)
                        },
                        expense: {
                            salary: r1.expense.salary + (r2.expense?.salary || 0),
                            commissions: r1.expense.commissions + (r2.expense?.commissions || 0),
                            expenses: r1.expense.expenses + (r2.expense?.expenses || 0),
                            attributedExpenses: r1.expense.attributedExpenses + (r2.expense?.attributedExpenses || 0)
                        }
                    };
                },
                    getSnapshot(NullSalesPerformance));

            return SalesPerformance.create(salesPerformance);
        },
        getDirectReports(managerId) {
            return managerId ?
                self.companyUsers.filter(u => u.reportsToId === managerId)
                    .sort((u1, u2) => `${u1.lastName}_${u1.firstName}` > `${u2.lastName}_${u2.firstName}`)
                : [];
        },
    }));

export const UsersStore = types.compose(ApiStore, _usersStore)
    .actions(self => ({
        loadCurrentUser: flow(function* loadCurrentUser(userId) {
            yield self.getApi(`/users/${userId}`, self.currentUser);
        }),
        loadCompanyUsers: flow(function* loadCompanyUsers(companyId) {
            yield self.getApi(`/companies/${companyId}/users`, self.companyUsers);
        }),
        clearCurrentUser() {
            self.currentUser = null;
        },
        signUpUserFromSnapshot: flow(function* signUpUserFromSnapshot(userSnapshot) {
            self.isLoading = true;
            try {
                const body = { ...userSnapshot, active: true };
                delete body.userId;
                const model = clone(NullUser);
                body.email = body.email.toLowerCase();
                yield self.postApi('/users', body, model);
                self.companyUsers.push(model);
                self.isLoading = false;
            } catch (error) {
                throw error;
            } finally {
                self.isLoading = false;
            }
        }),
        deactivateCompanyExec: flow(function* deactivateCompanyExec(companyId) {
            self.isLoading = true;
            try {
                yield self.postApi('/users/companyexec/deactivate', { companyId });
                yield self.loadCompanyUsers(companyId);
            } catch (error) {
                throw error;
            } finally {
                self.isLoading = false;
            }
        })
    }));