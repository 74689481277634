import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Form, Button, Icon, Segment, AccordionTitle, AccordionContent, Accordion, } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import MultiplyUpload from '../MultiplyUpload/MultiplyUpload';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import { shallowEqual } from '../../ShallowEquality';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';
import InfoPopup from '../InfoPopup/InfoPopup';
import DeleteModal from '../DeleteModal/DeleteModal';

class CompanyOfferings extends Component {
  constructor(props) {
    super(props);

    const offeringsSnapshot = [...getSnapshot(props.company.offerings).map(offering => ({
      ...offering,
      asks: RichTextEditor.createValueFromString(offering.asks || '', 'markdown'),
      qualifyingQuestions: RichTextEditor.createValueFromString(offering.qualifyingQuestions || '', 'markdown'),
      differentiator: RichTextEditor.createValueFromString(offering.differentiator || '', 'markdown'),
      typicalObjections: RichTextEditor.createValueFromString(offering.typicalObjections || '', 'markdown'),
    }))];

    if (offeringsSnapshot.length === 0) {
      offeringsSnapshot.push({
        asks: RichTextEditor.createEmptyValue(),
        qualifyingQuestions: RichTextEditor.createEmptyValue(),
        differentiator: RichTextEditor.createEmptyValue(),
        typicalObjections: RichTextEditor.createEmptyValue(),
      });
    }

    this.state = {
      offeringsSnapshot,
      error: '',
      activeTab: [],
      openDeleteModal: false,
      deleteIndex: null
    };
  }

  onChange = (event) => {
    const nameAndIndex = event.target.id.split('-');
    const name = nameAndIndex[0];
    const index = nameAndIndex[1];
    const { offeringsSnapshot } = this.state;

    offeringsSnapshot[index] = {
      ...offeringsSnapshot[index],
      [name]: event.target.value,
    };

    this.setState({ offeringsSnapshot });
  };

  onRteChange = (event, index, name) => {


    const { offeringsSnapshot } = this.state;

    offeringsSnapshot[index] = {
      ...offeringsSnapshot[index],
      [name]: event,
    };

    this.setState({ offeringsSnapshot });
  };

  onUploadChange = (file, index) => {
    const { offeringsSnapshot } = this.state;

    if (!file) {
      const offering = { ...offeringsSnapshot[index] };
      delete offering.uploadKey;

      offeringsSnapshot[index] = offering;
      this.setState({ offeringsSnapshot });
    } else {
      offeringsSnapshot[index] = {
        ...offeringsSnapshot[index],
        uploadFile: file,
      };

      this.setState({ offeringsSnapshot });
    }
  };

  onRemoveClick = (index) => {
    this.setState({ openDeleteModal: true, deleteIndex: index });
  };

  onDeleteConfirm = () => {
    const { deleteIndex } = this.state;
    const offeringsSnapshot = [...this.state.offeringsSnapshot];

    offeringsSnapshot.splice(deleteIndex, 1);

    this.setState({ offeringsSnapshot, openDeleteModal: false });
  };

  closeModal = () =>{
    this.setState({ openDeleteModal: false });
  }

  onSaveClick = async () => {
    const { company } = this.props;
    const { offeringsSnapshot } = this.state;

    this.setState({ error: '' });

    try {
      const offeringUpdate = offeringsSnapshot.map(offering => ({
        ...offering,
        asks: offering.asks.toString('markdown'),
        qualifyingQuestions: offering.qualifyingQuestions.toString('markdown'),
        differentiator: offering.differentiator.toString('markdown'),
        typicalObjections: offering.typicalObjections.toString('markdown'),
      }));

      await company.updateOfferingsFromSnapshot(offeringUpdate);
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onAddClick = () => {
    const { offeringsSnapshot } = this.state;

    offeringsSnapshot.push({
      asks: RichTextEditor.createEmptyValue(),
      qualifyingQuestions: RichTextEditor.createEmptyValue(),
      differentiator: RichTextEditor.createEmptyValue(),
      typicalObjections: RichTextEditor.createEmptyValue(),
    });

    this.setState({ offeringsSnapshot: [...offeringsSnapshot] });
  };

  isSaveEnabled = () => {
    const { offeringsSnapshot } = this.state;
    return offeringsSnapshot.every(
      (offering) =>
        offering.offering &&
        offering.differentiator &&
        offering.asks &&
        offering.qualifyingQuestions &&
        offering.typicalObjections
    );
  };

  formIsDirty = () => {
    const { offeringsSnapshot } = this.state;
    const offerings = getSnapshot(this.props.company.offerings);
    const filteredOfferingsSnapshot = offeringsSnapshot.filter(d => Object.keys(d).length > 0);

    if (filteredOfferingsSnapshot.length !== offerings.length) {
      return true;
    }

    for (var i = 0; i < filteredOfferingsSnapshot.length; i++) {
      const snapshot = filteredOfferingsSnapshot[i];
      const model = offerings[i];

      if (!shallowEqual({
        ...snapshot,
        asks: snapshot.asks.toString('markdown'),
        qualifyingQuestions: snapshot.qualifyingQuestions.toString('markdown'),
        differentiator: snapshot.differentiator.toString('markdown'),
        typicalObjections: snapshot.typicalObjections.toString('markdown'),
      }, model)) {
        return true;
      }
    }

    return false;
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTab } = this.state;
    const newIndex = activeTab;
    const currentIndexPosition = activeTab.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }
    this.setState({ activeTab: newIndex });
  };

  asksPopupContent = <>
    <p>
      The “asks” are questions that you can use to get a prospect thinking or conversing about the problems you expect them to have.  These are the problems that your offerings solve.  The prospect may not have even realized they have these problems prior to their conversation with you.  The “asks” will be specific to each encounter as further explained in our Framework Documentation.
    </p>
  </>;

  qualifyingQuestionsPopupContent = <>
    <p>
      These are questions or ways you can determine if you have a contact, a prospect showing general interest, or a prospect who is showing to represent a lead or opportunity.  These can be small actions you want a prospect to take on your behalf or questions associated with budget for your product/service.
    </p>
  </>;

  objectionsPopupContent = <>
    <p>
      These are the questions (or pushbacks) that you can expect a prospect to make.  The goal is to document and anticipate them and then address them proactively in the overall sales messaging.  But because that isn’t always possible, you want to anticipate the objections and your strategy for addressing them.
    </p>
  </>;

  renderCompanyOffering(offering, index, moreThanOne, readonly) {

    return (
      <div style={{ marginBottom: "1rem" }} key={index}>
        <Accordion fluid styled exclusive={false}>
          <AccordionTitle
            active={this.state.activeTab.includes(index)}
            index={index}
            onClick={this.handleClick}
          >
            <Grid>
              {readonly ? (
                <Grid.Row columns={1}>
                  <Grid.Column textAlign="left">
                    <h3 id={offering.offering}>{offering.offering}</h3>
                    <MultiplyUpload
                      mode="readonly"
                      uploadKey={offering.uploadKey ?? ''}
                      uploadLink={offering.uploadLink ?? ''}
                      onChange={(file) => this.onUploadChange(file, index)}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="left">
                  <div style={{display:'flex'}}>
                    <h3 style={{marginRight:'10px'}} id={offering.offering}>{offering.offering}</h3>
                    <Icon name="dropdown" />
                    </div>
                    <MultiplyUpload
                      uploadKey={offering.uploadKey ?? ''}
                      uploadLink={offering.uploadLink ?? ''}
                      onChange={(file) => this.onUploadChange(file, index)}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            </AccordionTitle>
            <AccordionContent active={this.state.activeTab.includes(index)}>
              <div className='remove'>
              {moreThanOne ? (
                    <Button
                      style={{ marginTop: '-35px' }}
                      data-testid={`remove-${index}`}
                      icon="remove"
                      basic
                      negative
                      onClick={() => this.onRemoveClick(index)}
                      size="mini"
                      compact
                    />
                ) : null}
              </div>
            <Grid stackable>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Input
                    id={`offering-${index}`}
                    label={<label>Offering <InfoPopup popupContent={this.offeringsPopupContent} /></label>}
                    value={offering.offering || ''}
                    onChange={this.onChange}
                    readOnly={readonly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <label>Description / Differentiator</label>
                    <RichTextEditor
                      readOnly={readonly}
                      toolbarConfig={toolbarConfig}
                      value={offering.differentiator}
                      id={`differentiator-${index}`}
                      onChange={(e) => this.onRteChange(e, index, 'differentiator')}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>
                      Asks
                      <InfoPopup popupContent={this.asksPopupContent} />
                    </label>
                    <RichTextEditor
                      readOnly={readonly}
                      toolbarConfig={toolbarConfig}
                      value={offering.asks}
                      id={`asks-${index}`}
                      onChange={(e) => this.onRteChange(e, index, 'asks')}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <label>Qualifying Questions
                      <InfoPopup popupContent={this.qualifyingQuestionsPopupContent} />
                    </label>
                    <RichTextEditor
                      readOnly={readonly}
                      toolbarConfig={toolbarConfig}
                      value={offering.qualifyingQuestions}
                      id={`qualifyingQuestions-${index}`}
                      onChange={(e) => this.onRteChange(e, index, 'qualifyingQuestions')}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <label>Typical Objections
                      <InfoPopup popupContent={this.objectionsPopupContent} />
                    </label>
                    <RichTextEditor
                      readOnly={readonly}
                      toolbarConfig={toolbarConfig}
                      value={offering.typicalObjections}
                      id={`typicalObjections-${index}`}
                      onChange={(e) => this.onRteChange(e, index, 'typicalObjections')}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </AccordionContent>
      </Accordion>
      </div>
    );
  }

  offeringsPopupContent = <>
    <p>An offering is a product or service that your company produces, executes, or delivers.</p>
    <p><b>Differentiators / Definition</b></p>
    <p>This is where you define the offering.</p>
    <p>When you describe your offering, you have to define and differentiate the offering.In many cases, the differentiation may not be different than the macro differentiators that you defined in the Company Overview section.While that is fine, there could be differences, especially in larger companies.This is when you need to define the differentiating factors associated with the offering.This process is similar to the process in the Differentiators section of the Company Overview.</p >
  </>;
  render() {
    const { company, mode } = this.props;
    const { offeringsSnapshot, error, openDeleteModal } = this.state;
    const moreThanOne = offeringsSnapshot.length > 1;
    const readonly = mode === 'readonly';

    return (
      <Form loading={company.isLoading}>
        <ErrorDisplay message={error} />
        <NavigationBlocker when={this.formIsDirty()} />
        {openDeleteModal && <DeleteModal onConfirm={this.onDeleteConfirm} onCancel={this.closeModal}/>}
          {offeringsSnapshot.map((offering, index) =>
            this.renderCompanyOffering(offering, index, moreThanOne, readonly)
          )}
        <Grid>
          {!readonly ? (
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Button
                  onClick={this.onSaveClick}
                  disabled={!this.isSaveEnabled()}
                  primary
                  icon
                  labelPosition="right"
                >
                  Save <Icon name="save" />
                </Form.Button>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Form.Button
                  onClick={this.onAddClick}
                  primary
                  icon="add"
                  circular
                  basic
                  data-testid="add"
                />
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
      </Form>
    );
  }
}

export default observer(CompanyOfferings);
