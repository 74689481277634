import React, { Component, createRef } from "react";

class ScreenRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      chunks: [],
      videoUrl: "",
      mediaRecord: null,
      blobCount: 0,
      timestamp: "",
      stream: null,
      screenStream: null,
    };
    this.mediaRecorderRef = createRef(null);
    this.recordedChunksRef = [];
    this.videoRef = createRef(null);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buttonClick !== this.props.buttonClick) {
      if (this.props.buttonClick === "Start Recording") {
        this.startRecording();
      } else if (this.props.buttonClick === "Stop Recording") {
        this.stopRecording();
      }
    }
  }

  getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hour = date.getHours().toString().padStart(2, "0");
    let min = date.getMinutes().toString().padStart(2, "0");
    let sec = date.getSeconds().toString().padStart(2, "0");
    this.setState({ timestamp: `${year}${month}${day}${hour}${min}${sec}` });
  }

  startRecording = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        audio: false,
        video: { mediaSource: "screen" },
      });

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      this.videoRef.current.srcObject = stream;
      await this.videoRef.current.play();
      const mediaRecorder = new MediaRecorder(stream);
      this.setState({ mediaRecord: mediaRecorder, stream, screenStream });
      await this.togglePictureInPicture();

      const combinedStream = new MediaStream([
        ...screenStream.getTracks(),
        ...stream.getTracks(),
      ]);

      this.recordedChunksRef = [];
      this.mediaRecorderRef.current = new MediaRecorder(combinedStream, {
        mimeType: "video/mp4",
      });

      this.setState({ isRecording: true, chunks: []}, () => {
        this.mediaRecorderRef.current.ondataavailable = (event)  => this.handleDataAvailable(event);
        this.mediaRecorderRef.current.start(1000); 
			});

      this.getFormattedDate();
      this.props.startTimer();

      const data = {
        id: this.props.usersStore.currentUser.companyId,
        userId: this.props.usersStore.currentUser.userId,
        ContentType: "video/mp4",
        key: `recording_${this.state.timestamp}_sales_presentation`,
      };
      await this.props.videoPresentationStore.uploadInitialChunk(data);
    } catch (err) {
      console.error("Error: " + err);
    }
  };

  stopRecording = async () => {
    const { mediaRecord, chunks } = this.state;
    if (mediaRecord) {
      this.mediaRecorderRef.current.stop();
      this.props.stoptimer();
      await this.togglePictureInPicture();
      mediaRecord.stop();
      this.mediaRecorderRef.current.onstop = () => {
        this.stopMediaDevices();
        this.playRecordedVideo();
      };
      this.setState({ isRecording: false });
      const blobChunks = new Blob(chunks, { type: "video/mp4" });
      if (blobChunks.size) {
        this.createBlob(blobChunks, () => {
          this.setState({ chunks: [] });
        });
      }
    }
  };

  playRecordedVideo() {
    const blob = new Blob(this.recordedChunksRef, { type: "video/mp4" });
    const url = URL.createObjectURL(blob);
    this.setState({ videoUrl: url });
  }

  async stopMediaDevices() {
    const { stream, screenStream} = this.state;
    try {
      if (stream) {
        screenStream.getTracks().forEach((track) => track.stop());
        stream.getTracks().forEach((track) => track.stop());
      }
    } catch (err) {
      this.setState({ error: "Error stopping media devices: " + err.message });
    }
  }

  handleDataAvailable = (event) => {
    const { chunks } = this.state;
    this.recordedChunksRef.push(event.data);
    chunks?.push(event.data);
    const blobChunks = new Blob(chunks, { type: "video/mp4" });
    if (blobChunks.size >= 5 * 1024 * 1024) {
      this.createBlob(blobChunks, () => {
        this.setState({ chunks: [] });
      });
    }
  };

  createBlob = (blob, callback) => {
    const { usersStore } = this.props;
    const { blobCount, timestamp } = this.state;
    this.setState({ blobCount: blobCount + 1 }, () => {
      const data = {
        id: usersStore.currentUser.companyId,
        userId: usersStore.currentUser.userId,
        ContentType: "video/mp4",
        key: `recording_${timestamp}_sales_presentation`,
        partNumber: blobCount + 1,
      };
      this.callUploadChunck(data,blob);
      if (callback) callback();
    });
  };

  async callUploadChunck(data, blob) {
    const { videoPresentationStore } = this.props;
    await videoPresentationStore.uploadChunk(data, blob);
  }

  togglePictureInPicture = async () => {
    if (this.videoRef.current !== document.pictureInPictureElement) {
      try {
        await this.videoRef.current.requestPictureInPicture();
      } catch (error) {
        console.error('Error trying to enter Picture-in-Picture mode:', error);
      }
    } else {
      try {
        await document.exitPictureInPicture();
      } catch (error) {
        console.error('Error trying to exit Picture-in-Picture mode:', error);
      }
    }
  };

  render() {
    const { videoUrl } = this.state;
    const { buttonClick } = this.props;

    return (
      <div className="screen-recorder-component">
        {videoUrl && (
          <div>
            <video src={videoUrl} controls autoPlay style={{ width: "100%", height: '400px' }} />
          </div>
        )}
        {buttonClick === "Start Recording" && (
          <video ref={this.videoRef} muted autoPlay />
        )}
      </div>
    );
  }
}

export default ScreenRecorder;
