import { types } from 'mobx-state-tree';

export const ProspectProfile = types.model({
    industry: types.maybe(types.string),
    minRevenue: types.maybe(types.number),
    maxRevenue: types.maybe(types.number),
    location: types.maybe(types.string),
    minNumberOfEmployees: types.maybe(types.number),
    maxNumberOfEmployees: types.maybe(types.number),
    budgetForOffering: types.maybe(types.number),
    specificCharacteristics: types.maybe(types.string)
});

export const NullProspectProfile = ProspectProfile.create({
    industry: '',
    minRevenue: undefined,
    maxRevenue: undefined,
    location: '',
    minNumberOfEmployees: undefined,
    maxNumberOfEmployees: undefined,
    budgetForOffering: undefined,
    specificCharacteristics: ''
});