import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Grid, Form, Icon, Segment, Header, Button, AccordionTitle, AccordionContent, Accordion, } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import { shallowEqual } from '../../ShallowEquality';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';
import DeleteModal from '../DeleteModal/DeleteModal';

class ProspectProfile extends Component {
  constructor(props) {
    super(props);

    const prospectProfileSnapshot =
      props.company.prospectProfile.length > 0
        ? [
          ...props.company.prospectProfile
            .map(pp => ({ ...pp, specificCharacteristics: RichTextEditor.createValueFromString(pp.specificCharacteristics || '', 'markdown') }))
        ]
        : [{
          ...getSnapshot(props.company.prospectProfile),
          specificCharacteristics: RichTextEditor.createValueFromString(props.company.prospectProfile.specificCharacteristics || '', 'markdown')
        }];

    if (prospectProfileSnapshot.length === 0) {
      prospectProfileSnapshot.push({});
    }

    this.state = {
      prospectProfileSnapshot,
      error: '',
      activeTab: [],
      openDeleteModal: false,
      deleteIndex: null
    };
  }

  onChange = (event, index) => {
    const { prospectProfileSnapshot } = this.state;

    const newSnapshot = [...prospectProfileSnapshot];

    newSnapshot[index] = {
      ...newSnapshot[index],
      [event.target.name]:
        event.target.type === 'number'
          ? +event.target.value
          : event.target.value,
    };

    this.setState({ prospectProfileSnapshot: newSnapshot });
  };

  onRteChange = (event, index) => {
    const { prospectProfileSnapshot } = this.state;

    const newSnapshot = [...prospectProfileSnapshot];

    newSnapshot[index].specificCharacteristics = event;

    this.setState({ prospectProfileSnapshot: newSnapshot });
  };

  onSaveClick = async () => {
    const { company, saveCallback } = this.props;
    const { prospectProfileSnapshot } = this.state;

    const updateSnapshot = prospectProfileSnapshot.map(pp => ({ ...pp, specificCharacteristics: pp.specificCharacteristics.toString('markdown') }));

    this.setState({ error: '' });
    try {
      await company.updateProspectProfileFromSnapshot(updateSnapshot);

      if (saveCallback) {
        saveCallback();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onAddClick = () => {
    const { prospectProfileSnapshot } = this.state;

    const newState = [...prospectProfileSnapshot];

    newState.push({
      specificCharacteristics: RichTextEditor.createEmptyValue()
    });

    this.setState({ prospectProfileSnapshot: newState });
  };

  onRemoveClick = (index) => {
    this.setState({ openDeleteModal: true, deleteIndex: index });
  };

  onDeleteConfirm = () => {
    const { prospectProfileSnapshot, deleteIndex } = this.state;

    let newSnapshot = [...prospectProfileSnapshot];

    newSnapshot.splice(deleteIndex, 1);

    this.setState({ prospectProfileSnapshot: newSnapshot, openDeleteModal: false });
  };

  closeModal = () =>{
    this.setState({ openDeleteModal: false });
  }

  formIsDirty = () => {
    const { prospectProfileSnapshot } = this.state;
    const prospectProfile = getSnapshot(this.props.company.prospectProfile);
    const filteredProspectProfileSnapshot = prospectProfileSnapshot.filter(d => Object.keys(d).length > 0);

    if (filteredProspectProfileSnapshot.length !== prospectProfile.length) {
      return true;
    }

    for (var i = 0; i < filteredProspectProfileSnapshot.length; i++) {
      const snapshot = filteredProspectProfileSnapshot[i];
      const model = prospectProfile[i];

      if (!shallowEqual({ ...snapshot, specificCharacteristics: snapshot.specificCharacteristics.toString('markdown') }, model)) {
        return true;
      }
    }

    return false;
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeTab } = this.state;
    const newIndex = activeTab;
    const currentIndexPosition = activeTab?.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }
    this.setState({ activeTab: newIndex });
  };

  renderProspectProfiles() {
    const { prospectProfileSnapshot, activeTab } = this.state;
    const isReadonly = this.props.mode === 'readonly';
    const moreThanOne = prospectProfileSnapshot.length > 1;
    return prospectProfileSnapshot.map((prospectProfile, index) => (
      <div style={{ marginBottom: "1rem" }} key={index}>
        <Accordion fluid styled exclusive={false}>
        <AccordionTitle
            active={activeTab?.includes(index)}
            index={index}
            onClick={this.handleClick}
            >
        <Grid stackable columns={1}>
          <Grid.Row>
            <Grid.Column>
            <Icon name="dropdown" />
              <Header as='h4' floated='left'>
                Prospect Profile # {index + 1}
              </Header>
            </Grid.Column>
          </Grid.Row>
          </Grid>
          </AccordionTitle>
          <AccordionContent active={activeTab?.includes(index)}>
          {!isReadonly && moreThanOne ? (
                <Button
                  style={{ marginTop: '-35px' }}
                  data-testid={`remove-${index}`}
                  size='mini'
                  compact
                  onClick={() => this.onRemoveClick(index)}
                  icon
                  basic
                  negative
                  floated='right'
                >
                  <Icon name='remove' />
                </Button>
              ) : null}
          <Grid stackable columns={1}>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Input
                label='Industry'
                placeholder='Industry'
                value={prospectProfile.industry || ''}
                name='industry'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
            <Grid.Column>
              <CurrencyInput
                label='Min Revenue'
                placeholder='Min Revenue'
                value={prospectProfile.minRevenue}
                name='minRevenue'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
            <Grid.Column>
              <CurrencyInput
                label='Max Revenue'
                placeholder='Max Revenue'
                value={prospectProfile.maxRevenue}
                name='maxRevenue'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Form.Input
                label='Region or Location of Headquarters'
                placeholder='Region or Location of Headquarters'
                value={prospectProfile.location || ''}
                name='location'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label='Min Number of Employees'
                placeholder='Min Number of Employees'
                value={prospectProfile.minNumberOfEmployees || ''}
                name='minNumberOfEmployees'
                type='number'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label='Max Number of Employees'
                placeholder='Max Number of Employees'
                value={prospectProfile.maxNumberOfEmployees || ''}
                name='maxNumberOfEmployees'
                type='number'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
            <Grid.Column>
              <CurrencyInput
                label='Overall Budget for your Offering'
                placeholder='Overall Budget for your Offering'
                value={prospectProfile.budgetForOffering}
                name='budgetForOffering'
                onChange={(event) => this.onChange(event, index)}
                readOnly={isReadonly}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Specific Charateristics</label>
                <RichTextEditor
                  onChange={(event) => this.onRteChange(event, index)}
                  readOnly={isReadonly}
                  toolbarConfig={toolbarConfig}
                  placeholder="Unique qualities of companies you prospect"
                  value={prospectProfile.specificCharacteristics}
                  name='specificCharacteristics'
                  data-testid="qualities"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </AccordionContent>
      </Accordion>
      </div>
    ));
  }

  renderButtons() {
    const { mode } = this.props;
    const { prospectProfileSnapshot } = this.state;
    if (mode === 'readonly') {
      return null;
    }

    const saveEnabled =
      prospectProfileSnapshot.length > 1 || prospectProfileSnapshot[0];

    return (
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Form.Button
              onClick={this.onSaveClick}
              primary
              icon
              labelPosition='right'
              disabled={!saveEnabled}
            >
              Save
              <Icon name='save' />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  popupContent = <>
    <p>Every company has ideal types of prospects it wants to sell to.  These can be company personas, vertical personas, or even individuals within the organization.  There is no perfect set of information.  The important thing is to recognize and document the characteristics of each prospect profile.</p>
  </>;

  render() {
    const { error } = this.state;
    const { company, mode } = this.props;
    const isReadonly = mode === 'readonly';
    return (
      <div>
        <MultiplySubheader text='Prospect Profiles' popupContent={this.popupContent} />
        <ErrorDisplay message={error} />
        <NavigationBlocker when={this.formIsDirty()} />
        {this.state.openDeleteModal && <DeleteModal onConfirm={this.onDeleteConfirm} onCancel={this.closeModal}/>}
        <Segment>
          <Form loading={company.isLoading}>
            {this.renderProspectProfiles()}
            <Grid stackable columns={1}>
              <Grid.Row>
                <Grid.Column textAlign='right'>
                  {!isReadonly ? (
                    <Form.Button
                      primary
                      onClick={this.onAddClick}
                      icon
                      circular
                      basic
                      data-testid='add'
                    >
                      <Icon name='plus' />
                    </Form.Button>
                  ) : null}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>{this.renderButtons()}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Segment>
      </div>
    );
  }
}

export default observer(ProspectProfile);
