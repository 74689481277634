import React, { Component } from 'react';
import { Form, Icon, Grid } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
class User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editUserSnapshot: getSnapshot(props.editUser)
        };
    }

    saveUser = async () => {
        const { editUserSnapshot } = this.state;
        const { editUser } = this.props;
        this.setState({ error: '' });
        try {
            await editUser.updateFromSnapshot(editUserSnapshot);
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    onFirstNameChange = e => {
        const { editUserSnapshot } = this.state;
        this.setState({ editUserSnapshot: { ...editUserSnapshot, firstName: e.target.value } });
    }

    onLastNameChange = e => {
        const { editUserSnapshot } = this.state;
        this.setState({ editUserSnapshot: { ...editUserSnapshot, lastName: e.target.value } });
    }

    render() {
        const { editUserSnapshot, error } = this.state;

        return (
            <Form loading={this.props.editUser.isLoading}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Input
                                label='First Name'
                                placeholder='First Name'
                                value={editUserSnapshot.firstName}
                                onChange={this.onFirstNameChange} />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                label='Last Name'
                                placeholder='Last Name'
                                value={editUserSnapshot.lastName}
                                onChange={this.onLastNameChange} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Input
                                readOnly
                                label='Email'
                                placeholder='Email'
                                value={editUserSnapshot.email} />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Input
                                readOnly
                                label='User Type'
                                placeholder='User Type'
                                value={editUserSnapshot.userType} />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <ErrorDisplay message={error} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Button primary icon labelPosition="right" onClick={this.saveUser}><Icon name="save" />Save</Form.Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>);
    }
}
export default observer(User);