import React, { Component } from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';

export class AssessmentPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salespersonAssessments: [],
            selectedSalespersonId: '',
            salespersonAssessmentHistory: {}
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.salespersonAssessments !== this.props.salespersonAssessments && this.props.salespersonAssessments.length === 0) {
            this.setState({ selectedSalespersonId: '', salespersonAssessmentHistory: {} });
        }
    }

    onSalespersonChange = (data) => {
        const { onAssessmentSalespersonChange } = this.props;

        onAssessmentSalespersonChange(data.value);
        this.setState({ selectedSalespersonId: data.value, salespersonAssessmentHistory: {} });
    };

    onSalespersonAssessmentChange = (data) => {
        const { onAssessmentChange, assessmentsStore } = this.props;
        const { selectedSalespersonId } = this.state;

        const assessment = assessmentsStore.assessmentForId(data.value);
        onAssessmentChange(assessment);
        const salespersonAssessmentHistory = assessmentsStore.assessmentHistoryForUser(selectedSalespersonId, assessment.subject, assessment.secondarySubject);

        this.setState({ salespersonAssessmentHistory, selectedAssessment: assessment });
    };

    createOptionsForAssessments(assessments) {
        if (!assessments) {
            return [];
        }

        return assessments.map(assessment => {
            const mapped = { value: assessment.id };
            if (assessment.secondarySubject) {
                mapped.text = `${assessment.subject} - ${assessment.secondarySubject} - ${new Date(assessment.reviewDateTime).toLocaleDateString()}`;
            } else {
                mapped.text = `${assessment.subject} - ${new Date(assessment.reviewDateTime).toLocaleDateString()}`;
            }

            return mapped;
        });
    }

    render() {
        const { assessmentsStore, usersStore, salespersonAssessments } = this.props;
        const { salespersonAssessmentHistory, selectedSalespersonId, selectedAssessment } = this.state;
        const currentUser = usersStore.currentUser;

        const salespersonAssessmentsOptions = this.createOptionsForAssessments(salespersonAssessments);

        const userOptions = assessmentsStore
            .usersWithOpenAssessments
            .filter(u => (u.userId !== currentUser.userId && u.reportsToId === currentUser.userId) || currentUser.userType === 'Company Executive' || currentUser.userType === 'General Executive' || currentUser.userType === 'admin')
            .map(user => ({
                text: `${user.firstName} ${user.lastName}`,
                key: user.userId,
                value: user.userId
            }));

        const lastAssessmentColor = salespersonAssessmentHistory.lastAssessment &&
            salespersonAssessmentHistory.lastAssessment.grade ?
            salespersonAssessmentHistory.lastAssessment.grade : 'grey';

        return (
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Form.Dropdown
                            label="Salesperson"
                            placeholder="Salesperson"
                            selection
                            options={userOptions}
                            onChange={(e, d) => this.onSalespersonChange(d)}
                            noResultsMessage="No Open Assessements"
                            value={selectedSalespersonId}
                            search
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            label="Reviewer"
                            id="reviewer"
                            value={`${currentUser.firstName} ${currentUser.lastName}`}
                            readOnly />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Form.Dropdown
                            selection
                            label="Feedback"
                            options={salespersonAssessmentsOptions}
                            onChange={(e, d) => this.onSalespersonAssessmentChange(d)}
                            search
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            id="numberOfAssessments"
                            readOnly
                            label="Previous Feedback Count"
                            value={assessmentsStore.assessmentCountForSessionType(selectedSalespersonId, selectedAssessment?.sessionType)}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <div className="field">
                            <label>Last Feedback</label>
                            <Button
                                data-testid="last-assessment-grade"
                                color={lastAssessmentColor}
                                icon
                                size="massive"
                                circular
                                style={{ marginLeft: '35px' }} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>);
    }
}

export default observer(AssessmentPicker);