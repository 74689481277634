import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Button } from 'semantic-ui-react';
import { AverageGradeCalculator } from '../../AverageGradeCalculator';

class CompanyOverviewRollupAssessmentDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordianContentVisible: false
        };
    }

    componentDidMount() {
        this.calculateGrades();
    }

    componentDidUpdate(previousProps) {
        if (previousProps === this.props) {
            return
        }
        this.calculateGrades();
    }

    calculateGrades() {
        const { assessments } = this.props;
        const grades = assessments?.map(a => a.grade);
        this.setState({ gradeColor: !grades ? 'grey' : AverageGradeCalculator(grades) });
    }

    render() {
        const { gradeColor } = this.state;

        return (
            <Grid.Row columns={2}>
                <Grid.Column>
                    Company Overview
                </Grid.Column>
                <Grid.Column>
                    <Button
                        data-testid="rating"
                        color={gradeColor}
                        icon
                        size="massive"
                        circular
                        style={{ marginLeft: '19px' }} />
                </Grid.Column>
            </Grid.Row>
        );
    }
}

export default observer(CompanyOverviewRollupAssessmentDisplay);