import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CompanyOverview from '../components/CompanyOverview/CompanyOverview';
import TopCompetitors from '../components/CompanyOverview/TopCompetitors';

class TopCompetitorsPage extends Component {
    render() {
        const { company, step, saveCallback, mode } = this.props;

        return (
            <CompanyOverview step={step}>
                <TopCompetitors company={company} saveCallback={saveCallback} mode={mode} />
            </CompanyOverview>);
    }
}

export default observer(TopCompetitorsPage);