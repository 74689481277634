import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CompanyOverview from '../components/CompanyOverview/CompanyOverview';
import GeneralAsks from '../components/CompanyOverview/GeneralAsks';

class GeneralAsksPage extends Component {
    render() {
        const { company, saveCallback, mode, step } = this.props;
        return (
            <CompanyOverview step={step}>
                <GeneralAsks company={company} mode={mode} saveCallback={saveCallback} />
            </CompanyOverview>
        );
    }
}

export default observer(GeneralAsksPage);
