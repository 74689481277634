import React, { Component } from 'react';
import { Table, Button, Input, Dropdown, Form, Icon, Grid, Checkbox, Message, Radio, Modal } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import { componentContainerStyle, componentContentStyle } from '../../constants';
import Loading from '../Loading/Loading';
import MultiplyHeader from '../MultiplyHeader/MultiplyHeader';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';

const blankUser = {
    firstName: '',
    lastName: '',
    email: '',
    userType: '',
    mfaEnabled: false,
};

class UserManagement extends Component {
    constructor(props) {
        super(props);

        blankUser.reportsToId = props.usersStore.companyExecutives[0]?.userId || props.usersStore.currentUser.userId;

        this.state = {
            editIndex: -1,
            editUser: {},
            newUser: { ...blankUser },
            showPassword: false,
            error: '',
            showDeactivateCompanyExec: false
        };
    }

    onEditClick(i) {
        const { companyUsers } = this.props.usersStore;
        this.setState({ editIndex: i, editUser: getSnapshot(companyUsers[i]) });
    }

    onCancelClick(i) {
        this.setState({ editIndex: -1 });
    }

    onGridDropdownChange(data, i) {
        let user = { ...this.state.editUser };

        user[data.name] = data.value;

        this.setState({ editUser: user });
    }

    onCheckboxChanged(data, i) {
        let user = { ...this.state.editUser };

        user[data.name] = data.checked;

        this.setState({ editUser: user });
    }

    onGridTextChange(event) {
        let user = { ...this.state.editUser };

        user[event.target.name] = event.target.value;

        this.setState({ editUser: user });
    }

    onNewUserDropdownChange = data => {
        const { newUser } = this.state;

        newUser[data.name] = data.value;
        this.setState({ newUser });
    };

    onNewUserTextChange = (event) => {
        const { newUser } = this.state;

        newUser[event.target.name] = event.target.value;
        this.setState({ newUser });
    };

    onNewUserMfaChange = (_, data) => {
        const { newUser } = this.state;
        newUser.mfaEnabled = data.checked;
        this.setState({ newUser });
    };

    onEditUserMfaEnabledChange = (_, data) => {
        let user = { ...this.state.editUser };

        user.mfaEnabled = data.checked;

        this.setState({ editUser: user });
    };

    async onSaveClick(i) {
        const userSnapshot = this.state.editUser;

        this.setState({ error: '' });

        if (userSnapshot.userType === 'Company Executive' && !userSnapshot.active) {
            this.setState({ showDeactivateCompanyExec: true });
        } else {
            const user = this.props.usersStore.companyUsers[i];
            try {
                await user.updateFromSnapshot(userSnapshot);

                this.setState({ editIndex: -1 });
            } catch (error) {
                this.setState({ error: error.message });
            }
        }
    }

    saveNewUser = async () => {
        const { usersStore, company } = this.props;
        const newUser = { ...this.state.newUser, companyId: company.id };

        if (newUser.userType === 'General Executive') {
            delete newUser.reportsToId;
        }

        this.setState({ error: '' });

        try {
            await usersStore.signUpUserFromSnapshot(newUser);
            // await company.chargeForUser();
            this.setState({ newUser: { ...blankUser } });
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    formatBoolean(value) {
        return value ? 'Yes' : 'No';
    }

    userTypeOptions = [
        {
            key: 'General Executive',
            text: 'General Executive',
            value: 'General Executive'
        },
        {
            key: 'Sales Manager',
            text: 'Sales Manager',
            value: 'Sales Manager'
        },
        {
            key: 'Sales Member',
            text: 'Sales Member',
            value: 'Sales Member'
        }
    ];

    userFromId = (id) => {
        const { usersStore } = this.props;
        const user = usersStore.companyUsers.find(u => u.userId === id);
        return user ? `${user.firstName} ${user.lastName}` : '';
    };

    potentialManagers = (editUser) => {
        const { usersStore } = this.props;

        const salesManagers = usersStore.salesManagers.map(m => {
            return {
                key: m.userId,
                text: `${m.firstName} ${m.lastName}`,
                value: m.userId
            };
        });

        const companyExecutives = usersStore.companyExecutives.map(m => {
            return {
                key: m.userId,
                text: `${m.firstName} ${m.lastName}`,
                value: m.userId
            };
        });

        switch (editUser.userType) {
            case 'General Executive':
            case 'Enterprise Executive':
            case 'Company Executive':
            case 'admin':
                return [];
            case 'Sales Manager':
                return [...companyExecutives];
            default:
                return [...salesManagers, ...companyExecutives];
        }
    };

    saveButtonEnabled = () => {
        const { newUser } = this.state;
        return newUser.firstName
            && newUser.lastName
            && newUser.email
            && newUser.userType
            && newUser.email?.trim() === newUser.confirmEmail?.trim();
    };

    togglePassword = () => {
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    };

    isCurrentUser = (user) => {
        const { currentUser } = this.props.usersStore;

        return currentUser.userId === user.userId;
    };

    getRankForUser = (user) => {
        switch (user.userType) {
            case 'admin':
                return 5;
            case 'Company Executive':
                return 4;
            case 'General Executive':
                return 3;
            case 'Sales Manager':
                return 2;
            default:
                return 0;
        }
    };

    hasHigherRank = (user) => {
        const { currentUser } = this.props.usersStore;
        return this.getRankForUser(currentUser) >= this.getRankForUser(user);
    };

    isCompanyExecutive = (user) => {
        return user.userType === 'Company Executive';
    };

    isAdmin = (user) => {
        return user.isAdmin;
    };

    canChangeUserType = (user) => {
        return !this.isCompanyExecutive(user) && !this.isCurrentUser(user);
    };

    canChangeActiveStatus = (user) => {
        const { currentUser } = this.props.usersStore;

        return (!this.isCompanyExecutive(user) || currentUser.isAdmin) && !this.isCurrentUser(user);
    };

    canChangeReportsTo = (user) => {
        return !this.isCompanyExecutive(user) && !this.isCurrentUser(user);
    };

    deactivateCompanyExec = async (user) => {
        const { usersStore } = this.props;

        if (user.isCompanyExecutive) {
            console.log('User in not company executive');
            return;
        }

        await usersStore.deactivateCompanyExec(user.companyId);
    };

    renderNewUserForm() {
        const { newUser, showDeactivateCompanyExec, editUser } = this.state;
        const { usersStore } = this.props;
        return (<Form loading={usersStore.isLoading}>
            <Modal open={showDeactivateCompanyExec}>
                <Modal.Header>Deactivate Company Executive</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to deactivate the company executive?  This will deactive all users for the company.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => {
                            let user = { ...this.state.editUser };

                            user.active = true;

                            this.setState({ editUser: user, showDeactivateCompanyExec: false });
                        }}
                        negative
                    >
                        No
                    </Button>
                    <Button
                        loading={usersStore.isLoading}
                        onClick={async () => {
                            await this.deactivateCompanyExec(editUser);
                            this.setState({ showDeactivateCompanyExec: false, editIndex: -1 });
                        }}
                        positive
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-first-name"
                            required
                            label="First Name"
                            placeholder="First Name"
                            name="firstName"
                            value={newUser.firstName}
                            onChange={this.onNewUserTextChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-last-name"
                            required
                            label="Last Name"
                            placeholder="Last Name"
                            name="lastName"
                            value={newUser.lastName}
                            onChange={this.onNewUserTextChange}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-email"
                            required
                            label="Email"
                            placeholder="Email"
                            name="email"
                            value={newUser.email}
                            onChange={this.onNewUserTextChange}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field label="MFA Enabled" />
                        <Form.Radio toggle name="mfaEnabled" onChange={this.onNewUserMfaChange} />
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Dropdown
                            data-testid="new-type"
                            required
                            selection
                            label="Type"
                            placeholder="Type"
                            options={this.userTypeOptions}
                            name="userType"
                            text={newUser.userType}
                            value={newUser.userType}
                            onChange={(e, d) => this.onNewUserDropdownChange(d)}
                            search
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {newUser.userType !== 'General Executive' ? <Form.Dropdown
                            data-testid="new-reports-to"
                            required
                            selection
                            label="Reports To"
                            placeholder="Reports To"
                            options={this.potentialManagers(newUser)}
                            name="reportsToId"
                            value={newUser.reportsToId}
                            onChange={(e, d) => this.onNewUserDropdownChange(d)}
                            search
                        /> : null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column>
                        <Form.Input
                            data-testid="new-email"
                            required
                            label="Confirm Email"
                            placeholder="Confirm Email"
                            name="confirmEmail"
                            value={newUser.confirmEmail}
                            onChange={this.onNewUserTextChange}
                            error={!!newUser.confirmEmail && newUser.confirmEmail !== newUser.email ? 'Emails are different.' : undefined}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column verticalAlign="bottom">
                        <Form.Button primary icon labelPosition="right" onClick={this.saveNewUser} disabled={!this.saveButtonEnabled()}><Icon name="user plus" />Save New User</Form.Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Message color="blue">You are billed $15 monthly for each active user.</Message>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form >);
    }

    renderEditButtons(editIndex, user, i) {
        const { currentUser } = this.props.usersStore;

        if (!this.isAdmin(currentUser) && !this.isCompanyExecutive(currentUser) && this.isCompanyExecutive(user)) {
            return null;
        }

        if (!this.hasHigherRank(user)) {
            return null;
        }

        if (editIndex !== i) {
            return <Button primary compact data-testid={`edit-${i}`} basic size="small" icon="edit" onClick={() => this.onEditClick(i)}></Button>;
        }

        return <div>
            <Button primary compact data-testid={`save-${i}`} basic size="small" icon="save" onClick={() => this.onSaveClick(i)}></Button>
            <Button negative compact data-testid={`cancel-${i}`} basic size="small" icon="cancel" onClick={() => this.onCancelClick(i)}></Button>
        </div>;
    }

    renderTableRow(user, i, editIndex, editUser) {
        const reportsToOptions = this.potentialManagers(user);
        const reportsToName = this.userFromId(editUser.reportsToId);

        return (<Table.Row key={`user_row_${i}`} style={{ overflow: 'visible' }}>
            <Table.Cell>
                {this.renderEditButtons(editIndex, user, i)}
            </Table.Cell>
            <Table.Cell>
                {editIndex === i && this.canChangeActiveStatus(user) ?
                    <Checkbox toggle data-testid={`edit-active-${i}`} name="active" onChange={(e, d) => this.onCheckboxChanged(d, i)} checked={editUser.active} />
                    : this.formatBoolean(user.active)}
            </Table.Cell>
            <Table.Cell>{editIndex !== i ?
                user.firstName :
                <Input data-testid={`edit-first-name-${i}`} fluid name="firstName" onChange={(e) => this.onGridTextChange(e, i)} placeholder='First Name' value={editUser.firstName} />}
            </Table.Cell>
            <Table.Cell>{editIndex !== i ?
                user.lastName :
                <Input data-testid={`edit-last-name-${i}`} fluid name="lastName" onChange={(e) => this.onGridTextChange(e, i)} placeholder='Last Name' value={editUser.lastName} />}
            </Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{editIndex !== i ? this.formatBoolean(user.mfaEnabled) :
                <Radio toggle name="mfaEnabled" onChange={this.onEditUserMfaEnabledChange} checked={editUser.mfaEnabled} />}
            </Table.Cell>
            <Table.Cell>{editIndex === i && this.canChangeUserType(user) ?
                <Dropdown search data-testid={`edit-type-${i}`} fluid name="userType" selection options={this.userTypeOptions} onChange={(e, d) => this.onGridDropdownChange(d, i)} placeholder='Type' value={editUser.userType} text={editUser.userType} />
                : user.userType}
            </Table.Cell>
            <Table.Cell>{editIndex === i && this.canChangeReportsTo(user) ?
                <Dropdown search data-testid={`edit-report-${i}`} fluid name="reportsToId" selection options={reportsToOptions} onChange={(e, d) => this.onGridDropdownChange(d, i)} placeholder='Reports To' value={editUser.reportsToId} text={reportsToName} />
                : this.userFromId(user.reportsToId)}
            </Table.Cell>
        </Table.Row>);
    }

    renderTableRows() {
        const companyUserClones = this.props.usersStore.companyUsers.map(u => getSnapshot(u));
        const { editIndex, editUser } = this.state;

        return (<Table.Body style={{ overflow: 'visible' }}>
            {companyUserClones.map((user, i) =>
                this.renderTableRow(user, i, editIndex, editUser)
            )}
        </Table.Body>);
    }

    render() {
        const { usersStore } = this.props;
        const { error } = this.state;
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="User Management" />
                <div style={componentContentStyle}>
                    {this.renderNewUserForm()}
                    <ErrorDisplay message={error} />
                    <Loading active={usersStore.companyUsers.some(u => u.isLoading)} >
                        <Table celled width={13} textAlign="center" striped style={{ overflow: 'visible' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1} />
                                    <Table.HeaderCell width={1}>
                                        Active
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        First Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Last Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={3}>
                                        Email
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1}>
                                        MFA Enabled
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Type
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                        Reports To
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {this.renderTableRows()}
                        </Table>
                    </Loading>
                </div>
            </div >);
    }
}

export default observer(UserManagement);