import { types, getParent } from 'mobx-state-tree';


export const Article = types.model('Article', {
    id: types.number,
    title: types.maybe(types.string),
    content: types.maybeNull(types.string)
}).views(self => ({
    get key() {
        return self.title.toLowerCase().replaceAll(' ', '-');
    },
    get path() {
        return `/${self.key}`;
    },
    get moduleName() {
        return getParent(self, 2).name;
    }
}));
