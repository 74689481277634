import { types } from 'mobx-state-tree';

export const OpportunityData = types.model('OpportunityData', {
    count: types.maybe(types.number),
    value: types.maybe(types.number)
});

export const NullOpportunityData = OpportunityData.create(
    {
        count: 0,
        value: 0
    }
);