import React, { Component } from "react";
import { observer } from 'mobx-react';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { componentContentStyle, componentContainerStyle } from '../constants';
import QuestionAnswer from "../components/QuestionAnswer/QuestionAnswer";

class QuestionAnswerPage extends Component {
    render() {
        const { company, mode } = this.props;
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="Question & Answer" />
                <div sytle={componentContentStyle}>
                    <QuestionAnswer company={company} mode={mode} />
                </div>
            </div>
        );

    }
}

export default observer(QuestionAnswerPage);