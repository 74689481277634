import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Form, Icon, Grid, Segment, Button, Header } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';
import DeleteModal from '../DeleteModal/DeleteModal';

class AddressableProblems extends Component {
  constructor(props) {
    super(props);

    const addressableProblemsSnapshot =
      props.company.addressableProblems.length > 0
        ? [...props.company.addressableProblems.map(d => RichTextEditor.createValueFromString(d, 'markdown'))]
        : [RichTextEditor.createValueFromString('', 'markdown')];
    this.state = {
      addressableProblemsSnapshot,
      error: '',
      openDeleteModal: false,
      deleteIndex: null
    };
  }

  onChange = (e, index) => {
    const { addressableProblemsSnapshot } = this.state;

    const newState = [...addressableProblemsSnapshot];

    newState[index] = e;

    this.setState({ addressableProblemsSnapshot: newState });
  };

  onSaveClick = async () => {
    const { addressableProblemsSnapshot } = this.state;
    const { company, saveCallback } = this.props;
    this.setState({ error: '' });

    const addressableProblems = addressableProblemsSnapshot.map(d => d.toString('markdown'));
    try {
      await company.updateAddressableProblemsFromSnapshot(
        addressableProblems.filter((s) => !!s)
      );

      if (saveCallback) {
        saveCallback();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onAddClick = () => {
    const { addressableProblemsSnapshot } = this.state;

    const newState = [...addressableProblemsSnapshot];

    newState.push(RichTextEditor.createValueFromString('', 'markdown'));

    this.setState({ addressableProblemsSnapshot: newState });
  };

  onRemoveClick = (index) => {
    this.setState({ openDeleteModal: true, deleteIndex: index });
  };

  onDeleteConfirm = () => {
    const { addressableProblemsSnapshot, deleteIndex } = this.state;

    let newSnapshot = [...addressableProblemsSnapshot];

    newSnapshot.splice(deleteIndex, 1);

    this.setState({ addressableProblemsSnapshot: newSnapshot, openDeleteModal: false });
  };

  closeModal = () =>{
    this.setState({ openDeleteModal: false });
  }

  formIsDirty() {
    const { addressableProblemsSnapshot } = this.state;
    const { company } = this.props;

    const filteredSnapshot = addressableProblemsSnapshot.filter((s) => !!s.toString('markdown'));
    const filteredModel = company.addressableProblems.filter((s) => !!s);

    if (filteredSnapshot.length !== filteredModel.length) {
      return true;
    }

    for (var i = 0; i < filteredSnapshot.length; i++) {
      if (filteredSnapshot[i].toString('markdown') !== filteredModel[i]) {
        return true;
      }
    }

    return false;
  }

  renderAddressableProblems() {
    const { addressableProblemsSnapshot } = this.state;
    const isReadonly = this.props.mode === 'readonly';
    const moreThanOne = addressableProblemsSnapshot.length > 1;
    return addressableProblemsSnapshot.map((d, i) => (
      <Segment key={i}>
        <Header as='h4' floated='left'>
          Addressable Problem # {i + 1}
        </Header>
        {!isReadonly && moreThanOne ? (
          <Button
            style={{ marginBottom: 0 }}
            data-testid={`remove-${i}`}
            size='mini'
            compact
            onClick={() => this.onRemoveClick(i)}
            icon
            basic
            negative
            floated='right'
          >
            <Icon name='remove' />
          </Button>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <RichTextEditor
                onChange={(e) => this.onChange(e, i)}
                readOnly={isReadonly}
                toolbarConfig={toolbarConfig}
                placeholder="Use the plus button below to add as many addressable problems as you can..."
                name={i}
                value={d}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Segment>
    ));
  }

  renderButtons() {
    const { mode } = this.props;

    if (mode === 'readonly') {
      return null;
    }

    const { addressableProblemsSnapshot } = this.state;
    const saveEnabled =
      addressableProblemsSnapshot.length > 1 || addressableProblemsSnapshot[0];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.Button
              primary
              onClick={this.onSaveClick}
              icon
              labelPosition='right'
              disabled={!saveEnabled}
            >
              Save
              <Icon name='save' />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  popupContent = <>
    <p>This is the core set of problems your company solves.  As you describe the differentiators of
      your company in conjunction with the weaknesses of the competitive landscape, the problem set
      begin to emerge.  Stating the problems your company solves allows the prospect to see how
      your offerings fit into the problem set you have positioned.</p>
  </>

  render() {
    const isReadonly = this.props.mode === 'readonly';
    return (
      <div>
        <MultiplySubheader text='What problems does your company address?' popupContent={this.popupContent} />
        <NavigationBlocker when={this.formIsDirty()} />
        {this.state.openDeleteModal && <DeleteModal onConfirm={this.onDeleteConfirm} onCancel={this.closeModal}/>}
        <ErrorDisplay message={this.state.error} />
        <Form loading={this.props.company.isLoading}>
          <Grid stackable columns={1}>
            <Grid.Row>
              <Grid.Column>{this.renderAddressableProblems()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='right'>
                {!isReadonly ? (
                  <Form.Button
                    primary
                    onClick={this.onAddClick}
                    icon
                    circular
                    basic
                    data-testid='add'
                  >
                    <Icon name='plus' />
                  </Form.Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>{this.renderButtons()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default observer(AddressableProblems);
