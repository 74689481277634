import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Form, Icon, Grid, Segment, Button, Header } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';
import DeleteModal from '../DeleteModal/DeleteModal';

class Differentiators extends Component {
  constructor(props) {
    super(props);

    const differentiatorsSnapshot =
      props.company.differentiators.length > 0
        ? [...props.company.differentiators.map(d => RichTextEditor.createValueFromString(d, 'markdown'))]
        : [RichTextEditor.createValueFromString('', 'markdown')];
    this.state = {
      differentiatorsSnapshot,
      error: '',
      openDeleteModal: false,
      deleteIndex: null
    };
  }

  onChange = (e, index) => {
    const { differentiatorsSnapshot } = this.state;

    const newState = [...differentiatorsSnapshot];

    newState[index] = e;

    this.setState({ differentiatorsSnapshot: newState });
  };

  onSaveClick = async () => {
    const { differentiatorsSnapshot } = this.state;
    const { company, saveCallback } = this.props;
    this.setState({ error: '' });

    const differentiators = differentiatorsSnapshot.map(d => d.toString('markdown'));
    try {
      await company.updateDifferentiatorsFromSnapshot(
        differentiators.filter((s) => !!s)
      );

      if (saveCallback) {
        saveCallback();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onAddClick = () => {
    const { differentiatorsSnapshot } = this.state;

    const newState = [...differentiatorsSnapshot];

    newState.push(RichTextEditor.createValueFromString('', 'markdown'));

    this.setState({ differentiatorsSnapshot: newState });
  };

  onRemoveClick = (index) => {
    this.setState({ openDeleteModal: true, deleteIndex: index });
  };

  onDeleteConfirm = () => {
    const { differentiatorsSnapshot, deleteIndex } = this.state;

    let newSnapshot = [...differentiatorsSnapshot];

    newSnapshot.splice(deleteIndex, 1);

    this.setState({ differentiatorsSnapshot: newSnapshot, openDeleteModal: false });
  }
  
  closeModal = () =>{
    this.setState({ openDeleteModal: false });
  }

  formIsDirty() {
    const { differentiatorsSnapshot } = this.state;
    const { company } = this.props;

    const filteredSnapshot = differentiatorsSnapshot.filter((s) => !!s.toString('markdown'));
    const filteredModel = company.differentiators.filter((s) => !!s);

    if (filteredSnapshot.length !== filteredModel.length) {
      return true;
    }

    for (var i = 0; i < filteredSnapshot.length; i++) {
      if (filteredSnapshot[i].toString('markdown') !== filteredModel[i]) {
        return true;
      }
    }

    return false;
  }

  renderDifferentiators() {
    const { differentiatorsSnapshot } = this.state;
    const isReadonly = this.props.mode === 'readonly';
    const moreThanOne = differentiatorsSnapshot.length > 1;
    return differentiatorsSnapshot.map((d, i) => (
      <Segment key={i}>
        <Header as='h4' floated='left'>
          Differentiator # {i + 1}
        </Header>
        {!isReadonly && moreThanOne ? (
          <Button
            style={{ marginBottom: 0 }}
            data-testid={`remove-${i}`}
            size='mini'
            compact
            onClick={() => this.onRemoveClick(i)}
            icon
            basic
            negative
            floated='right'
          >
            <Icon name='remove' />
          </Button>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <RichTextEditor
                onChange={(e) => this.onChange(e, i)}
                readOnly={isReadonly}
                toolbarConfig={toolbarConfig}
                data-testid='diff-entry'
                placeholder="Use the plus button below to add as many differentiators as you can..."
                name={i}
                value={d}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Segment>
    ));
  }

  renderButtons() {
    const { mode } = this.props;

    if (mode === 'readonly') {
      return null;
    }

    const { differentiatorsSnapshot } = this.state;
    const saveEnabled =
      differentiatorsSnapshot.length > 1 || differentiatorsSnapshot[0];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.Button
              primary
              onClick={this.onSaveClick}
              icon
              labelPosition='right'
              disabled={!saveEnabled}
            >
              Save
              <Icon name='save' />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  popupContent = <>
    <p>The differentiators are things that make your company different.  They may involve a unique quality, process, or alignment that your company possesses that allows you to say why you are different.  Many companies say things such as “We have the best people” or “We have the best widget on the market.”   The question is WHY do you have the best people or product.</p>
    <p>Every company probably has 3-5 of these types of differentiators.  A differentiator often has nothing to do with the product or service you provide but instead is an area of expertise your company possesses that allows you to perfect the offering.</p>
  </>

  render() {
    const isReadonly = this.props.mode === 'readonly';
    return (
      <div>
        <MultiplySubheader text='What differentiates your company?' popupContent={this.popupContent} />
        <NavigationBlocker when={this.formIsDirty()} />
        {this.state.openDeleteModal && <DeleteModal onConfirm={this.onDeleteConfirm} onCancel={this.closeModal}/>}
        <ErrorDisplay message={this.state.error} />
        <Form loading={this.props.company.isLoading}>
          <Grid stackable columns={1}>
            <Grid.Row>
              <Grid.Column>{this.renderDifferentiators()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='right'>
                {!isReadonly ? (
                  <Form.Button
                    primary
                    onClick={this.onAddClick}
                    icon
                    circular
                    basic
                    data-testid='add'
                  >
                    <Icon name='plus' />
                  </Form.Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>{this.renderButtons()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default observer(Differentiators);
