import React from 'react';
import { observer } from 'mobx-react';
import OnboardingPage from './OnboardingPage';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';

const SignupWizard = ({ companyStore, usersStore }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const goHome = () => {
    history.push('/');
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      <Route path={`${match.path}`} exact></Route>
      <Route path={`${match.path}/onboarding`}>
        <OnboardingPage
          company={companyStore.company}
          step={1}
          currentUser={usersStore.currentUser}
          saveCallback={goHome}
        />
      </Route>
    </div>
  );
};

export default observer(SignupWizard);
