import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Form, Icon, Grid, Segment, Button, Header } from 'semantic-ui-react';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';
import DeleteModal from '../DeleteModal/DeleteModal';

class GeneralAsks extends Component {
  constructor(props) {
    super(props);

    const generalAsksSnapshot = props.company.generalAsks.length > 0
      ? [...props.company.generalAsks.map(d => RichTextEditor.createValueFromString(d, 'markdown'))]
      : [RichTextEditor.createValueFromString('', 'markdown')];
    this.state = {
      generalAsksSnapshot: generalAsksSnapshot,
      error: '',
      openDeleteModal: false,
      deleteIndex: null
    };
  }

  onChange = (e, index) => {
    const { generalAsksSnapshot } = this.state;

    const newState = [...generalAsksSnapshot];

    newState[index] = e;

    this.setState({ generalAsksSnapshot: newState });
  };

  onSaveClick = async () => {
    const { generalAsksSnapshot } = this.state;
    const { company, saveCallback } = this.props;
    this.setState({ error: '' });

    const generalAsks = generalAsksSnapshot.map(d => d.toString('markdown'));
    try {
      await company.updateGeneralAsksFromSnapshot(
        generalAsks.filter((s) => !!s)
      );

      if (saveCallback) {
        saveCallback();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onAddClick = () => {
    const { generalAsksSnapshot } = this.state;

    const newState = [...generalAsksSnapshot];

    newState.push(RichTextEditor.createValueFromString('', 'markdown'));

    this.setState({ generalAsksSnapshot: newState });
  };

  onRemoveClick = (index) => {
    this.setState({ openDeleteModal: true, deleteIndex: index });
  };

  onDeleteConfirm = () => {
    const { generalAsksSnapshot, deleteIndex } = this.state;

    let newSnapshot = [...generalAsksSnapshot];

    newSnapshot.splice(deleteIndex, 1);

    this.setState({ generalAsksSnapshot: newSnapshot, openDeleteModal: false });
  };

  closeModal = () =>{
    this.setState({ openDeleteModal: false });
  }

  formIsDirty() {
    const { generalAsksSnapshot } = this.state;
    const { company } = this.props;

    const filteredSnapshot = generalAsksSnapshot.filter((s) => !!s.toString('markdown'));
    const filteredModel = company.generalAsks.filter((s) => !!s);

    if (filteredSnapshot.length !== filteredModel.length) {
      return true;
    }

    for (var i = 0; i < filteredSnapshot.length; i++) {
      if (filteredSnapshot[i].toString('markdown') !== filteredModel[i]) {
        return true;
      }
    }

    return false;
  }

  renderGeneralAsks() {
    const { generalAsksSnapshot } = this.state;
    const isReadonly = this.props.mode === 'readonly';
    const moreThanOne = generalAsksSnapshot.length > 1;
    return generalAsksSnapshot.map((d, i) => (
      <Segment key={i}>
        <Header as='h4' floated='left'>
          Ask # {i + 1}
        </Header>
        {!isReadonly && moreThanOne ? (
          <Button
            style={{ marginBottom: 0 }}
            data-testid={`remove-${i}`}
            size='mini'
            compact
            onClick={() => this.onRemoveClick(i)}
            icon
            basic
            negative
            floated='right'
          >
            <Icon name='remove' />
          </Button>
        ) : null}
        <Grid.Row>
          <Grid.Column>
            <Form.Field>
              <RichTextEditor
                onChange={(e) => this.onChange(e, i)}
                readOnly={isReadonly}
                toolbarConfig={toolbarConfig}
                placeholder="Use the plus button below to add as many general asks as you can..."
                name={i}
                value={d}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Segment>
    ));
  }

  renderButtons() {
    const { mode } = this.props;

    if (mode === 'readonly') {
      return null;
    }

    const { generalAsksSnapshot } = this.state;
    const saveEnabled =
      generalAsksSnapshot.length > 1 || generalAsksSnapshot[0];

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.Button
              primary
              onClick={this.onSaveClick}
              icon
              labelPosition='right'
              disabled={!saveEnabled}
            >
              Save
              <Icon name='save' />
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  popupContent = <>
    <p>Once you have talked about the company, the differentiators, the competitive landscape, and
      the problem set that the company can solve, the salesperson will be able to generate “asks.”
      These are questions that you can use to get a prospect thinking or conversing about the
      problems you expect them to have.  The prospect may not have even realized they have these
      problems prior to their conversation with you.  You will need to define The General asks are and
      a good start is a very basic question like:  “does your company experience &ltthe problem&gt that
      we solve”?  Where &ltthe problem&gt represents the problem your company has the solution(s) for.</p>
    <p>
      The “asks” will be specific to each encounter as further explained in our Framework
      Documentation.
    </p>
  </>

  render() {
    const isReadonly = this.props.mode === 'readonly';
    return (
      <div>
        <MultiplySubheader text='What are you asking?' popupContent={this.popupContent} />
        <NavigationBlocker when={this.formIsDirty()} />
        {this.state.openDeleteModal && <DeleteModal onConfirm={this.onDeleteConfirm} onCancel={this.closeModal}/>}
        <ErrorDisplay message={this.state.error} />
        <Form loading={this.props.company.isLoading}>
          <Grid stackable columns={1}>
            <Grid.Row>
              <Grid.Column>{this.renderGeneralAsks()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='right'>
                {!isReadonly ? (
                  <Form.Button
                    primary
                    onClick={this.onAddClick}
                    icon
                    circular
                    basic
                    data-testid='add'
                  >
                    <Icon name='plus' />
                  </Form.Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>{this.renderButtons()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default observer(GeneralAsks);
