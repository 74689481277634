import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'semantic-ui-react';

class CompaniesGrid extends Component {

    renderTableRows(adminCompanies) {
        return adminCompanies.map((adminCompany, index) => <Table.Row key={`company-${index}`}>
            <Table.Cell>
                {adminCompany.company.name}
            </Table.Cell>
            <Table.Cell>
                {adminCompany.companyExecutive?.email}
            </Table.Cell>
            <Table.Cell>
                {adminCompany.totalUsersCount}
            </Table.Cell>
            <Table.Cell>
                {adminCompany.activeUsersCount}
            </Table.Cell>
        </Table.Row>);
    }

    render() {
        const { adminCompanies } = this.props;

        return (
            <Table striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Company Name
                    </Table.HeaderCell>
                        <Table.HeaderCell>
                            Company Executive Email
                    </Table.HeaderCell>
                        <Table.HeaderCell>
                            Total Users
                    </Table.HeaderCell>
                        <Table.HeaderCell>
                            Active Users
                    </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.renderTableRows(adminCompanies)}
                </Table.Body>
            </Table>
        );
    }
}

export default observer(CompaniesGrid);