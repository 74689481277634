import { types, flow, applySnapshot, destroy } from 'mobx-state-tree';
import axios from 'axios';

export const ApiStore = types.model('ApiStore', {
    isLoading: types.optional(types.boolean, false)
}).actions(self => ({
    getApi: flow(function* getApi(url, applyTo) {
        yield processApiCall(() => axios.get(url), self, applyTo);
    }),
    patchApi: flow(function* patchApi(url, patch, applyTo) {
        yield processApiCall(() => axios.patch(url, patch), self, applyTo);
    }),
    postApi: flow(function* postApi(url, body, applyTo) {
        yield processApiCall(() => axios.post(url, body), self, applyTo);
    }),
    putApi: flow(function* putApi(url, body, applyTo) {
        yield processApiCall(() => axios.put(url, body), self, applyTo);
    }),
    deleteApi: flow(function* deleteApi(url, applyTo) {
        self.isLoading = true;
        try {
            yield axios.delete(url);
            destroy(applyTo);
        } catch (error) {
            handleError(error);
        }
        finally {
            self.isLoading = false;
        }
    })
}));

const handleError = error => {
    if (error.response) {
        const e = new Error(error.response.data.error);
        e.response = error.response;
        throw e;
    }
    throw error;
};

const processApiCall = flow(function* processApiCall(apiCall, self, applyTo) {
    self.isLoading = true;
    try {
        const response = yield apiCall();

        if (applyTo) {
            applySnapshot(applyTo, response.data);
        }
    } catch (error) {
        handleError(error);
    } finally {
        self.isLoading = false;
    }
});