import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CompanyOverview from '../components/CompanyOverview/CompanyOverview';
import Differentiators from '../components/CompanyOverview/Differentiators';

class DifferentiatorsPage extends Component {
    render() {
        const {company, saveCallback, mode, step} = this.props;
        return (
            <CompanyOverview step={step}>
                <Differentiators company={company} mode={mode} saveCallback={saveCallback}/>
            </CompanyOverview>
        );
    }
}

export default observer(DifferentiatorsPage);
