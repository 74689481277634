import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CompanyOverview from '../components/CompanyOverview/CompanyOverview';
import AddressableProblems from '../components/CompanyOverview/AddressableProblems';

class AddressableProblemsPage extends Component {
    render() {
        const { company, saveCallback, mode, step } = this.props;
        return (
            <CompanyOverview step={step}>
                <AddressableProblems company={company} mode={mode} saveCallback={saveCallback} />
            </CompanyOverview>
        );
    }
}

export default observer(AddressableProblemsPage);
