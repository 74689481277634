import React, { Component } from 'react';
import { Popup, Icon } from 'semantic-ui-react';

export default class InfoPopup extends Component {
    render() {
        const { popupContent } = this.props;

        if (!popupContent) {
            return null;
        }
        return <Popup
            content={popupContent}
            wide="very"
            trigger={<Icon color="blue" style={{ display: 'inline-block', fontSize: '1em', marginLeft: 5 }} name="info circle" />}
        />
    }
}
