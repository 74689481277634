import React, { Component } from 'react';
import { observer } from 'mobx-react';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';
import { Grid, Form, Icon, Segment } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import MultiplyUpload from '../MultiplyUpload/MultiplyUpload';
import { shallowEqual } from '../../ShallowEquality';
import NavigationBlocker from '../NavigationBlocker/NavigationBlocker';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';

class CompanyBackground extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyBackgroundSnapshot: {
        ...getSnapshot(props.companyBackground),
        uploadFile: undefined,
      },
      history: RichTextEditor.createValueFromString(props.companyBackground.history || '', 'markdown'),
      mission: RichTextEditor.createValueFromString(props.companyBackground.mission || '', 'markdown'),
      error: '',
    };
  }

  companyTypeOptions = [
    {
      key: 'Private',
      text: 'Private',
      value: false,
    },
    {
      key: 'Public',
      text: 'Public',
      value: true,
    },
  ];

  isInteger(value) {
    return /^\d+$/.test(value);
  }

  onUploadChange = (file) => {
    if (!file) {
      const tempBackground = this.state.companyBackgroundSnapshot;
      tempBackground.uploadKey = '';

      this.setState({ backgroundSnapshot: tempBackground });
    } else {
      const tempBackground = this.state.companyBackgroundSnapshot;
      tempBackground.uploadFile = file;

      this.setState({
        backgroundSnapshot: tempBackground,
        error: '',
      });
    }
  };

  onTextChange = (e) => {
    const { companyBackgroundSnapshot } = this.state;

    const value = this.isInteger(e.target.value)
      ? +e.target.value
      : e.target.value;
    const newState = { ...companyBackgroundSnapshot, [e.target.name]: value };

    this.setState({ companyBackgroundSnapshot: newState });
  };

  onHistoryChange = (e) => {
    this.setState({ history: e });
  };

  onMissionChange = (e) => {
    this.setState({ mission: e });
  };

  onPublicPrivateChange = (data) => {
    const backgroundSnapshot = { ...this.state.companyBackgroundSnapshot };
    backgroundSnapshot.isPublic = data.value;
    this.setState({ companyBackgroundSnapshot: backgroundSnapshot });
  };

  onSaveClick = async () => {
    try {
      this.setState({ error: '' });

      const backgroundSnapshot = {
        ...this.state.companyBackgroundSnapshot,
        mission: this.state.mission.toString('markdown'),
        history: this.state.history.toString('markdown')
      };

      delete backgroundSnapshot.isLoading;
      const { companyBackground } = this.props;

      await companyBackground.updateFromSnapshot(backgroundSnapshot);

      const { saveCallback } = this.props;

      this.setState({
        error: '',
        companyBackgroundSnapshot: backgroundSnapshot,
      });

      if (saveCallback) {
        saveCallback();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  onRemoveClick = () => {
    const tempBackground = this.state.companyBackgroundSnapshot;
    tempBackground.uploadKey = '';
    tempBackground.uploadLink = '';

    this.setState({
      backgroundSnapshot: tempBackground,
      error: '',
    });
  };

  saveButtonEnabled = () => {
    const { companyBackgroundSnapshot } = this.state;
    return (
      companyBackgroundSnapshot.ceo &&
      companyBackgroundSnapshot.totalRevenue !== undefined &&
      companyBackgroundSnapshot.yearFounded &&
      companyBackgroundSnapshot.numberOfEmployees &&
      companyBackgroundSnapshot.numberOfOffices &&
      companyBackgroundSnapshot.mission
    );
  };

  renderButtons() {
    const { mode } = this.props;

    if (mode === 'readonly') {
      return null;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Form.Button
              primary
              icon
              labelPosition="right"
              onClick={this.onSaveClick}
            >
              <Icon name="save" />
              Save
            </Form.Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  popupContent = <>
    <p>The company background is information that you want your sales team to know about your company.This includes basic facts such as headquarters location, CEO, revenue, etc., as well as some general information about the history of the company that will help build corporate credibility.This information should not be 10 pages, but it also should not be 3 sentences.There are three or four paragraphs that a salesperson needs to know to describe the company.</p>
    <p>For smaller companies, the goal is to present bigger and perhaps more stable than what you are to coincide with their nimbleness.Larger companies often try to do the exact opposite by attempting to present smaller and more nimble than what they are to coincide with their scale and stability.</p >
  </>;


  formIsDirty() {
    const { companyBackgroundSnapshot } = this.state;
    const { companyBackground } = this.props;

    const backgroundSnapshot = {
      ...companyBackgroundSnapshot,
      mission: this.state.mission.toString('markdown'),
      history: this.state.history.toString('markdown')
    };

    return !shallowEqual(backgroundSnapshot, getSnapshot(companyBackground));
  }

  render() {
    const { companyBackground, mode } = this.props;
    const { companyBackgroundSnapshot, error, history, mission } = this.state;
    const isReadonly = mode === 'readonly';
    return (
      <div>
        <NavigationBlocker when={this.formIsDirty()}></NavigationBlocker>
        <MultiplySubheader text="Company Background" popupContent={this.popupContent} />
        <ErrorDisplay message={error} />
        <Form loading={companyBackground.isLoading}>
          <Segment>
            <Grid>
              {isReadonly ? (
                <Grid.Row columns={1}>
                  <Grid.Column textAlign="left">
                    <MultiplyUpload
                      mode="readonly"
                      uploadKey={companyBackgroundSnapshot.uploadKey ?? ''}
                      uploadLink={companyBackgroundSnapshot.uploadLink ?? ''}
                      onChange={(file) => this.onUploadChange(file)}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="left">
                    <MultiplyUpload
                      mode={'notreadonly'}
                      uploadKey={companyBackgroundSnapshot.uploadKey ?? ''}
                      uploadLink={companyBackgroundSnapshot.uploadLink ?? ''}
                      onChange={(file) => this.onUploadChange(file)}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Input
                    label="CEO"
                    placeholder="CEO"
                    value={companyBackgroundSnapshot.ceo}
                    name="ceo"
                    onChange={this.onTextChange}
                    fluid
                    readOnly={isReadonly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <CurrencyInput
                    label="Total Revenue"
                    placeholder="Total Revenue"
                    value={companyBackgroundSnapshot.totalRevenue}
                    name="totalRevenue"
                    onChange={this.onTextChange}
                    readOnly={isReadonly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <Form.Input
                    label="Year Founded"
                    placeholder="Year Founded"
                    value={companyBackgroundSnapshot.yearFounded || ''}
                    name="yearFounded"
                    onChange={this.onTextChange}
                    fluid
                    type="number"
                    min="0"
                    readOnly={isReadonly}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label="Number of Employees"
                    placeholder="Number of Employees"
                    value={companyBackgroundSnapshot.numberOfEmployees || ''}
                    name="numberOfEmployees"
                    onChange={this.onTextChange}
                    fluid
                    type="number"
                    min="0"
                    readOnly={isReadonly}
                  />
                </Grid.Column>
                <Grid.Column>
                  {!isReadonly ? (
                    <Form.Dropdown
                      label="Public/Private"
                      placeholder="Public/Private"
                      options={this.companyTypeOptions}
                      selection
                      onChange={(e, d) => this.onPublicPrivateChange(d)}
                      fluid
                      value={companyBackgroundSnapshot.isPublic}
                      readOnly={isReadonly}
                      search
                    />
                  ) : (
                    <Form.Input
                      label="Public/Private"
                      placeholder="Public/Private"
                      value={
                        companyBackgroundSnapshot.isPublic
                          ? 'Public'
                          : 'Private'
                      }
                      readOnly
                    />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    label="Number of Office Locations"
                    placeholder="Number of Office Locations"
                    value={companyBackgroundSnapshot.numberOfOffices || ''}
                    name="numberOfOffices"
                    onChange={this.onTextChange}
                    fluid
                    type="number"
                    min="0"
                    readOnly={isReadonly}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>Company History</label>
                    <RichTextEditor
                      id="company-history"
                      value={history}
                      onChange={this.onHistoryChange}
                      readOnly={isReadonly}
                      toolbarConfig={toolbarConfig}
                      name="history"
                      placeholder="A few sentences describing why your company was founded, a brief profile of the founders, and major events in your company's life..."
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>Company Mission</label>
                    <RichTextEditor
                      id="company-mission"
                      value={mission}
                      onChange={this.onMissionChange}
                      readOnly={isReadonly}
                      toolbarConfig={toolbarConfig}
                      name="mission"
                      placeholder="Your company's function, why it exists, or reason for being..."
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width={1}>
                <Grid.Column>{this.renderButtons()}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      </div>
    );
  }
}

export default observer(CompanyBackground);
