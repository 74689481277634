import axios from 'axios';
import fileDowload from 'js-file-download';

export const FileDownloader = async (key) => {
    const parts = key.split('/');

    const resultUrl = await axios.get(`/companies/${parts[0]}/uploads/${parts[1]}`);

    const resultFile = await axios.get(resultUrl.data.downloadUrl, { baseURL: '', responseType: 'blob' });

    fileDowload(resultFile.data, parts[1]);
};