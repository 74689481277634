import React, { Component } from 'react';
import AssessmentGrader from './AssessmentGrader';
import { observer } from 'mobx-react';
import { Grid, Form } from 'semantic-ui-react';
import RichTextEditor from 'react-rte';
import { toolbarConfig } from '../RichTextEditorConfig';

class OfferingAssessmentCompleter extends Component {
    constructor(props) {
        super();
        this.state = {
            areasForImprovement: RichTextEditor.createValueFromString(props?.assessment?.offeringAreasForImprovement || '', 'markdown'),
            positives: RichTextEditor.createValueFromString(props?.assessment?.offeringPositives || '', 'markdown'),
        };
    }

    onGradeChange = (grade) => {
        const { assessment } = this.props;
        assessment.updateFromEvent({ target: { id: 'grade', value: grade } });
    };

    onAreasForImprovementChange = (event) => {
        const { assessment } = this.props;

        this.setState({ areasForImprovement: event });
        assessment.updateFromEvent({ target: { id: 'offeringAreasForImprovement', value: event.toString('markdown') } });
    };

    onPositivesChange = (event) => {
        const { assessment } = this.props;

        this.setState({ positives: event });
        assessment.updateFromEvent({ target: { id: 'offeringPositives', value: event.toString('markdown') } });
    };

    onSessionTypeChange = data => {
        const { assessment } = this.props;
        assessment.setSessionType(data.value);
    };

    render() {
        const { assessment } = this.props;
        const reviewDate = new Date(assessment.reviewDateTime);
        const todaysDate = new Date();

        return (<Grid>
            <Grid.Row>
                <Grid.Column width="14">
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Form.Input
                                    label="Scheduled Review Date"
                                    id="dateAssigned"
                                    readOnly
                                    value={reviewDate.toLocaleDateString()}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Input
                                    label="Actual Review Date"
                                    id="reviewDate"
                                    readOnly
                                    value={todaysDate.toLocaleDateString()} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Input
                                    id="sessionType"
                                    label="Session Type"
                                    readOnly
                                    value={assessment.sessionType || ''} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Positives</label>
                                    <RichTextEditor
                                        value={this.state.positives}
                                        onChange={this.onPositivesChange}
                                        id="offeringPositives"
                                        toolbarConfig={toolbarConfig}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Areas for Improvement</label>
                                    <RichTextEditor
                                        value={this.state.areasForImprovement}
                                        onChange={this.onAreasForImprovementChange}
                                        id="offeringAreasForImprovement"
                                        toolbarConfig={toolbarConfig}
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column textAlign="center" width="2">
                    <AssessmentGrader onGradeChange={this.onGradeChange} value={assessment.grade} />
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }
}

export default observer(OfferingAssessmentCompleter);