import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Grid, Button, Icon, Form } from 'semantic-ui-react';
import CurrencyInput from '../CurrencyInput/CurrencyInput';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import MultiplySubheader from '../MultiplySubheader/MultiplySubheader';

class SalesPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    topRadius = { borderTopLeftRadius: 3, borderTopRightRadius: 3 }
    bottomRadius = { borderBottomLeftRadius: 3, borderBottomRightRadius: 3 }
    noBottomBorder = { border: '1px solid  #0784a7', borderBottom: 'none' }
    fullBorder = { border: '1px solid #0784a7' }

    metricContainerStyle = { margin: '30px 0', display: 'inline-block' };

    onTextChange = (e) => {
        const { salesPerformance } = this.props;

        salesPerformance.updateFromEvent(e);
    }

    onSaveClick = async () => {
        const { salesPerformance, user } = this.props;
        this.setState({ error: '' });

        try {
            await salesPerformance.save(user);
        } catch (error) {
            this.setState({ error: error.message });
        }
    }

    multiplyMetricStyle() {
        const { salesPerformance } = this.props;
        const metricStyle = { ...this.fullBorder, marginTop: 10, padding: 10, borderRadius: 3, minHeight: 41 };

        let color;
        if (salesPerformance.multiplyMetricNumber > 0) {
            color = 'limegreen';
        } else {
            color = 'red';
        }

        return { ...metricStyle, color };
    }

    getRoiStyle() {
        const { salesPerformance } = this.props;

        let color;

        if (salesPerformance.salesRoiNumber > 0) {
            color = 'limegreen';
        } else {
            color = 'red';
        }

        return { color };
    }

    render() {
        const { salesPerformance, readOnly } = this.props;
        const { error } = this.state;

        return (
            <Form loading={salesPerformance.isLoading} style={{ marginTop: 10 }}>
                <MultiplySubheader text="Sales Performance" />
                <Grid columns={4} verticalAlign="middle" style={{ margin: 0 }}>
                    <Grid.Row style={{ ...this.noBottomBorder, ...this.topRadius }}>
                        <Grid.Column>
                            <Form.Input
                                label="Number of Opportunities"
                                id="totalOpportunities.count"
                                onChange={this.onTextChange}
                                fluid
                                value={salesPerformance.totalOpportunities.count || 0}
                                readOnly={readOnly} />
                        </Grid.Column>
                        <Grid.Column>
                            <CurrencyInput
                                label="Value of Pipeline"
                                id="totalOpportunities.value"
                                onChange={this.onTextChange}
                                fluid
                                value={salesPerformance.totalOpportunities.value}
                                readOnly={readOnly} />
                        </Grid.Column>
                        <Grid.Column>
                            <CurrencyInput
                                label="Compensation"
                                fluid
                                id="expense.salary"
                                onChange={this.onTextChange}
                                value={salesPerformance.expense.salary}
                                readOnly={readOnly} />
                        </Grid.Column>
                        <Grid.Column>
                            <CurrencyInput
                                label="Expenses"
                                fluid
                                id="expense.expenses"
                                onChange={this.onTextChange}
                                value={salesPerformance.expense.expenses}
                                readOnly={readOnly} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={this.noBottomBorder}>
                        <Grid.Column>
                            <Form.Input
                                label="Number of Won Opportunities"
                                fluid
                                id="wonOpportunities.count"
                                onChange={this.onTextChange}
                                value={salesPerformance.wonOpportunities.count || 0}
                                readOnly={readOnly} />
                        </Grid.Column>
                        <Grid.Column>
                            <CurrencyInput
                                label="Value of Won Opportunities"
                                fluid
                                id="wonOpportunities.value"
                                onChange={this.onTextChange}
                                value={salesPerformance.wonOpportunities.value}
                                readOnly={readOnly} />
                        </Grid.Column>
                        <Grid.Column>
                            <CurrencyInput
                                label="Commissions"
                                fluid
                                id="expense.commissions"
                                onChange={this.onTextChange}
                                value={salesPerformance.expense.commissions}
                                readOnly={readOnly} />
                        </Grid.Column>
                        <Grid.Column>
                            <CurrencyInput
                                label="Billed Expenses"
                                fluid
                                id="expense.attributedExpenses"
                                onChange={this.onTextChange}
                                value={salesPerformance.expense.attributedExpenses}
                                readOnly={readOnly} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ ...this.fullBorder, ...this.bottomRadius }}>
                        <Grid.Column style={this.getRoiStyle()}>
                            <Form.Field>
                                <label>Sales ROI</label>
                                {salesPerformance.salesRoi}
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column />
                        <Grid.Column>
                            <Form.Field>
                                <label>Total Earnings</label>
                                {salesPerformance.expense.totalEarnings}
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Expenses Paid</label>
                                {salesPerformance.expense.expensesPaid}
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div style={this.metricContainerStyle}>
                    Multiply Metric
                    <div style={this.multiplyMetricStyle()}>
                        {salesPerformance.multiplyMetric}
                    </div>
                </div>
                <ErrorDisplay message={error} />
                {!readOnly ? <div>
                    <Button onClick={this.onSaveClick} primary icon labelPosition="right">Save<Icon name="save" /></Button>
                </div> : null}
            </Form>);
    }
}

export default observer(SalesPerformance);