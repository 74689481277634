import React, { Component } from 'react';
import { observer } from 'mobx-react';
import CompanyExecSignup from '../components/CompanyExecSignup/CompanyExecSignup';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';

class OnboardingPage extends Component {
  render() {
    const {
      currentUser,
      currentUserAttributes,
      company,
      saveCallback,
    } = this.props;

    return (
      <>
        <MultiplyHeader text='Onboarding'></MultiplyHeader>
        <CompanyExecSignup
          currentUser={currentUser}
          currentUserAttributes={currentUserAttributes}
          company={company}
          saveCallback={saveCallback}
        />
      </>
    );
  }
}

export default observer(OnboardingPage);
