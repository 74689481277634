import React, { useEffect, useState } from 'react';
import { Header, Form, Grid } from 'semantic-ui-react';

const PreviousAssessmentList = (props) => {
    const { assessmentHistory, onAssessmentButtonClick } = props;
    const [assessmentTypeDropDownOptions, setAssessmentTypeDropDownOptions] = useState([]);
    const [selectedAssessmentType, setSelectedAssessmentType] = useState('');
    const [assessmentsByType, setAssessmentsByType] = useState({});
    const [assessmentOptions, setAssessmentOptions] = useState([]);
    const [selectedAssessmentIndex, setSelectedAssessmentIndex] = useState(-1);

    const { assessments } = assessmentHistory;

    useEffect(() => {
        const byType = assessments.reduce((previous, current, i) => {
            if (!previous[current.sessionType]) {
                previous[current.sessionType] = [];
            }

            previous[current.sessionType].push({
                key: `assessment-type-option-${i}`,
                text: new Date(current.completedDateTime).toLocaleDateString(),
                value: previous[current.sessionType].length,
                assessment: current,
                label: { color: current.colorForGrade, empty: true, circular: true }
            });

            return previous;
        }, {});

        const keysOptions = Object.keys(byType).map((key, index) => ({
            key: `assessment-type-option-${index}`,
            text: key,
            value: key,
        }));

        setAssessmentsByType(byType);
        setAssessmentTypeDropDownOptions(keysOptions);
        setSelectedAssessmentType(keysOptions[0].value);
        setAssessmentOptions(byType[keysOptions[0].value]);
        setSelectedAssessmentIndex(0);
    }, [assessments]);

    if (!assessments || assessments.length === 0) {
        return null;
    }

    const assessmentTypeChange = (_, data) => {
        setAssessmentOptions(assessmentsByType[data.value]);
        setSelectedAssessmentIndex(-1);
        setSelectedAssessmentType(data.value);
    };

    const assessmentChange = (_, data) => {
        setSelectedAssessmentIndex(data.value);
        onAssessmentButtonClick(assessmentOptions[data.value].assessment);
    };

    return <>
        <Header>
            Previous Feedback
        </Header>
        <Grid as={Form}>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Form.Dropdown
                        label='Assessment Type'
                        placeholder='Select Assessment Type'
                        selection
                        fluid
                        onChange={assessmentTypeChange}
                        options={assessmentTypeDropDownOptions}
                        value={selectedAssessmentType}
                        search
                    />
                </Grid.Column>
                <Grid.Column>
                    <Form.Dropdown
                        label='Assessment'
                        placeholder='Select Assessment'
                        selection
                        fluid
                        onChange={assessmentChange}
                        options={assessmentOptions}
                        value={selectedAssessmentIndex}
                        search
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>


    </>;
};

export default PreviousAssessmentList;