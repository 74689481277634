import { types, clone, flow, getParent, getSnapshot } from 'mobx-state-tree';
import { User, NullUser } from './User';

export const Assessment = types.model('Assessment', {
    id: types.string,
    aiGenerated: types.optional(types.boolean, false),
    companyId: types.string,
    salesperson: User,
    sessionType: types.optional(types.enumeration(['Phone call', 'Phone Call', 'Casual Encounter', 'Presentation', 'Online Video Conference', 'Sales Presentation']), 'Phone Call'),
    subject: types.enumeration(['Company Overview', 'Company Overview (for MVP/Rollout)', 'Offerings']),
    secondarySubject: types.maybe(types.string),
    reviewDateTime: types.maybe(types.string),
    completedDateTime: types.maybe(types.string),
    additionalAttendees: types.maybe(types.string),
    offeringPositives: types.maybe(types.string),
    offeringAreasForImprovement: types.maybe(types.string),
    companyBackgroundPositives: types.maybe(types.string),
    companyBackgroundAreasForImprovement: types.maybe(types.string),
    differentiatorsPositives: types.maybe(types.string),
    differentiatorsAreasForImprovement: types.maybe(types.string),
    prospectProfilePositives: types.maybe(types.string),
    prospectProfileAreasForImprovement: types.maybe(types.string),
    topCompetitorsPositives: types.maybe(types.string),
    topCompetitorsAreasForImprovement: types.maybe(types.string),
    companyOverviewPositives: types.maybe(types.string),
    companyOverviewAreasForImprovement: types.maybe(types.string),
    grade: types.optional(types.enumeration(['red', 'yellow', 'green', 'notGraded']), 'notGraded'),
    reviewer: types.maybe(User),
    isDirty: types.optional(types.boolean, false)
})
    .views(self => ({
        get colorForGrade() {

            if (self.grade === 'notGraded') {
                return 'gray';
            }

            return self.grade;
        },
        get assessmentCanBeSaved() {
            if (self.subject === 'Company Overview') {
                return !!self.companyOverviewPositives &&
                    !!self.companyOverviewAreasForImprovement &&
                    self.grade !== 'notGraded';
            } else {
                return !!self.offeringAreasForImprovement &&
                    !!self.offeringPositives &&
                    self.grade !== 'notGraded';
            }
        },
        get companyOverviewPositivesView() {
            if (!!self.companyOverviewPositives) {
                return self.companyOverviewPositives;
            }

            const positives = [];
            if (!!self.companyBackgroundPositives) {
                positives.push(`${self.companyBackgroundPositives}\n\n`);
            }

            if (!!self.differentiatorsPositives) {
                positives.push(`${self.differentiatorsPositives}\n\n`);
            }

            if (!!self.prospectProfilePositives) {
                positives.push(`${self.prospectProfilePositives}\n\n`);
            }

            if (!!self.topCompetitorsPositives) {
                positives.push(`${self.topCompetitorsPositives}\n\n`);
            }

            return positives.join('');
        },
        get companyOverviewAreasForImprovementView() {
            if (!!self.companyOverviewAreasForImprovement) {
                return self.companyOverviewAreasForImprovement;
            }

            const negatives = [];
            if (!!self.companyBackgroundAreasForImprovement) {
                negatives.push(`${self.companyBackgroundAreasForImprovement}\n\n`);
            }

            if (!!self.differentiatorsAreasForImprovement) {
                negatives.push(`${self.differentiatorsAreasForImprovement}\n\n`);
            }

            if (!!self.prospectProfileAreasForImprovement) {
                negatives.push(`${self.prospectProfileAreasForImprovement}\n\n`);
            }

            if (!!self.topCompetitorsAreasForImprovement) {
                negatives.push(`${self.topCompetitorsAreasForImprovement}\n\n`);
            }

            return negatives.join('');
        }
    }))
    .actions(self => {
        const updateFromEvent = (event) => {
            self[event.target.id] = event.target.value;
            self.isDirty = true;
        };
        const save = flow(function* save() {
            const snapshot = { ...getSnapshot(self) };
            delete snapshot.isDirty;
            self.isDirty = false;
            yield getParent(self, 2).updateAssessmentFromSnapshot(snapshot);
        });
        const setSessionType = (sessionType) => {
            self.sessionType = sessionType;
        };

        return {
            updateFromEvent,
            setSessionType,
            save
        };

    });

export const NullAssessment = Assessment.create({
    id: '',
    companyId: '',
    salesperson: clone(NullUser),
    sessionType: 'Phone Call',
    subject: 'Company Overview',
    reviewDateTime: '',
    completedDateTime: '',
    additionalAttendees: ''
});