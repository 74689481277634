import React, { Component } from 'react';
import User from '../components/User/User';
import MultiplyHeader from '../components/MultiplyHeader/MultiplyHeader';
import { componentContainerStyle, componentContentStyle } from '../constants';
import { observer } from 'mobx-react';
import UpdateCreditCard from '../components/UpdateCreditCard/UpdateCreditCard';
import { Divider, Header } from 'semantic-ui-react';

export class UserProfile extends Component {
    render() {
        const { companyStore } = this.props;
        return (
            <div style={componentContainerStyle}>
                <MultiplyHeader text="User Profile" />
                <div style={componentContentStyle}>
                    <User {...this.props} />
                    <Divider />
                    {this.props.currentUser.canChangeCreditCard &&
                        <>
                            <Header>Update Credit Card</Header>

                            <UpdateCreditCard companyStore={companyStore} />
                        </>
                    }
                </div>
            </div>
        );
    }
}

export default observer(UserProfile);