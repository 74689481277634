import React, { Component } from 'react';
import { Form, Grid, Segment, Icon, Header, Checkbox, GridRow } from 'semantic-ui-react';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import MaskedInput from 'react-text-mask';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import { createSubscription } from '../../Stripe';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';
import { withUserContext } from '../../UserContextProvider';

class CompanyExecSignup extends Component {
  constructor(props) {
    super(props);

    const companySnapshot = { ...getSnapshot(props.company) };
    delete companySnapshot.background;
    delete companySnapshot.isLoading;
    delete companySnapshot.id;
    this.state = { companySnapshot, userSnapshot: { firstName: '', lastName: '' } };
  }

  save = async () => {
    const {
      user,
      currentUser,
      saveCallback,
      company,
    } = this.props;
    const { companySnapshot, paymentMethod, userSnapshot } = this.state;
    this.setState({ error: '' });
    try {
      if (company.id) {
        await company.updateFromSnapshot(companySnapshot);
      } else {
        await company.createFromSnapshot(companySnapshot);
      }

      if (!currentUser.userId) {
        const newUser = {
          userId: user.sub.replace('auth0|', ''),
          firstName: userSnapshot.firstName,
          lastName: userSnapshot.lastName,
          email: user.email,
          companyId: this.props.company.id,
          userType: 'Company Executive',
        };

        await currentUser.createFromSnapshot(newUser);
      }
      const paymentdetails = {
        customerId: currentUser.stripeCustomerId,
        paymentMethodId: paymentMethod.id,
      };
      this.setState({ isLoading: true });
      await createSubscription(paymentdetails);
      await company.chargeForUser();
    } catch (error) {
      this.setState({ error: error.message });
    }

    this.setState({ isLoading: false });

    if (saveCallback) {
      saveCallback();
    }
  };

  onInputChange = (e) => {
    const companySnapshot = { ...this.state.companySnapshot };
    companySnapshot[e.target.name] = e.target.value;
    this.setState({ companySnapshot });
  };

  onUserInputChange = (e) => {
    const userSnapshot = { ...this.state.userSnapshot };
    userSnapshot[e.target.name] = e.target.value;
    this.setState({ userSnapshot: userSnapshot });
  };

  onAgreementChange = (data) => {
    this.setState({ userAgrees: data.checked });
  };

  onTermsAgreementChange = (data) => {
    this.setState({ termsAccepted: data.checked });
  };

  onPaymentMethodChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  nextIsEnabled() {
    const { currentUser } = this.props;
    const { companySnapshot, paymentMethod, userAgrees, termsAccepted } = this.state;

    return (
      companySnapshot.name &&
      companySnapshot.streetAddress &&
      companySnapshot.city &&
      companySnapshot.state &&
      companySnapshot.zipCode &&
      (paymentMethod || currentUser.subscriptionId) &&
      userAgrees &&
      termsAccepted
    );
  }

  renderCreditCardDetails() {
    const { companySnapshot } = this.state;
    const { currentUser } = this.props;

    if (currentUser.subscriptionId) {
      return <div>Credit card details saved.</div>;
    } else {
      return (
        <CreditCardDetails
          onPaymentMethodChange={this.onPaymentMethodChange}
          company={companySnapshot}
        />
      );
    }
  }

  render() {
    const { company, currentUser } = this.props;
    const { companySnapshot, isLoading, error, userSnapshot } = this.state;
    return (
      <div style={{ padding: 25 }}>
        <Header>
          Welcome! Let's get some more info,
          then we'll get started.
        </Header>
        <Form loading={company.isLoading || currentUser.isLoading || isLoading}>
          <Segment>
            <ErrorDisplay message={error} />
            <Grid stackable columns='equal'>
              <GridRow>
                <Grid.Column>
                  <Form.Input
                    name='firstName'
                    label='Your First Name'
                    placeholder='Your First Name'
                    onChange={this.onUserInputChange}
                    value={userSnapshot.firstName}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    name='lastName'
                    label='Your Last Name'
                    placeholder='Your Last Name'
                    onChange={this.onUserInputChange}
                    value={userSnapshot.lastName}
                  />
                </Grid.Column>
              </GridRow>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    name='name'
                    label='Company Name'
                    placeholder='Company Name'
                    onChange={this.onInputChange}
                    value={companySnapshot.name}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    children={
                      <MaskedInput
                        mask={[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        placeholder='Phone'
                        name='phone'
                        onChange={this.onInputChange}
                        value={companySnapshot.phone}
                      />
                    }
                    label='Phone'
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    name='streetAddress'
                    label='Address'
                    placeholder='Address'
                    onChange={this.onInputChange}
                    value={companySnapshot.streetAddress}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Form.Input
                    name='city'
                    label='City'
                    placeholder='City'
                    onChange={this.onInputChange}
                    value={companySnapshot.city}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <Form.Input
                    name='state'
                    label='State'
                    placeholder='State'
                    onChange={this.onInputChange}
                    value={companySnapshot.state}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    name='zipCode'
                    label='Zip Code'
                    placeholder='Zip Code'
                    onChange={this.onInputChange}
                    value={companySnapshot.zipCode}
                    type='number'
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            {this.renderCreditCardDetails()}
            <br />
            <Checkbox
              data-testid='userAgreement'
              label='By checking this box I agree my card will be charged $15 per month per active user.'
              onChange={(e, d) => this.onAgreementChange(d)}
            />
            <br />
            <br />
            <Checkbox
              data-testid='termsAgreement'
              label={() => (<label>By checking this box I agree to the{' '}<a href="https://www.termsofusegenerator.net/" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>.</label>)}
              onChange={(e, d) => this.onTermsAgreementChange(d)}
            />
          </Segment>
          <Form.Button
            primary
            onClick={this.save}
            labelPosition='right'
            data-testid='save'
            floated='right'
            icon
            disabled={!this.nextIsEnabled()}
          >
            Next
            <Icon name='right arrow' />
          </Form.Button>
        </Form>
      </div >
    );
  }
}

export default withUserContext(observer(CompanyExecSignup));
